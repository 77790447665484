import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  type Firestore,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import type { UserData } from "../interfaces";
import loggerHelper from "../loggerHelper";

export default class DBHelper {
  db: Firestore;
  constructor(db: Firestore) {
    this.db = db;
  }

  async addDataToCollection(_collection: string, data: any) {
    return await addDoc(collection(this.db, _collection), data).catch(
      (e: any) => {
        const error = `addDataToCollection - ${collection} - ${e}`;
        return loggerHelper.report(error);
      },
    );
  }

  async addDataToSubCollection(
    _collection: string,
    subCollection: string,
    idDoc: string,
    data: any,
  ) {
    return await addDoc(
      collection(this.db, _collection, idDoc, subCollection),
      data,
    ).catch((e: any) => {
      const error = `addDataToSubCollection - ${collection} - ${e}`;
      return loggerHelper.report(error);
    });
  }

  async addOrSetDataToSubCollection(
    _collection: string,
    subCollection: string,
    idDoc: string,
    data: any,
  ) {
    if (data.id) {
      const subDocumentID = data.id;
      const payload = { ...data };
      delete payload.id;
      await setDoc(
        doc(this.db, _collection, idDoc, subCollection, subDocumentID),
        payload,
      ).catch((e: any) => {
        const error = `addOrSetDataToSubCollection - ${collection} - ${e}`;
        return loggerHelper.report(error);
      });
    }
    return await addDoc(
      collection(this.db, _collection, idDoc, subCollection),
      data,
    ).catch((e: any) => {
      const error = `addOrSetDataToSubCollection - ${collection} - ${e}`;
      return loggerHelper.report(error);
    });
  }

  async deleteData(collection: string, id: string) {
    await deleteDoc(doc(this.db, collection, id)).catch((e: any) => {
      const error = `deleteData - ${collection} - ${id} - ${e}`;
      return loggerHelper.report(error);
    });
  }

  async deleteDataToSubCollection(
    collection: string,
    subCollection: string,
    idDoc: string,
    idSubDoc: string,
  ) {
    await deleteDoc(
      doc(this.db, collection, idDoc, subCollection, idSubDoc),
    ).catch((e: any) => {
      const error = `deleteDataToSubCollection - ${collection} - ${e}`;
      return loggerHelper.report(error);
    });
  }

  async deleteDataWithWhere(
    _collection: string,
    arrayWhere: Record<string, unknown> = {},
  ) {
    let docRef = query(collection(this.db, _collection));
    for (const prop in arrayWhere) {
      docRef = query(docRef, where(prop, "==", arrayWhere[prop]));
    }
    await getDocs(docRef)
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          doc.ref.delete();
        });
      })
      .catch((e: any) => {
        const error = `deleteDataWithWhere - ${collection} - ${e}`;
        return loggerHelper.report(error);
      });
  }

  async getAllDataAndSubCollectionsWithWhere(
    _collection: string,
    subCollection: string,
    whatIs: string,
    equalTo: any,
  ) {
    const returnArray = [] as any[];
    const docRef = query(collection(this.db, _collection));
    await getDocs(query(docRef, where(whatIs, "==", equalTo)))
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const result = doc.data();
            result.id = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = `getAllDataAndSubCollectionsWithWhere - ${collection} - ${whatIs} - ${equalTo} - ${e}`;
        return loggerHelper.report(error);
      });
    for (const docItem of returnArray) {
      if (docItem.id) {
        const resultSubCollection = await this.getSubCollectionByDocId(
          _collection,
          subCollection,
          docItem.id,
        );
        docItem[subCollection] = resultSubCollection;
      }
    }
    return returnArray;
  }

  async getAllDataAndSubCollectionsWithWhereArray(
    _collection: string,
    subCollection: string,
    arrayWhere: Record<string, unknown> = {},
  ) {
    const returnArray = [] as any[];
    let docRef = query(collection(this.db, _collection));
    for (const prop in arrayWhere) {
      docRef = query(docRef, where(prop, "==", arrayWhere[prop]));
    }
    await getDocs(docRef)
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const result = doc.data();
            result.id = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = `getAllDataAndSubCollectionsWithWhereArray - ${collection} - ${subCollection} - ${e}`;
        return loggerHelper.report(error);
      });
    for (const docItem of returnArray) {
      if (docItem.id) {
        const resultSubCollection = await this.getSubCollectionByDocId(
          _collection,
          subCollection,
          docItem.id,
        );
        docItem[subCollection] = resultSubCollection;
      }
    }
    return returnArray;
  }

  async getAllDataFromCollection(_collection: string) {
    const returnArray = [] as any[];
    const docRef = query(collection(this.db, _collection));
    await getDocs(docRef)
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const result = doc.data();
            result.id = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = `getAllDataFromCollection - ${collection} - ${e}`;
        return loggerHelper.report(error);
      });
    return returnArray;
  }

  async getAllDataFromCollectionWithWhere(
    _collection: string,
    whatIs: string,
    equalTo: any,
  ) {
    const returnArray = [] as any[];
    const docRef = query(collection(this.db, _collection));
    await getDocs(query(docRef, where(whatIs, "==", equalTo)))
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const result = doc.data();
            result.id = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = `getAllDataFromCollectionWithWhere - ${collection} - ${whatIs} - ${equalTo} - ${e}`;
        return loggerHelper.report(error);
      });
    return returnArray;
  }

  async getAllDataFromCollectionWithWhereArray(
    _collection: string,
    arrayWhere: Record<string, unknown> = {},
  ) {
    const returnArray = [] as any[];
    let docRef = query(collection(this.db, _collection));
    for (const prop in arrayWhere) {
      docRef = query(docRef, where(prop, "==", arrayWhere[prop]));
    }
    await getDocs(docRef)
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const result = doc.data();
            result.id = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = `getAllDataFromCollectionWithWhereArray - ${_collection} -${e}`;
        return loggerHelper.report(error);
      });
    return returnArray;
  }

  async getDocFromCollection(collection: string, docId: string) {
    if (docId) {
      const docRef = doc(this.db, collection);
      const result = await getDoc(doc(this.db, docRef.path, docId))
        .then((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const resultQuery: any = doc.data();
            resultQuery.id = doc.id;
            return resultQuery;
          }
        })
        .catch((e: any) => {
          const error = `getDocFromCollection - ${collection} - ${docId} - ${e}`;
          return loggerHelper.report(error);
        });
      return result;
    }
  }

  async getDocFromCollectionFIXED(collection: string, docId: string) {
    if (docId) {
      const docRef = doc(this.db, collection, docId);
      const result = await getDoc(docRef)
        .then((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const resultQuery: any = doc.data();
            resultQuery.id = doc.id;
            return resultQuery;
          }
        })
        .catch((e: any) => {
          const error = `getDocFromCollection - ${collection} - ${docId} - ${e}`;
          return loggerHelper.report(error);
        });
      return result;
    }
  }

  async getSubCollectionByDocId(
    _collection: string,
    subCollection: string,
    docId: string,
  ) {
    const returnArray = [] as any[];
    await getDocs(collection(this.db, _collection, docId, subCollection)).then(
      (snapshot: any) => {
        snapshot.forEach((doc: any) => {
          if (doc?.exists && doc.data && doc.id) {
            const result = doc.data();
            result.id = doc.id;
            returnArray.push(result);
          }
        });
      },
    );
    return returnArray;
  }

  async setDataToCollection(collection: string, id: string, data: any) {
    await setDoc(doc(this.db, collection, id), data).catch((e: any) => {
      const error = `setDataToCollection - ${collection} - ${id} - ${e}`;
      return loggerHelper.report(error);
    });
  }

  async setDataWithBatch(
    _collection: string,
    data: any,
    subCollection: null | string = null,
    idDoc: null | string = null,
    user: null | Partial<UserData> = null,
  ) {
    let operationsFailed: boolean | string = false;
    try {
      const batchArray = [] as any[];
      batchArray.push(writeBatch(this.db));
      let operationCounter = 0;
      let batchIndex = 0;
      if (subCollection && idDoc) {
        for (const document of data) {
          const docRef = doc(
            this.db,
            _collection,
            idDoc,
            subCollection,
            document.id,
          );
          const _doc = { ...document };
          delete _doc.id;

          batchArray[batchIndex].set(docRef, _doc);
          operationCounter++;
          if (operationCounter === 499) {
            batchArray.push(writeBatch(this.db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      } else {
        for (const document of data) {
          const docRef = doc(this.db, _collection, document.id);
          const _doc = { ...document };
          delete _doc.id;

          batchArray[batchIndex].set(docRef, _doc);
          operationCounter++;
          if (operationCounter === 499) {
            batchArray.push(writeBatch(this.db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      }
      for (const batch of batchArray) {
        await batch
          .commit()
          .then(() => {
            // DO NTG
          })
          .catch((e: any) => {
            console.error("Batched write Failed:", e);
            const error = new Error(
              `Error For ClientID ${user?.client_id} && SiteID ${user?.site_id} && userEmail ${user?.email} && documentID ${idDoc}: \n\n ${e}`,
            );
            operationsFailed = true;
            if (
              (e.message as string).includes(
                'The value of property "content" is longer than',
              )
            ) {
              operationsFailed = "CONTENT LIMIT";
            }
            return loggerHelper.report(error).finally(() => operationsFailed);
          });
      }
      return operationsFailed;
    } catch (err: any) {
      console.error("Batch Failed:", err);
      const error = new Error(
        `Error For ClientID ${user?.client_id} && SiteID ${user?.site_id} && userEmail ${user?.email} && documentID ${idDoc}: \n\n ${err}`,
      );

      operationsFailed = true;
      if (
        (err.message as string).includes(
          'The value of property "content" is longer than',
        )
      ) {
        operationsFailed = "CONTENT LIMIT";
      }
      return loggerHelper.report(error).finally(() => operationsFailed);
    }
  }

  async updateDataToCollection(_collection: string, id: string, data: any) {
    await updateDoc(doc(this.db, _collection, id), data).catch((e: any) => {
      const error = `updateDataToCollection - ${collection} - ${id} - ${e}`;
      return loggerHelper.report(error);
    });
  }

  async updateDataToSubCollection(
    _collection: string,
    subCollection: string,
    idDoc: string,
    idSubDoc: string,
    data: any,
  ) {
    await updateDoc(
      doc(this.db, _collection, idDoc, subCollection, idSubDoc),
      data,
    ).catch((e: any) => {
      const error = `updateDataToSubCollection - ${collection} - ${idDoc} - ${e}`;
      return loggerHelper.report(error);
    });
  }
}
