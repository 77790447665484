<template>
  <div>
    <v-snackbar
      v-for="(snackbar, idx) in snackbars"
      v-bind="$attrs"
      :key="snackbar.key"
      :ref="'v-snackbars-' + identifier"
      v-model="snackbar.show"
      :bottom="snackbar.bottom"
      :class="'v-snackbars v-snackbars-' + identifier + '-' + snackbar.key"
      :color="snackbar.color"
      :left="snackbar.left"
      :right="snackbar.right"
      :timeout="-1"
      :top="snackbar.top"
      :transition="snackbar.transition"
    >
      <template #default>
        <slot :message="snackbar.message">
          {{ snackbar.message }}
        </slot>
      </template>
      <template #action>
        <slot
          :id="snackbar.key"
          :close="() => removeMessage(snackbar.key, true)"
          :index="idx"
          :message="snackbar.message"
          name="action"
        >
          <v-btn
            icon
            @click="removeMessage(snackbar.key, true)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </slot>
      </template>
    </v-snackbar>
    <css-style v-for="(key, idx) in keys" :key="key + idx">
      .v-snackbars.v-snackbars-{{ identifier }}-{{ key }} .v-snack__wrapper {
      transition: {{ topOrBottom[key] }} 500ms; {{ topOrBottom[key] }}: 0; }
      .v-snackbars.v-snackbars-{{ identifier }}-{{ key }} > .v-snack__wrapper {
      {{ topOrBottom[key] }}:{{ calcDistance(key) }}px; }
    </css-style>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue";

interface Snackbar {
  bottom?: boolean;
  color?: string;
  key: string;
  left?: boolean;
  message: string;
  right?: boolean;
  show: boolean;
  timeout: ReturnType<typeof setTimeout> | undefined;
  top?: boolean;
  transition?: string;
}

export default Vue.extend({
  name: "VSnackbars",
  components: {
    "css-style": {
      render: function (createElement) {
        return createElement("style", (this as any).$slots.default);
      },
    },
  },
  inheritAttrs: false,
  props: {
    distance: {
      default: 55,
      type: [Number, String],
    },
    messages: {
      default: () => [],
      type: Array as PropType<Snackbar[]>,
    },
    objects: {
      default: () => [],
      type: Array as PropType<Snackbar[]>,
    },
    timeout: {
      default: 5000,
      type: [Number, String],
    },
  },
  data() {
    return {
      heights: {},
      identifier: Date.now() + `${Math.random()}`.slice(2),
      keys: [] as string[],
      len: 0,
      snackbars: [] as Snackbar[],
    };
  },
  computed: {
    allMessages(): Snackbar[] {
      if (this.objects.length > 0) return this.objects;
      return this.messages;
    },
    // to correcly position the snackbar
    indexPosition(): any {
      const ret = {};
      const idx = {
        bottomCenter: 0,
        bottomLeft: 0,
        bottomRight: 0,
        topCenter: 0,
        topLeft: 0,
        topRight: 0,
      };
      this.snackbars.forEach((o) => {
        if (o.top && !o.left && !o.right) ret[o.key] = idx.topCenter++;
        if (o.top && o.left) ret[o.key] = idx.topLeft++;
        if (o.top && o.right) ret[o.key] = idx.topRight++;
        if (o.bottom && !o.left && !o.right) ret[o.key] = idx.bottomCenter++;
        if (o.bottom && o.left) ret[o.key] = idx.bottomLeft++;
        if (o.bottom && o.right) ret[o.key] = idx.bottomRight++;
      });
      return ret;
    },
    topOrBottom(): any {
      const ret = {};
      this.snackbars.forEach((o) => {
        ret[o.key] = o.top ? "top" : "bottom";
      });
      return ret;
    },
  },
  watch: {
    messages() {
      this.eventify(this.messages);
    },
    objects: {
      deep: true,
      handler() {
        this.eventify(this.objects);
      },
    },
  },
  created() {
    this.eventify(this.messages);
    this.eventify(this.objects);
  },
  methods: {
    calcDistance(key) {
      let distance = 0;
      const snackbar = this.snackbars.find((s) => s.key === key);
      if (!snackbar) return 0;
      for (const snackbar of this.snackbars) {
        if (snackbar.key === key) break;
        if (
          snackbar.show &&
          snackbar.bottom === snackbar.bottom &&
          snackbar.top === snackbar.top &&
          snackbar.right === snackbar.right &&
          snackbar.left === snackbar.left
        ) {
          distance += this.heights[snackbar.key] || 0;
        }
      }
      return distance;
    },
    eventify(arr) {
      const eventify = (arr) => {
        arr.isEventified = true;
        const pushMethod = arr.push;
        arr.push = (e) => {
          pushMethod.call(arr, e);
          this.setSnackbars();
        };

        const spliceMethod = arr.splice;
        arr.splice = () => {
          const args: any = [];
          let len = arguments.length;
          while (len--) args[len] = arguments[len];
          spliceMethod.apply(arr, args);
          let idx = args[0];
          let nbDel = args[1];
          const elemsLen = args.length - 2;
          if (elemsLen === 0) {
            nbDel += idx;
            while (idx < nbDel) {
              if (this.snackbars[idx]) {
                this.removeMessage(this.snackbars[idx].key);
              }
              idx++;
            }
          } else if (elemsLen > 0) {
            for (let i = 2; i < elemsLen + 2; i++) {
              if (typeof args[i] === "string") {
                this.$set(this.snackbars[idx], "message", args[i]);
              } else if (typeof args[i] === "object") {
                for (const prop in args[i]) {
                  if (prop === "timeout") {
                    const timeout = args[i][prop] * 1;
                    if (this.snackbars[idx].timeout) {
                      clearTimeout(this.snackbars[idx].timeout);
                      this.snackbars[idx].timeout = undefined;
                    }
                    if (timeout > -1) {
                      const key = this.snackbars[idx].key;
                      this.snackbars[idx].timeout = setTimeout(() => {
                        this.removeMessage(key, true);
                      }, timeout);
                    }
                  } else {
                    this.$set(this.snackbars[idx], prop, args[i][prop]);
                  }
                }
              }
            }
            idx++;
          }
        };
      };
      if (!arr.isEventified) eventify(arr);
    },
    getProp(prop: string, i: number) {
      if (
        this.objects &&
        this.objects.length > i &&
        typeof this.objects[i][prop] !== "undefined"
      )
        return (this as any).objects[i][prop];
      if (typeof this.$attrs[prop] !== "undefined")
        return (this as any).$attrs[prop];
      if (typeof this[prop] !== "undefined") return this[prop];
      return undefined;
    },
    removeMessage(key: any, fromComponent?: any) {
      const idx = this.snackbars.findIndex((s) => s.key === key);
      if (idx > -1) {
        this.snackbars[idx].show = false;
        const removeSnackbar = () => {
          const idx = this.snackbars.findIndex((s) => s.key === key);
          this.snackbars.splice(idx, 1);
          // dipose all
          this.keys = this.keys.filter((k) => k !== key);
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete this.heights[key];
          // only send back the changes if it happens from this component
          if (fromComponent) {
            this.$emit(
              "update:messages",
              this.allMessages.filter((_m, i) => i !== idx),
            );
            this.$emit(
              "update:objects",
              this.objects.filter((_m, i) => i !== idx),
            );
          }
        };
        // if a timeout on the snackbar, clear it
        if (this.snackbars[idx].timeout)
          clearTimeout(this.snackbars[idx].timeout);
        // use a timeout to ensure the 'transitionend' will be triggerred
        const timeout = setTimeout(removeSnackbar, 600);

        // skip waiting if key does not exist
        const ref = this.$refs[`v-snackbars-${this.identifier}`];
        if (!ref?.[idx]) return;
        // wait the end of the animation
        ref[idx].$el.addEventListener(
          "transitionend",
          () => {
            clearTimeout(timeout);
            removeSnackbar();
          },
          { once: true },
        );
      }
    },
    setSnackbars() {
      if (!this.snackbars) return;
      for (let i = this.snackbars.length; i < this.allMessages.length; i++) {
        const key = `${i}-${Date.now()}`;
        let top = this.getProp("top", i);
        let bottom = this.getProp("bottom", i);
        let left = this.getProp("left", i);
        let right = this.getProp("right", i);
        top = top === "" ? true : top;
        bottom = bottom === "" ? true : bottom;
        left = left === "" ? true : left;
        right = right === "" ? true : right;
        // by default, it will be at the bottom
        if (!bottom && !top) bottom = true;
        this.snackbars.push({
          key: key,
          transition:
            this.getProp("transition", i) ||
            (right ? "slide-x-reverse-transition" : "slide-x-transition"),
          bottom: bottom,
          color:
            this.allMessages[i].color || this.getProp("color", i) || "black",
          left: left,
          message: this.allMessages[i].message,
          right: right,
          show: false,
          timeout: undefined,
          top: top,
        });
        this.keys.push(key);
        this.$nextTick(function () {
          this.snackbars[i].show = true; // to see the come-in animation
          this.$nextTick(function () {
            // find the correct height
            let height = this.distance;
            const elem = document.querySelector(
              `.v-snackbars-${this.identifier}-${key}`,
            );
            if (elem) {
              const wrapper = elem.querySelector(".v-snack__wrapper");
              if (wrapper) {
                height = wrapper.clientHeight + 7;
              }
            }
            this.$set(this.heights, key, height);
            // define the timeout
            const timeout = this.getProp("timeout", i);
            if (timeout > 0) {
              this.snackbars[i].timeout = setTimeout(() => {
                this.removeMessage(key, true);
              }, timeout * 1);
            }
          });
        });
      }
    },
  },
});
</script>
