import { isArray, orderBy } from "lodash";
import { defineStore } from "pinia";

import {
  useContractGlobalStore,
  useParameterAssignationTagsStore,
  useParameterEmployeeCategoryStore,
  useParameterRestrictionStore,
} from "../collections";
import { useTeamDataStore } from "../team";
import { useAuthentificationStore } from "../user";
import { usePlanningStore } from "./planning";
import { useTeamDataFastStore } from "./team-data";

export enum SearchPlanningType {
  category = 8,
  clearance = 4,
  contract = 6,
  employee = 1,
  none = 0,
  position = 2,
  restriction = 7,
  skill = 5,
  subposition = 3,
  tag = 9,
}
export interface SearchPlanningValue {
  id: string;
  name: string;
  search_type: SearchPlanningType;
}

export const isSearchPlanningValue = (value: any) => {
  if (value) {
    if (isArray(value)) {
      const res = value.filter(
        (item) =>
          (item as SearchPlanningValue).id !== undefined &&
          (item as SearchPlanningValue).name !== undefined &&
          (item as SearchPlanningValue).search_type !== undefined,
      );

      return res.length === value.length;
    }
    return false;
  }
  return (
    (value as SearchPlanningValue).id !== undefined &&
    (value as SearchPlanningValue).name !== undefined &&
    (value as SearchPlanningValue).search_type !== undefined
  );
};

export const usePlanningSearch = defineStore("usePlanningSearch", {
  actions: {
    emptySearchValue() {
      this.value = [];
    },
  },
  getters: {
    searchItems(): SearchPlanningValue[] {
      const result: SearchPlanningValue[] = [];
      const uniqIds = new Set<string>([]);
      const { order_positions_and_subpositions_to_search } = useTeamDataStore();
      const PlanningStore = usePlanningStore();
      const TeamDataFastStore = useTeamDataFastStore();
      const employees = PlanningStore.employeeCards;
      const employeeSkillsOnlyTeam = TeamDataFastStore.linkEmployeeSkills.list;
      const employeeClearancesOnlyTeam =
        TeamDataFastStore.linkEmployeeClearances.list;
      const { actives: contracts } = useContractGlobalStore();
      const { actives: restrictions } = useParameterRestrictionStore();
      const { actives: categories } = useParameterEmployeeCategoryStore();
      const { actives: tags } = useParameterAssignationTagsStore();

      if (employees) {
        const lastname_first =
          this.authentificationStore.data?.firstname_parameter;
        const formatedExternalEmployees = this.externalEmployees.map(
          (employee) => {
            const fullname = lastname_first
              ? `${employee.last_name} ${employee.first_name}`
              : `${employee.first_name} ${employee.last_name}`;
            return {
              ...employee,
              name: fullname,
            };
          },
        );
        const allEmployee = [...employees, ...formatedExternalEmployees];
        for (const employee of allEmployee) {
          if (uniqIds.has(employee.id)) continue;
          uniqIds.add(employee.id);

          let name = `${employee.employee_company_id} - ${employee.name}`;

          if (employee.internal_id) {
            name = `${name} - ${employee.internal_id}`;
          }

          result.push({
            id: employee.id,
            name,
            search_type: SearchPlanningType.employee,
          });
        }
      }

      if (order_positions_and_subpositions_to_search) {
        for (const p of order_positions_and_subpositions_to_search) {
          if (uniqIds.has(p.id)) continue;
          uniqIds.add(p.id);

          result.push({
            id: p.id,
            name: p.parent_name ? `${p.parent_name}/${p.name}` : p.name,
            search_type: p.parent_name
              ? SearchPlanningType.subposition
              : SearchPlanningType.position,
          });
        }
      }

      if (employeeSkillsOnlyTeam) {
        for (const employeeSkill of employeeSkillsOnlyTeam) {
          if (uniqIds.has(employeeSkill.skill_id)) continue;
          uniqIds.add(employeeSkill.skill_id);

          result.push({
            id: employeeSkill.skill_id,
            name: employeeSkill?.skill?.name,
            search_type: SearchPlanningType.skill,
          });
        }
      }

      if (employeeClearancesOnlyTeam) {
        for (const employeeClearance of employeeClearancesOnlyTeam) {
          if (uniqIds.has(employeeClearance.clearance_id)) continue;
          uniqIds.add(employeeClearance.clearance_id);

          result.push({
            id: employeeClearance.clearance_id,
            name: employeeClearance.clearance.name,
            search_type: SearchPlanningType.clearance,
          });
        }
      }

      if (contracts) {
        for (const contract of contracts) {
          if (uniqIds.has(contract.id)) continue;
          uniqIds.add(contract.id);

          result.push({
            id: contract.id,
            name: contract.contract_name,
            search_type: SearchPlanningType.contract,
          });
        }
      }

      if (restrictions) {
        for (const restriction of restrictions) {
          if (uniqIds.has(restriction.id)) continue;
          uniqIds.add(restriction.id);

          result.push({
            id: restriction.id,
            name: restriction.name,
            search_type: SearchPlanningType.restriction,
          });
        }
      }

      if (categories) {
        for (const category of categories) {
          if (uniqIds.has(category.id)) continue;
          uniqIds.add(category.id);

          result.push({
            id: category.id,
            name: category.name,
            search_type: SearchPlanningType.category,
          });
        }
      }

      if (tags) {
        for (const tag of tags) {
          if (uniqIds.has(tag.id)) continue;
          uniqIds.add(tag.id);

          result.push({
            id: tag.id,
            name: tag.tag_name,
            search_type: SearchPlanningType.tag,
          });
        }
      }

      uniqIds.clear();

      return orderBy(result, "name");
    },
    searchItemsForWorkload(): SearchPlanningValue[] {
      const result: SearchPlanningValue[] = [];
      const uniqIds = new Set<string>([]);
      const { order_positions_and_subpositions_to_search } = useTeamDataStore();

      for (const p of order_positions_and_subpositions_to_search) {
        if (uniqIds.has(p.id)) continue;
        uniqIds.add(p.id);

        result.push({
          id: p.id,
          name: p.parent_name ? `${p.parent_name}/${p.name}` : p.name,
          search_type: p.parent_name
            ? SearchPlanningType.subposition
            : SearchPlanningType.position,
        });
      }

      uniqIds.clear();

      return result.toSorted((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toLocaleUpperCase()),
      );
    },
    searchValue: (state) => state.value,
  },
  state: () => ({
    authentificationStore: useAuthentificationStore(),
    externalEmployees: [] as {
      employee_company_id: string;
      first_name: string;
      id: string;
      internal_id?: string;
      last_name: string;
    }[],
    value: [] as SearchPlanningValue[],
    // searchItems: [] as SearchPlanningValue[],
  }),
});
