<template>
  <v-app class="grey lighten-4" style="background: #f0f2f5;">
    <div v-if="!globalLoaded" id="loading">
      <v-layout align-center justify-center row>
        <v-img
          :src="logoMercateam"
          alt="Mercateam·logo"
          class="mb-5"
          max-width="185"
        />
        <v-progress-linear
          :value="loadingProgress"
          height="10"
          rounded
          striped
        />
      </v-layout>
    </div>
    <Navbar
      v-if="(!$route.meta || !$route.meta.hideNavigation) && globalLoaded"
      @reload-all="reloadAll"
    />
    <v-main v-if="globalLoaded" class="mx-0">
      <GlobalDialog key="globaldialog1" />
      <GlobalDialog key="globaldialog2" :popin-level="1" />
      <Snackbars
        :messages.sync="messages"
        :timeout="10000"
        color="red"
        right
        top
      />
      <router-view
        :style="
          !$route.meta || !$route.meta.hideFooter ? 'margin-bottom: 60px' : ''
        "
      />
      <div
        v-if="!$route.meta || !$route.meta.hideFooter"
        id="footer"
        class="row"
      >
        <v-col
          class="text-center"
          cols="12"
          style="font-size: 12px; font-weight: lighter;"
        >
          <a
            class="grey_dark--text font-weight-light"
            href="mailto: support@merca.team"
            style="text-decoration: none;"
            target="_blank"
          >{{ $t("navbar.suggestion") }}
          </a>

          <div class="grey_dark--text font-weight-light">
            {{ $t("navbar.cocorico") }} - {{ $t("navbar.version") }}
            {{ `v${version}` }}
            <v-icon color="grey_dark" style="font-size: 12px;" @click="refresh">
              mdi-reload
            </v-icon>
          </div>
        </v-col>
      </div>

      <DocComponent v-if="isDev || isStaging" />
      <MReloadPrompt />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { datadogRum } from "@datadog/browser-rum";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { gql } from "graphql-tag";
import { last } from "lodash";
import { mapActions, mapState, mapStores } from "pinia";
import Vue from "vue";
import type { TranslateResult } from "vue-i18n";

import logoMercateam from "@/assets/logo_mercateam.svg";
import { useLoadingStore } from "@/pinia/features/loading";
import { useAuthentificationStore } from "@/pinia/user";
import { getGraphQLContext } from "@/plugins/datadog";
import { segmentTriggerEvent } from "@/tscript/analytics";
import type { IUser } from "@/tscript/interfaces";

import { version } from "../package.json";
import { config, DATADOG, IS_DEV, IS_STAGING } from "./config";
import usePermissions from "./modules/rights/composables/use-permissions";
import { ESectionName } from "./modules/rights/types";
import { appData } from "./pinia/_loader";
import { type SnackbarPayload, useUiStore } from "./pinia/modules/ui";

import MReloadPrompt from "./components/m-reload-prompt.vue";
import DocComponent from "@/AppDoc/DocComponent.vue";
import GlobalDialog from "@/components/global/GlobalDialog.vue";
import Snackbars from "@/components/libs/v-snackbars.vue";
import Navbar from "@/components/Navbar.vue";

export default Vue.extend({
  name: "App",
  components: { DocComponent, GlobalDialog, MReloadPrompt, Navbar, Snackbars },
  setup() {
    const user = useAuthentificationStore();
    if (DATADOG) {
      datadogRum.init({
        ...DATADOG,
        beforeSend: (event, context) => {
          if (user.data?.email.endsWith("@merca.team")) {
            return event.type === "view";
          }

          event.context = {
            ...event.context,
            ...getGraphQLContext(event, context),
          };

          return true;
        },
      });
    }

    return {
      version,
    };
  },
  data() {
    return {
      loading: true,
      isDev: IS_DEV,
      isStaging: IS_STAGING,
      loadingProgress: 0,
      logoMercateam: logoMercateam,
      messages: [] as { color?: string; message: TranslateResult }[],
      orderBy: [
        {
          last_name: "asc",
        },
      ],
      reportSegmentAlreadySent: false,
      userData: null as IUser | null,
      userRightsQuestionnaires: usePermissions(
        ESectionName.Enum.questionnaire_management,
      ),
    };
  },
  computed: {
    ...mapStores(useAuthentificationStore),
    ...mapState(useUiStore, {
      snackbars: "snackbars",
    }),
    ...mapState(useLoadingStore, {
      globalStoresLoaded: "globalStores",
      goalsHistoryLoaded: "goalsHistory",
      goalsLoaded: "goals",
    }),
    globalLoaded(): boolean {
      return (
        // /*this.globalStoresLoaded &&*/ !this.loading && !this.$apollo.loading
        this.$route.name === "login" ||
        this.$route.name === "terms" ||
        !!this.useAuthentificationStore.data ||
        this.$route.name === "logout"
      );
    },
    notAuthenticated(): boolean {
      return !this.useAuthentificationStore.data;
    },
  },
  watch: {
    globalLoaded: {
      deep: true,
      async handler(v) {
        if (v && !this.reportSegmentAlreadySent) {
          await this.logDashboardEvent();
          this.reportSegmentAlreadySent = true;
        }
      },
    },
    snackbars: {
      deep: true,
      handler(v: SnackbarPayload[]): void {
        if (v && v.length >= 1) {
          const newSnackbar = last(v);
          if (!newSnackbar) return;
          this.messages.push(newSnackbar);
        }
      },
    },
  },
  mounted() {
    logEvent(getAnalytics(), "app_started");
    document.title = `Merca.team - v${version}`;
    // Set language to navigator language
    if (navigator.language && navigator.language.length >= 2) {
      const locale_iso = navigator.language.substring(0, 2);
      this.$i18n.locale = locale_iso;
    }

    const auth = getAuth();
    if (config.useLocalAuthentification) {
      connectAuthEmulator(auth, `${config.portLocalAuthentification}`);
    }
    auth.onAuthStateChanged((user) => {
      (async () => {
        if (user) {
          await this.loadAll(user.uid);
          this.intercomInit();
        } else {
          this.loadingProgress = 100;
          this.loading = false;
          this.useAuthentificationStore.reset();
          if (!this.$route.meta?.isPublic) {
            this.$router.push({ name: "login" }).catch(() => undefined);
          }
        }
      })();
    });
  },
  methods: {
    ...mapActions(useUiStore, {
      pushSnackbar: "pushSnackbar",
    }),
    intercomInit(): null | undefined {
      const user = this.useAuthentificationStore.data;
      const client = this.useAuthentificationStore.active_client;

      const has_planning_feature = client?.enable_planning;
      const has_content_feature =
        this.useAuthentificationStore.active_site_full
          ?.settings_content_feature;
      const has_content_feature_v2 =
        this.useAuthentificationStore.active_site_full
          ?.settings_content_feature_v2;
      const has_training_feature = this.userRightsQuestionnaires.readAllowed;

      if (!user) return null;
      if ((window as any).Intercom) {
        (window as any).Intercom("boot", {
          created_at: user.created_at,
          api_base: "https://api-iam.intercom.io",
          app_id: "ptjev4an",
          client: this.useAuthentificationStore.active_client?.name,
          email: user.email,
          firstname: user.first_name,
          has_content_feature,
          has_content_feature_v2,
          has_planning_feature,
          has_training_feature,
          job: user.job,
          language: user.language,
          lastname: user.last_name,
          role: user.role,
          site: this.useAuthentificationStore.active_site_full?.name,
          site_id: user.site_id,
          user_id: user.id,
        });
      }
    },
    async loadAll(uid: string) {
      const startLoaderGlobal = performance.now();
      const loadingSteps = 30;
      this.loadingProgress = 0;
      this.loading = true;

      // await this.useAuthentificationStore.setUserAuthenticated(uid);
      await this.useAuthentificationStore.setClientAuthenticated(uid);
      await this.useAuthentificationStore.setSiteAuthenticated(uid);
      this.loadingProgress = 100;
      const userData = this.useAuthentificationStore.data;
      this.userData = this.useAuthentificationStore.data;

      this.loadingProgress += loadingSteps;
      // await this.siteData();
      if (userData?.role && userData?.role !== "CUSTOMER_SUCCESS") {
        // this.loadingProgress = 90;
        // === Pinia app data ===
        await appData(true);
        // this.loadingProgress = 100;
        this.loading = false;
        const endLoaderGlobal = performance.now();
        segmentTriggerEvent("loaderPerformance", {
          performance: endLoaderGlobal - startLoaderGlobal,
        });
      }
      // set Event User Properties
      setUserProperties(getAnalytics(), {
        client_name: this.useAuthentificationStore.active_client?.name,
        site_name: this.useAuthentificationStore.active_site_full?.name,
      });

      datadogRum.setUser({
        client: this.useAuthentificationStore.data?.client?.name,
        client_id: this.useAuthentificationStore.data?.client_id,
        id: this.useAuthentificationStore.data?.id,
        site: this.useAuthentificationStore.data?.site?.name,
        site_id: this.useAuthentificationStore.data?.site_id,
      });
    },
    async logDashboardEvent() {
      segmentTriggerEvent("info_user");
      if (this.useAuthentificationStore.data) {
        const { data } = await this.$apollo.query({
          fetchPolicy: "no-cache",
          query: gql`
            query Site($where: SiteWhereUniqueInput!) {
              Site(where: $where) {
                _count {
                  employees
                  users
                  teams
                  skills
                  clearances
                  link_skills
                  link_clearances
                  positions
                  subpositions
                }
              }
            }
          `,
          variables: {
            where: {
              id: this.useAuthentificationStore.data?.site_id,
            },
          },
        });

        if (data?.Site?._count) {
          // Send event
          segmentTriggerEvent("sites_data", {
            nb_clearances: data.Site._count.clearances.length,
            nb_employee: data.Site._count.employees.length,
            nb_employee_clearances: data.Site._count.link_clearances.length,
            nb_employee_skills: data.Site._count.link_skills.length,
            nb_positions: data.Site._count.positions.length,
            nb_skills: data.Site._count.skills.length,
            nb_teams: data.Site._count.teams.length,
            nb_users: data.Site._count.users.length,
          });
        }
      }
    },
    refresh(): void {
      const d = new Date().getTime();
      window.location.href = `${window.location}?${d?.toString()}`;
    },
    async reloadAll(user: IUser) {
      await this.loadAll(user.id);
    },
    // Example of snackbar
    test(): void {
      this.pushSnackbar({
        color: "green",
        message: "coucou",
      });
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/app";

#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20%;
}

#afterLoading {
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f0f2f5;
}
</style>
