import { ApolloLink, type Operation } from "@apollo/client";
import type { RumEvent, RumInitConfiguration } from "@datadog/browser-rum";
import type { DefinitionNode, OperationDefinitionNode } from "graphql";

export const datadogDefaultConfig: RumInitConfiguration = {
  applicationId: "0a1c9ea9-c37c-405d-a28e-76da6e0871af",
  clientToken: "pub510e65142f98e62029c5dfef2387d382",
  compressIntakeRequests: true,
  defaultPrivacyLevel: "allow",
  service: "apps-core",
  sessionReplaySampleRate: 100,
  sessionSampleRate: 100,
  site: "datadoghq.eu",
};

const DATADOG_GRAPHQL_CUSTOM_HEADER_PREFIX = "_dd-custom-header-graphql";
const DATADOG_GRAPHQL_OPERATION_NAME_HEADER = `${DATADOG_GRAPHQL_CUSTOM_HEADER_PREFIX}-operation-name`;
const DATADOG_GRAPHQL_OPERATION_TYPE_HEADER = `${DATADOG_GRAPHQL_CUSTOM_HEADER_PREFIX}-operation-type`;

export const getGraphQLContext = (event: RumEvent, context) => {
  if (
    event.type === "resource" &&
    event.resource.type === "fetch" &&
    event.resource.url.endsWith("/graphql")
  ) {
    const headers = Object.fromEntries(
      context.requestInit.headers.filter(([key]) =>
        key.startsWith(DATADOG_GRAPHQL_CUSTOM_HEADER_PREFIX),
      ),
    );
    const operationName = headers[DATADOG_GRAPHQL_OPERATION_NAME_HEADER];
    const operationType = headers[DATADOG_GRAPHQL_OPERATION_TYPE_HEADER];

    return {
      operationName,
      operationType,
    };
  }

  return {};
};

/**
 * Logic below is a copy/paste of what is provided by Datadog for React Native interaction with GraphQL
 * (see: https://github.com/DataDog/dd-sdk-reactnative/blob/d984be1de35994a4e17c083d40ac012718a89c52/packages/react-native-apollo-client/src/DatadogLink.ts)
 */
const getOperationName = (operation: Operation): null | string => {
  if (operation.operationName) {
    return operation.operationName;
  }
  return null;
};

const getOperationDefinitionNode = (
  definition: DefinitionNode,
): definition is OperationDefinitionNode => {
  return definition.kind === "OperationDefinition" && !!definition.operation;
};

const getOperationType = (
  operation: Operation,
): "mutation" | "query" | "subscription" | null => {
  try {
    return (
      operation.query.definitions
        .filter(getOperationDefinitionNode)
        .map((operationDefinitionNode) => {
          return operationDefinitionNode.operation;
        })[0] || null
    );
  } catch (e) {
    // TODO RUM-1206: telemetry
    return null;
  }
};

export class DatadogLink extends ApolloLink {
  constructor() {
    super((operation, forward) => {
      const operationName = getOperationName(operation);
      const operationType = getOperationType(operation);

      operation.setContext(({ headers = {} }) => {
        const newHeaders: Record<string, null | string> = {
          ...headers,
        };

        newHeaders[DATADOG_GRAPHQL_OPERATION_TYPE_HEADER] = operationType;
        newHeaders[DATADOG_GRAPHQL_OPERATION_NAME_HEADER] = operationName;

        return {
          headers: newHeaders,
        };
      });

      return forward(operation);
    });
  }
}
