import { ESectionName } from "../rights/types";

const performanceReviewsRoutes = [
  {
    children: [
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/TemplateCreation/TemplateCreation.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_models,
        },
        name: "performance-review-v2-create-template",
        path: "create-template",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/TemplateCreation/TemplateCreation.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_models,
        },
        name: "performance-review-v2-edit-template",
        path: "edit-template/:template_id",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/TemplateCreation/TemplateCreation.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_models,
        },
        name: "performance-review-v2-template-revision",
        path: "templates/template-revision/:template_id",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/CampaignCreation/CampaignCreation.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_campaign,
        },
        name: "performance-review-v2-launch-campaign",
        path: "launch-campaign",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/PerformanceReviewCampaign.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_campaign,
        },
        name: "performance-review-v2-campaign",
        path: "campaign/:id",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/PerformanceReviewResponse.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_campaign,
        },
        name: "performance-review-v2-review",
        path: "review",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review-v2/views/PerformanceReviewResponse.vue"
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_campaign,
        },
        name: "performance-review-v2-review-consult",
        path: "review-consult",
      },
      {
        children: [
          {
            component: () =>
              import(
                "@/modules/performance-review-v2/views/PerformanceReviewTemplates.vue"
              ),
            meta: {
              requiresAuth: true,
              userRightsSection: ESectionName.Enum.performance_reviews_models,
            },
            name: "performance-review-v2-templates",
            path: "templates",
          },
          {
            component: () =>
              import(
                "@/modules/performance-review-v2/views/PerformanceReviewCampaigns.vue"
              ),
            meta: {
              requiresAuth: true,
              userRightsSection: ESectionName.Enum.performance_reviews_campaign,
            },
            name: "performance-review-v2-campaigns",
            path: "campaigns",
          },
          {
            component: () =>
              import(
                "@/modules/performance-review-v2/views/PerformanceReviewTemplateRevisions.vue"
              ),
            meta: {
              requiresAuth: true,
              userRightsSection: ESectionName.Enum.performance_reviews_models,
            },
            name: "performance-review-v2-template-revisions",
            path: "templates/template-revisions/:template_id",
          },
        ],
        component: () =>
          import("@/modules/performance-review-v2/views/PerformanceReview.vue"),
        name: "performance-review-v2",
        path: "/",
      },
    ],
    component: () =>
      import("@/modules/performance-review-v2/views/PerformanceReviewMain.vue"),
    path: "/performance-reviews-v2",
  },
];

export default performanceReviewsRoutes;
