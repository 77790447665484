<template>
  <v-list-item-avatar
    :class="{'mt-1': $router.currentRoute.name === 'PlanningMonthPosition'}"
    :color="color"
    :size="sizePicture"
  >
    <v-img
      v-if="props.pictureUrl"
      :alt="`${firstName} ${lastName}`"
      :src="props.pictureUrl"
    />
    <span v-if="!props.pictureUrl" :class="'white--text ' + classInitial">
      {{ initials }}
    </span>
  </v-list-item-avatar>
</template>

<script lang="ts">
import { mapState } from "pinia";
import Vue from "vue";

import { useAuthentificationStore } from "@/pinia/user";
import { stringToColor } from "@/tscript/utils";

export default Vue.extend({
  name: "AvatarPicture",
  props: ["id", "firstName", "lastName", "pictureUrl", "size"],
  setup(props) {
    return {
      props,
    };
  },
  data() {
    return {
      classInitial: "headline",
      sizePicture: 70,
    };
  },
  computed: {
    ...mapState(useAuthentificationStore, {
      userData: "data",
    }),
    color: function (): string {
      return stringToColor(this.id);
    },
    initials: function () {
      let computedInitials = "";
      if (this.firstName && this.firstName.length > 0) {
        if (this.userData?.firstname_parameter) {
          computedInitials = this.lastName.charAt(0) + this.firstName.charAt(0);
        } else {
          computedInitials = this.firstName.charAt(0) + this.lastName.charAt(0);
        }
      }
      return computedInitials;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.size) {
        this.sizePicture = this.size;
        if (this.size < 50) {
          this.classInitial = "";
        }
      }
    },
  },
});
</script>
