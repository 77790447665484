import d, { defineComponent as N } from "vue";
import Ht from "vuetify";
const v = d.extend().extend({
  name: "themeable",
  provide() {
    return {
      theme: this.themeableProvide
    };
  },
  inject: {
    theme: {
      default: {
        isDark: !1
      }
    }
  },
  props: {
    dark: {
      type: Boolean,
      default: null
    },
    light: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      themeableProvide: {
        isDark: !1
      }
    };
  },
  computed: {
    appIsDark() {
      return this.$vuetify.theme.dark || !1;
    },
    isDark() {
      return this.dark === !0 ? !0 : this.light === !0 ? !1 : this.theme.isDark;
    },
    themeClasses() {
      return {
        "theme--dark": this.isDark,
        "theme--light": !this.isDark
      };
    },
    /** Used by menus and dialogs, inherits from v-app instead of the parent */
    rootIsDark() {
      return this.dark === !0 ? !0 : this.light === !0 ? !1 : this.appIsDark;
    },
    rootThemeClasses() {
      return {
        "theme--dark": this.rootIsDark,
        "theme--light": !this.rootIsDark
      };
    }
  },
  watch: {
    isDark: {
      handler(t, e) {
        t !== e && (this.themeableProvide.isDark = this.isDark);
      },
      immediate: !0
    }
  }
});
function Nt(t) {
  const e = {
    ...t.props,
    ...t.injections
  }, i = v.options.computed.isDark.call(e);
  return v.options.computed.themeClasses.call({
    isDark: i
  });
}
function m(...t) {
  return d.extend({
    mixins: t
  });
}
function K(t, e = "div", i) {
  return d.extend({
    name: t.replace(/__/g, "-"),
    functional: !0,
    props: {
      tag: {
        type: String,
        default: e
      }
    },
    render(s, {
      data: n,
      props: r,
      children: o
    }) {
      return n.staticClass = `${t} ${n.staticClass || ""}`.trim(), s(r.tag, n, o);
    }
  });
}
let Ae = !1;
try {
  if (typeof window < "u") {
    const t = Object.defineProperty({}, "passive", {
      get: () => {
        Ae = !0;
      }
    });
    window.addEventListener("testListener", t, t), window.removeEventListener("testListener", t, t);
  }
} catch (t) {
  console.warn(t);
}
function ft(t, e, i) {
  const s = e.length - 1;
  if (s < 0) return t === void 0 ? i : t;
  for (let n = 0; n < s; n++) {
    if (t == null)
      return i;
    t = t[e[n]];
  }
  return t == null || t[e[s]] === void 0 ? i : t[e[s]];
}
function pt(t, e) {
  if (t === e) return !0;
  if (t instanceof Date && e instanceof Date && t.getTime() !== e.getTime() || t !== Object(t) || e !== Object(e))
    return !1;
  const i = Object.keys(t);
  return i.length !== Object.keys(e).length ? !1 : i.every((s) => pt(t[s], e[s]));
}
function k(t, e, i) {
  return t == null || !e || typeof e != "string" ? i : t[e] !== void 0 ? t[e] : (e = e.replace(/\[(\w+)\]/g, ".$1"), e = e.replace(/^\./, ""), ft(t, e.split("."), i));
}
function A(t, e, i) {
  if (e == null) return t === void 0 ? i : t;
  if (t !== Object(t)) return i === void 0 ? t : i;
  if (typeof e == "string") return k(t, e, i);
  if (Array.isArray(e)) return ft(t, e, i);
  if (typeof e != "function") return i;
  const s = e(t, i);
  return typeof s > "u" ? i : s;
}
function R(t) {
  if (!t || t.nodeType !== Node.ELEMENT_NODE) return 0;
  const e = +window.getComputedStyle(t).getPropertyValue("z-index");
  return e || R(t.parentNode);
}
function Me(t, e) {
  const i = {};
  for (let s = 0; s < e.length; s++) {
    const n = e[s];
    typeof t[n] < "u" && (i[n] = t[n]);
  }
  return i;
}
function f(t, e = "px") {
  if (!(t == null || t === ""))
    return isNaN(+t) ? String(t) : `${Number(t)}${e}`;
}
function rt(t) {
  return (t || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
function It(t) {
  return t !== null && typeof t == "object";
}
const h = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34,
  shift: 16
});
function De(t, e) {
  const i = t.$vuetify.icons.component;
  if (e.startsWith("$")) {
    const s = `$vuetify.icons.values.${e.split("$").pop().split(".").pop()}`, n = k(t, s, e);
    if (typeof n == "string") e = n;
    else return n;
  }
  return i == null ? e : {
    component: i,
    props: {
      icon: e
    }
  };
}
function zt(t) {
  return Object.keys(t);
}
const Be = /-(\w)/g, Oe = (t) => t.replace(Be, (e, i) => i ? i.toUpperCase() : "");
function Ve(t) {
  return t.charAt(0).toUpperCase() + t.slice(1);
}
function mt(t) {
  return t != null ? Array.isArray(t) ? t : [t] : [];
}
function Wt(t, e, i) {
  if (t.$slots.hasOwnProperty(e) && t.$scopedSlots.hasOwnProperty(e) && t.$scopedSlots[e].name)
    return "v-slot";
  if (t.$slots.hasOwnProperty(e)) return "normal";
  if (t.$scopedSlots.hasOwnProperty(e)) return "scoped";
}
function b(t, e = "default", i, s = !1) {
  const n = rt(e);
  if (t.$scopedSlots.hasOwnProperty(e))
    return t.$scopedSlots[e](i instanceof Function ? i() : i);
  if (t.$scopedSlots.hasOwnProperty(n))
    return t.$scopedSlots[n](i instanceof Function ? i() : i);
  if (t.$slots.hasOwnProperty(e) && (!i || s))
    return t.$slots[e];
  if (t.$slots.hasOwnProperty(n) && (!i || s))
    return t.$slots[n];
}
function Ee(t, e = 0, i = 1) {
  return Math.max(e, Math.min(i, t));
}
function E(t = {}, e = {}) {
  for (const i in e) {
    const s = t[i], n = e[i];
    if (It(s) && It(n)) {
      t[i] = E(s, n);
      continue;
    }
    t[i] = n;
  }
  return t;
}
function kt(t) {
  return function(e, i) {
    for (const s in i)
      Object.prototype.hasOwnProperty.call(e, s) || this.$delete(this.$data[t], s);
    for (const s in e)
      this.$set(this.$data[t], s, e[s]);
  };
}
const gt = d.extend({
  data: () => ({
    attrs$: {},
    listeners$: {}
  }),
  created() {
    this.$watch("$attrs", kt("attrs$"), {
      immediate: !0
    }), this.$watch("$listeners", kt("listeners$"), {
      immediate: !0
    });
  }
});
function ot(t, e = {}) {
  if (ot.installed) return;
  ot.installed = !0, d !== t && C(`Multiple instances of Vue detected
See https://github.com/vuetifyjs/vuetify/issues/4068

If you're seeing "$attrs is readonly", it's caused by this`);
  const i = e.components || {}, s = e.directives || {};
  for (const n in s) {
    const r = s[n];
    t.directive(n, r);
  }
  (function n(r) {
    if (r) {
      for (const o in r) {
        const a = r[o];
        a && !n(a.$_vuetify_subcomponents) && t.component(o, a);
      }
      return !0;
    }
    return !1;
  })(i), !t.$_vuetify_installed && (t.$_vuetify_installed = !0, t.mixin({
    beforeCreate() {
      const n = this.$options;
      n.vuetify ? (n.vuetify.init(this, this.$ssrContext), this.$vuetify = t.observable(n.vuetify.framework)) : this.$vuetify = n.parent && n.parent.$vuetify || this;
    },
    beforeMount() {
      this.$options.vuetify && this.$el && this.$el.hasAttribute("data-server-rendered") && (this.$vuetify.isHydrating = !0, this.$vuetify.breakpoint.update(!0));
    },
    mounted() {
      this.$options.vuetify && this.$vuetify.isHydrating && (this.$vuetify.isHydrating = !1, this.$vuetify.breakpoint.update());
    }
  }));
}
class B {
  constructor() {
    this.framework = {};
  }
  init(e, i) {
  }
}
class Rt extends B {
  constructor() {
    super(...arguments), this.bar = 0, this.top = 0, this.left = 0, this.insetFooter = 0, this.right = 0, this.bottom = 0, this.footer = 0, this.application = {
      bar: {},
      top: {},
      left: {},
      insetFooter: {},
      right: {},
      bottom: {},
      footer: {}
    };
  }
  register(e, i, s) {
    this.application[i][e] = s, this.update(i);
  }
  unregister(e, i) {
    this.application[i][e] != null && (delete this.application[i][e], this.update(i));
  }
  update(e) {
    this[e] = Object.values(this.application[e]).reduce((i, s) => i + s, 0);
  }
}
Rt.property = "application";
class U extends B {
  constructor(e) {
    super(), this.xs = !1, this.sm = !1, this.md = !1, this.lg = !1, this.xl = !1, this.xsOnly = !1, this.smOnly = !1, this.smAndDown = !1, this.smAndUp = !1, this.mdOnly = !1, this.mdAndDown = !1, this.mdAndUp = !1, this.lgOnly = !1, this.lgAndDown = !1, this.lgAndUp = !1, this.xlOnly = !1, this.name = "xs", this.height = 0, this.width = 0, this.mobile = !0, this.resizeTimeout = 0;
    const {
      mobileBreakpoint: i,
      scrollBarWidth: s,
      thresholds: n
    } = e[U.property];
    this.mobileBreakpoint = i, this.scrollBarWidth = s, this.thresholds = n;
  }
  init() {
    this.update(), !(typeof window > "u") && window.addEventListener("resize", this.onResize.bind(this), {
      passive: !0
    });
  }
  /* eslint-disable-next-line max-statements */
  update(e = !1) {
    const i = e ? 0 : this.getClientHeight(), s = e ? 0 : this.getClientWidth(), n = s < this.thresholds.xs, r = s < this.thresholds.sm && !n, o = s < this.thresholds.md - this.scrollBarWidth && !(r || n), a = s < this.thresholds.lg - this.scrollBarWidth && !(o || r || n), l = s >= this.thresholds.lg - this.scrollBarWidth;
    switch (this.height = i, this.width = s, this.xs = n, this.sm = r, this.md = o, this.lg = a, this.xl = l, this.xsOnly = n, this.smOnly = r, this.smAndDown = (n || r) && !(o || a || l), this.smAndUp = !n && (r || o || a || l), this.mdOnly = o, this.mdAndDown = (n || r || o) && !(a || l), this.mdAndUp = !(n || r) && (o || a || l), this.lgOnly = a, this.lgAndDown = (n || r || o || a) && !l, this.lgAndUp = !(n || r || o) && (a || l), this.xlOnly = l, !0) {
      case n:
        this.name = "xs";
        break;
      case r:
        this.name = "sm";
        break;
      case o:
        this.name = "md";
        break;
      case a:
        this.name = "lg";
        break;
      default:
        this.name = "xl";
        break;
    }
    if (typeof this.mobileBreakpoint == "number") {
      this.mobile = s < parseInt(this.mobileBreakpoint, 10);
      return;
    }
    const c = {
      xs: 0,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4
    }, u = c[this.name], p = c[this.mobileBreakpoint];
    this.mobile = u <= p;
  }
  onResize() {
    clearTimeout(this.resizeTimeout), this.resizeTimeout = window.setTimeout(this.update.bind(this), 200);
  }
  // Cross-browser support as described in:
  // https://stackoverflow.com/questions/1248081
  getClientWidth() {
    return typeof document > "u" ? 0 : Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }
  getClientHeight() {
    return typeof document > "u" ? 0 : Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }
}
U.property = "breakpoint";
const Fe = (t) => t, Pe = (t) => t ** 2, He = (t) => t * (2 - t), Ne = (t) => t < 0.5 ? 2 * t ** 2 : -1 + (4 - 2 * t) * t, ze = (t) => t ** 3, We = (t) => --t ** 3 + 1, Re = (t) => t < 0.5 ? 4 * t ** 3 : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1, je = (t) => t ** 4, Ye = (t) => 1 - --t ** 4, qe = (t) => t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t, Ze = (t) => t ** 5, Xe = (t) => 1 + --t ** 5, Ke = (t) => t < 0.5 ? 16 * t ** 5 : 1 + 16 * --t ** 5, Ue = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  easeInCubic: ze,
  easeInOutCubic: Re,
  easeInOutQuad: Ne,
  easeInOutQuart: qe,
  easeInOutQuint: Ke,
  easeInQuad: Pe,
  easeInQuart: je,
  easeInQuint: Ze,
  easeOutCubic: We,
  easeOutQuad: He,
  easeOutQuart: Ye,
  easeOutQuint: Xe,
  linear: Fe
}, Symbol.toStringTag, { value: "Module" }));
function et(t) {
  if (typeof t == "number")
    return t;
  let e = Yt(t);
  if (!e)
    throw typeof t == "string" ? new Error(`Target element "${t}" not found.`) : new TypeError(`Target must be a Number/Selector/HTMLElement/VueComponent, received ${jt(t)} instead.`);
  let i = 0;
  for (; e; )
    i += e.offsetTop, e = e.offsetParent;
  return i;
}
function Ge(t) {
  const e = Yt(t);
  if (e) return e;
  throw typeof t == "string" ? new Error(`Container element "${t}" not found.`) : new TypeError(`Container must be a Selector/HTMLElement/VueComponent, received ${jt(t)} instead.`);
}
function jt(t) {
  return t == null ? t : t.constructor.name;
}
function Yt(t) {
  return typeof t == "string" ? document.querySelector(t) : t && t._isVue ? t.$el : t instanceof HTMLElement ? t : null;
}
function M(t, e = {}) {
  const i = {
    container: document.scrollingElement || document.body || document.documentElement,
    duration: 500,
    offset: 0,
    easing: "easeInOutCubic",
    appOffset: !0,
    ...e
  }, s = Ge(i.container);
  if (i.appOffset && M.framework.application) {
    const l = s.classList.contains("v-navigation-drawer"), c = s.classList.contains("v-navigation-drawer--clipped"), {
      bar: u,
      top: p
    } = M.framework.application;
    i.offset += u, (!l || c) && (i.offset += p);
  }
  const n = performance.now();
  let r;
  typeof t == "number" ? r = et(t) - i.offset : r = et(t) - et(s) - i.offset;
  const o = s.scrollTop;
  if (r === o) return Promise.resolve(r);
  const a = typeof i.easing == "function" ? i.easing : Ue[i.easing];
  if (!a) throw new TypeError(`Easing function "${i.easing}" not found.`);
  return new Promise((l) => requestAnimationFrame(function c(u) {
    const p = u - n, g = Math.abs(i.duration ? Math.min(p / i.duration, 1) : 1);
    s.scrollTop = Math.floor(o + (r - o) * a(g));
    const W = (s === document.body ? document.documentElement.clientHeight : s.clientHeight) + s.scrollTop >= s.scrollHeight;
    if (g === 1 || // Need to go lower but reach bottom
    r > s.scrollTop && W)
      return l(r);
    requestAnimationFrame(c);
  }));
}
M.framework = {};
M.init = () => {
};
class qt extends B {
  constructor() {
    return super(), M;
  }
}
qt.property = "goTo";
const Je = {
  complete: "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z",
  cancel: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  close: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
  delete: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  clear: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  success: "M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z",
  info: "M13,9H11V7H13M13,17H11V11H13M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2ZM13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
  warning: "M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
  error: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  prev: "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z",
  next: "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z",
  checkboxOn: "M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z",
  checkboxOff: "M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z",
  checkboxIndeterminate: "M17,13H7V11H17M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z",
  delimiter: "M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z",
  sort: "M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z",
  expand: "M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z",
  menu: "M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z",
  subgroup: "M7,10L12,15L17,10H7Z",
  dropdown: "M7,10L12,15L17,10H7Z",
  radioOn: "M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7Z",
  radioOff: "M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z",
  edit: "M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z",
  ratingEmpty: "M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z",
  ratingFull: "M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z",
  ratingHalf: "M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z",
  loading: "M19,8L15,12H18C18,15.31 15.31,18 12,18C11,18 10.03,17.75 9.2,17.3L7.74,18.76C8.97,19.54 10.43,20 12,20C16.42,20 20,16.42 20,12H23M6,12C6,8.69 8.69,6 12,6C13,6 13.97,6.25 14.8,6.7L16.26,5.24C15.03,4.46 13.57,4 12,4C7.58,4 4,7.58 4,12H1L5,16L9,12",
  first: "M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z",
  last: "M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z",
  unfold: "M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z",
  file: "M16.5,6V17.5C16.5,19.71 14.71,21.5 12.5,21.5C10.29,21.5 8.5,19.71 8.5,17.5V5C8.5,3.62 9.62,2.5 11,2.5C12.38,2.5 13.5,3.62 13.5,5V15.5C13.5,16.05 13.05,16.5 12.5,16.5C11.95,16.5 11.5,16.05 11.5,15.5V6H10V15.5C10,16.88 11.12,18 12.5,18C13.88,18 15,16.88 15,15.5V5C15,2.79 13.21,1 11,1C8.79,1 7,2.79 7,5V17.5C7,20.54 9.46,23 12.5,23C15.54,23 18,20.54 18,17.5V6H16.5Z",
  plus: "M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z",
  minus: "M19,13H5V11H19V13Z"
}, Qe = {
  complete: "check",
  cancel: "cancel",
  close: "close",
  delete: "cancel",
  clear: "clear",
  success: "check_circle",
  info: "info",
  warning: "priority_high",
  error: "warning",
  prev: "chevron_left",
  next: "chevron_right",
  checkboxOn: "check_box",
  checkboxOff: "check_box_outline_blank",
  checkboxIndeterminate: "indeterminate_check_box",
  delimiter: "fiber_manual_record",
  sort: "arrow_upward",
  expand: "keyboard_arrow_down",
  menu: "menu",
  subgroup: "arrow_drop_down",
  dropdown: "arrow_drop_down",
  radioOn: "radio_button_checked",
  radioOff: "radio_button_unchecked",
  edit: "edit",
  ratingEmpty: "star_border",
  ratingFull: "star",
  ratingHalf: "star_half",
  loading: "cached",
  first: "first_page",
  last: "last_page",
  unfold: "unfold_more",
  file: "attach_file",
  plus: "add",
  minus: "remove"
}, ti = {
  complete: "mdi-check",
  cancel: "mdi-close-circle",
  close: "mdi-close",
  delete: "mdi-close-circle",
  clear: "mdi-close",
  success: "mdi-check-circle",
  info: "mdi-information",
  warning: "mdi-exclamation",
  error: "mdi-alert",
  prev: "mdi-chevron-left",
  next: "mdi-chevron-right",
  checkboxOn: "mdi-checkbox-marked",
  checkboxOff: "mdi-checkbox-blank-outline",
  checkboxIndeterminate: "mdi-minus-box",
  delimiter: "mdi-circle",
  sort: "mdi-arrow-up",
  expand: "mdi-chevron-down",
  menu: "mdi-menu",
  subgroup: "mdi-menu-down",
  dropdown: "mdi-menu-down",
  radioOn: "mdi-radiobox-marked",
  radioOff: "mdi-radiobox-blank",
  edit: "mdi-pencil",
  ratingEmpty: "mdi-star-outline",
  ratingFull: "mdi-star",
  ratingHalf: "mdi-star-half-full",
  loading: "mdi-cached",
  first: "mdi-page-first",
  last: "mdi-page-last",
  unfold: "mdi-unfold-more-horizontal",
  file: "mdi-paperclip",
  plus: "mdi-plus",
  minus: "mdi-minus"
}, Zt = {
  complete: "fas fa-check",
  cancel: "fas fa-times-circle",
  close: "fas fa-times",
  delete: "fas fa-times-circle",
  clear: "fas fa-times-circle",
  success: "fas fa-check-circle",
  info: "fas fa-info-circle",
  warning: "fas fa-exclamation-circle",
  error: "fas fa-exclamation-triangle",
  prev: "fas fa-chevron-left",
  next: "fas fa-chevron-right",
  checkboxOn: "fas fa-check-square",
  checkboxOff: "far fa-square",
  checkboxIndeterminate: "fas fa-minus-square",
  delimiter: "fas fa-circle",
  sort: "fas fa-sort-up",
  expand: "fas fa-chevron-down",
  menu: "fas fa-bars",
  subgroup: "fas fa-caret-down",
  dropdown: "fas fa-caret-down",
  radioOn: "far fa-dot-circle",
  radioOff: "far fa-circle",
  edit: "fas fa-edit",
  ratingEmpty: "far fa-star",
  ratingFull: "fas fa-star",
  ratingHalf: "fas fa-star-half",
  loading: "fas fa-sync",
  first: "fas fa-step-backward",
  last: "fas fa-step-forward",
  unfold: "fas fa-arrows-alt-v",
  file: "fas fa-paperclip",
  plus: "fas fa-plus",
  minus: "fas fa-minus"
}, ei = {
  complete: "fa fa-check",
  cancel: "fa fa-times-circle",
  close: "fa fa-times",
  delete: "fa fa-times-circle",
  clear: "fa fa-times-circle",
  success: "fa fa-check-circle",
  info: "fa fa-info-circle",
  warning: "fa fa-exclamation",
  error: "fa fa-exclamation-triangle",
  prev: "fa fa-chevron-left",
  next: "fa fa-chevron-right",
  checkboxOn: "fa fa-check-square",
  checkboxOff: "fa fa-square-o",
  checkboxIndeterminate: "fa fa-minus-square",
  delimiter: "fa fa-circle",
  sort: "fa fa-sort-up",
  expand: "fa fa-chevron-down",
  menu: "fa fa-bars",
  subgroup: "fa fa-caret-down",
  dropdown: "fa fa-caret-down",
  radioOn: "fa fa-dot-circle-o",
  radioOff: "fa fa-circle-o",
  edit: "fa fa-pencil",
  ratingEmpty: "fa fa-star-o",
  ratingFull: "fa fa-star",
  ratingHalf: "fa fa-star-half-o",
  loading: "fa fa-refresh",
  first: "fa fa-step-backward",
  last: "fa fa-step-forward",
  unfold: "fa fa-angle-double-down",
  file: "fa fa-paperclip",
  plus: "fa fa-plus",
  minus: "fa fa-minus"
};
function ii(t, e) {
  const i = {};
  for (const s in e)
    i[s] = {
      component: t,
      props: {
        icon: e[s].split(" fa-")
      }
    };
  return i;
}
const si = ii("font-awesome-icon", Zt), ni = Object.freeze({
  mdiSvg: Je,
  md: Qe,
  mdi: ti,
  fa: Zt,
  fa4: ei,
  faSvg: si
});
class G extends B {
  constructor(e) {
    super();
    const {
      iconfont: i,
      values: s,
      component: n
    } = e[G.property];
    this.component = n, this.iconfont = i, this.values = E(ni[i], s);
  }
}
G.property = "icons";
const Xt = "$vuetify.", wt = Symbol("Lang fallback");
function Kt(t, e, i = !1, s) {
  const n = e.replace(Xt, "");
  let r = k(t, n, wt);
  return r === wt && (i ? (C(`Translation key "${n}" not found in fallback`), r = e) : (I(`Translation key "${n}" not found, falling back to default`), r = Kt(s, e, !0, s))), r;
}
class J extends B {
  constructor(e) {
    super(), this.defaultLocale = "en";
    const {
      current: i,
      locales: s,
      t: n
    } = e[J.property];
    this.current = i, this.locales = s, this.translator = n || this.defaultTranslator;
  }
  currentLocale(e) {
    const i = this.locales[this.current], s = this.locales[this.defaultLocale];
    return Kt(i, e, !1, s);
  }
  t(e, ...i) {
    return e.startsWith(Xt) ? this.translator(e, ...i) : this.replace(e, i);
  }
  defaultTranslator(e, ...i) {
    return this.replace(this.currentLocale(e), i);
  }
  replace(e, i) {
    return e.replace(/\{(\d+)\}/g, (s, n) => String(i[+n]));
  }
}
J.property = "lang";
const ri = {
  badge: "Badge",
  close: "Close",
  dataIterator: {
    noResultsText: "No matching records found",
    loadingText: "Loading items..."
  },
  dataTable: {
    itemsPerPageText: "Rows per page:",
    ariaLabel: {
      sortDescending: "Sorted descending.",
      sortAscending: "Sorted ascending.",
      sortNone: "Not sorted.",
      activateNone: "Activate to remove sorting.",
      activateDescending: "Activate to sort descending.",
      activateAscending: "Activate to sort ascending."
    },
    sortBy: "Sort by"
  },
  dataFooter: {
    itemsPerPageText: "Items per page:",
    itemsPerPageAll: "All",
    nextPage: "Next page",
    prevPage: "Previous page",
    firstPage: "First page",
    lastPage: "Last page",
    pageText: "{0}-{1} of {2}"
  },
  datePicker: {
    itemsSelected: "{0} selected",
    nextMonthAriaLabel: "Next month",
    nextYearAriaLabel: "Next year",
    prevMonthAriaLabel: "Previous month",
    prevYearAriaLabel: "Previous year"
  },
  noDataText: "No data available",
  carousel: {
    prev: "Previous visual",
    next: "Next visual",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}"
    }
  },
  calendar: {
    moreEvents: "{0} more"
  },
  input: {
    clear: "Clear {0}",
    prependAction: "{0} prepended action",
    appendAction: "{0} appended action"
  },
  fileInput: {
    counter: "{0} files",
    counterSize: "{0} files ({1} in total)"
  },
  timePicker: {
    am: "AM",
    pm: "PM"
  },
  pagination: {
    ariaLabel: {
      wrapper: "Pagination Navigation",
      next: "Next page",
      previous: "Previous page",
      page: "Goto Page {0}",
      currentPage: "Current Page, Page {0}"
    }
  },
  rating: {
    ariaLabel: {
      icon: "Rating {0} of {1}"
    }
  },
  loading: "Loading..."
}, oi = {
  breakpoint: {
    // TODO: update to MD2 spec in v3 - 1280
    mobileBreakpoint: 1264,
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920
    }
  },
  icons: {
    // TODO: remove v3
    iconfont: "mdi",
    values: {}
  },
  lang: {
    current: "en",
    locales: {
      en: ri
    },
    // Default translator exists in lang service
    t: void 0
  },
  rtl: !1,
  theme: {
    dark: !1,
    default: "light",
    disable: !1,
    options: {
      cspNonce: void 0,
      customProperties: void 0,
      minifyTheme: void 0,
      themeCache: void 0,
      variations: !0
    },
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      },
      dark: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      }
    }
  }
};
class Ut extends B {
  constructor(e, i) {
    super();
    const s = E({}, oi), {
      userPreset: n
    } = i, {
      preset: r = {},
      ...o
    } = n;
    r.preset != null && I("Global presets do not support the **preset** option, it can be safely omitted"), i.preset = E(E(s, r), o);
  }
}
Ut.property = "presets";
const ai = [[3.2406, -1.5372, -0.4986], [-0.9689, 1.8758, 0.0415], [0.0557, -0.204, 1.057]], li = (t) => t <= 31308e-7 ? t * 12.92 : 1.055 * t ** (1 / 2.4) - 0.055, hi = [[0.4124, 0.3576, 0.1805], [0.2126, 0.7152, 0.0722], [0.0193, 0.1192, 0.9505]], ci = (t) => t <= 0.04045 ? t / 12.92 : ((t + 0.055) / 1.055) ** 2.4;
function Gt(t) {
  const e = Array(3), i = li, s = ai;
  for (let n = 0; n < 3; ++n)
    e[n] = Math.round(Ee(i(s[n][0] * t[0] + s[n][1] * t[1] + s[n][2] * t[2])) * 255);
  return (e[0] << 16) + (e[1] << 8) + (e[2] << 0);
}
function Jt(t) {
  const e = [0, 0, 0], i = ci, s = hi, n = i((t >> 16 & 255) / 255), r = i((t >> 8 & 255) / 255), o = i((t >> 0 & 255) / 255);
  for (let a = 0; a < 3; ++a)
    e[a] = s[a][0] * n + s[a][1] * r + s[a][2] * o;
  return e;
}
function Lt(t) {
  return !!t && !!t.match(/^(#|var\(--|(rgb|hsl)a?\()/);
}
function at(t) {
  let e;
  if (typeof t == "number")
    e = t;
  else if (typeof t == "string") {
    let i = t[0] === "#" ? t.substring(1) : t;
    i.length === 3 && (i = i.split("").map((s) => s + s).join("")), i.length !== 6 && I(`'${t}' is not a valid rgb color`), e = parseInt(i, 16);
  } else
    throw new TypeError(`Colors can only be numbers or strings, recieved ${t == null ? t : t.constructor.name} instead`);
  return e < 0 ? (I(`Colors cannot be negative: '${t}'`), e = 0) : (e > 16777215 || isNaN(e)) && (I(`'${t}' is not a valid rgb color`), e = 16777215), e;
}
function F(t) {
  let e = t.toString(16);
  return e.length < 6 && (e = "0".repeat(6 - e.length) + e), "#" + e;
}
function ui(t) {
  return F(at(t));
}
const q = 0.20689655172413793, di = (t) => t > q ** 3 ? Math.cbrt(t) : t / (3 * q ** 2) + 4 / 29, fi = (t) => t > q ? t ** 3 : 3 * q ** 2 * (t - 4 / 29);
function Qt(t) {
  const e = di, i = e(t[1]);
  return [116 * i - 16, 500 * (e(t[0] / 0.95047) - i), 200 * (i - e(t[2] / 1.08883))];
}
function te(t) {
  const e = fi, i = (t[0] + 16) / 116;
  return [e(i + t[1] / 500) * 0.95047, e(i), e(i - t[2] / 200) * 1.08883];
}
function ee(t, e = !1, i = !0) {
  const {
    anchor: s,
    ...n
  } = t, r = Object.keys(n), o = {};
  for (let a = 0; a < r.length; ++a) {
    const l = r[a], c = t[l];
    c != null && (i ? e ? (l === "base" || l.startsWith("lighten") || l.startsWith("darken")) && (o[l] = ui(c)) : typeof c == "object" ? o[l] = ee(c, !0, i) : o[l] = vi(l, at(c)) : o[l] = {
      base: F(at(c))
    });
  }
  return e || (o.anchor = s || o.base || o.primary.base), o;
}
const pi = (t, e) => `
.v-application .${t} {
  background-color: ${e} !important;
  border-color: ${e} !important;
}
.v-application .${t}--text {
  color: ${e} !important;
  caret-color: ${e} !important;
}`, mi = (t, e, i) => {
  const [s, n] = e.split(/(\d)/, 2);
  return `
.v-application .${t}.${s}-${n} {
  background-color: ${i} !important;
  border-color: ${i} !important;
}
.v-application .${t}--text.text--${s}-${n} {
  color: ${i} !important;
  caret-color: ${i} !important;
}`;
}, j = (t, e = "base") => `--v-${t}-${e}`, it = (t, e = "base") => `var(${j(t, e)})`;
function gi(t, e = !1) {
  const {
    anchor: i,
    ...s
  } = t, n = Object.keys(s);
  if (!n.length) return "";
  let r = "", o = "";
  const a = e ? it("anchor") : i;
  o += `.v-application a { color: ${a}; }`, e && (r += `  ${j("anchor")}: ${i};
`);
  for (let l = 0; l < n.length; ++l) {
    const c = n[l], u = t[c];
    o += pi(c, e ? it(c) : u.base), e && (r += `  ${j(c)}: ${u.base};
`);
    const p = zt(u);
    for (let g = 0; g < p.length; ++g) {
      const L = p[g], W = u[L];
      L !== "base" && (o += mi(c, L, e ? it(c, L) : W), e && (r += `  ${j(c, L)}: ${W};
`));
    }
  }
  return e && (r = `:root {
${r}}

`), r + o;
}
function vi(t, e) {
  const i = {
    base: F(e)
  };
  for (let s = 5; s > 0; --s)
    i[`lighten${s}`] = F(yi(e, s));
  for (let s = 1; s <= 4; ++s)
    i[`darken${s}`] = F(bi(e, s));
  return i;
}
function yi(t, e) {
  const i = Qt(Jt(t));
  return i[0] = i[0] + e * 10, Gt(te(i));
}
function bi(t, e) {
  const i = Qt(Jt(t));
  return i[0] = i[0] - e * 10, Gt(te(i));
}
class Q extends B {
  constructor(e) {
    super(), this.disabled = !1, this.isDark = null, this.unwatch = null, this.vueMeta = null;
    const {
      dark: i,
      disable: s,
      options: n,
      themes: r
    } = e[Q.property];
    if (this.dark = !!i, this.defaults = this.themes = r, this.options = n, s) {
      this.disabled = !0;
      return;
    }
    this.themes = {
      dark: this.fillVariant(r.dark, !0),
      light: this.fillVariant(r.light, !1)
    };
  }
  // When setting css, check for element and apply new values
  /* eslint-disable-next-line accessor-pairs */
  set css(e) {
    if (this.vueMeta) {
      this.isVueMeta23 && this.applyVueMeta23();
      return;
    }
    this.checkOrCreateStyleElement() && (this.styleEl.innerHTML = e);
  }
  set dark(e) {
    const i = this.isDark;
    this.isDark = e, i != null && this.applyTheme();
  }
  get dark() {
    return !!this.isDark;
  }
  // Apply current theme default
  // only called on client side
  applyTheme() {
    if (this.disabled) return this.clearCss();
    this.css = this.generatedStyles;
  }
  clearCss() {
    this.css = "";
  }
  // Initialize theme for SSR and SPA
  // Attach to ssrContext head or
  // apply new theme to document
  init(e, i) {
    this.disabled || (e.$meta ? this.initVueMeta(e) : i && this.initSSR(i), this.initTheme(e));
  }
  // Allows for you to set target theme
  setTheme(e, i) {
    this.themes[e] = Object.assign(this.themes[e], i), this.applyTheme();
  }
  // Reset theme defaults
  resetThemes() {
    this.themes.light = Object.assign({}, this.defaults.light), this.themes.dark = Object.assign({}, this.defaults.dark), this.applyTheme();
  }
  // Check for existence of style element
  checkOrCreateStyleElement() {
    return this.styleEl = document.getElementById("vuetify-theme-stylesheet"), this.styleEl ? !0 : (this.genStyleElement(), !!this.styleEl);
  }
  fillVariant(e = {}, i) {
    const s = this.themes[i ? "dark" : "light"];
    return Object.assign({}, s, e);
  }
  // Generate the style element
  // if applicable
  genStyleElement() {
    typeof document > "u" || (this.styleEl = document.createElement("style"), this.styleEl.type = "text/css", this.styleEl.id = "vuetify-theme-stylesheet", this.options.cspNonce && this.styleEl.setAttribute("nonce", this.options.cspNonce), document.head.appendChild(this.styleEl));
  }
  initVueMeta(e) {
    if (this.vueMeta = e.$meta(), this.isVueMeta23) {
      e.$nextTick(() => {
        this.applyVueMeta23();
      });
      return;
    }
    const i = typeof this.vueMeta.getOptions == "function" ? this.vueMeta.getOptions().keyName : "metaInfo", s = e.$options[i] || {};
    e.$options[i] = () => {
      s.style = s.style || [];
      const n = s.style.find((r) => r.id === "vuetify-theme-stylesheet");
      return n ? n.cssText = this.generatedStyles : s.style.push({
        cssText: this.generatedStyles,
        type: "text/css",
        id: "vuetify-theme-stylesheet",
        nonce: (this.options || {}).cspNonce
      }), s;
    };
  }
  applyVueMeta23() {
    const {
      set: e
    } = this.vueMeta.addApp("vuetify");
    e({
      style: [{
        cssText: this.generatedStyles,
        type: "text/css",
        id: "vuetify-theme-stylesheet",
        nonce: this.options.cspNonce
      }]
    });
  }
  initSSR(e) {
    const i = this.options.cspNonce ? ` nonce="${this.options.cspNonce}"` : "";
    e.head = e.head || "", e.head += `<style type="text/css" id="vuetify-theme-stylesheet"${i}>${this.generatedStyles}</style>`;
  }
  initTheme(e) {
    typeof document > "u" || (this.unwatch && (this.unwatch(), this.unwatch = null), e.$once("hook:created", () => {
      const i = d.observable({
        themes: this.themes
      });
      this.unwatch = e.$watch(() => i.themes, () => this.applyTheme(), {
        deep: !0
      });
    }), this.applyTheme());
  }
  get currentTheme() {
    const e = this.dark ? "dark" : "light";
    return this.themes[e];
  }
  get generatedStyles() {
    const e = this.parsedTheme, i = this.options || {};
    let s;
    return i.themeCache != null && (s = i.themeCache.get(e), s != null) || (s = gi(e, i.customProperties), i.minifyTheme != null && (s = i.minifyTheme(s)), i.themeCache != null && i.themeCache.set(e, s)), s;
  }
  get parsedTheme() {
    return ee(this.currentTheme || {}, void 0, ft(this.options, ["variations"], !0));
  }
  // Is using v2.3 of vue-meta
  // https://github.com/nuxt/vue-meta/releases/tag/v2.3.0
  get isVueMeta23() {
    return typeof this.vueMeta.addApp == "function";
  }
}
Q.property = "theme";
class z {
  constructor(e = {}) {
    this.framework = {
      isHydrating: !1
    }, this.installed = [], this.preset = {}, this.userPreset = {}, this.userPreset = e, this.use(Ut), this.use(Rt), this.use(U), this.use(qt), this.use(G), this.use(J), this.use(Q);
  }
  // Called on the new vuetify instance
  // bootstrap in install beforeCreate
  // Exposes ssrContext if available
  init(e, i) {
    this.installed.forEach((s) => {
      const n = this.framework[s];
      n.framework = this.framework, n.init(e, i);
    }), this.framework.rtl = !!this.preset.rtl;
  }
  // Instantiate a VuetifyService
  use(e) {
    const i = e.property;
    this.installed.includes(i) || (this.framework[i] = new e(this.preset, this), this.installed.push(i));
  }
}
z.install = ot;
z.installed = !1;
z.version = "2.7.2";
z.config = {
  silent: !1
};
function ie(t, e, i) {
  if (!z.config.silent) {
    if (i && (e = {
      _isVue: !0,
      $parent: i,
      $options: e
    }), e) {
      if (e.$_alreadyWarned = e.$_alreadyWarned || [], e.$_alreadyWarned.includes(t)) return;
      e.$_alreadyWarned.push(t);
    }
    return `[Vuetify] ${t}` + (e ? _i(e) : "");
  }
}
function I(t, e, i) {
  const s = ie(t, e, i);
  s != null && console.warn(s);
}
function C(t, e, i) {
  const s = ie(t, e, i);
  s != null && console.error(s);
}
function lt(t, e, i, s) {
  C(`[BREAKING] '${t}' has been removed, use '${e}' instead. For more information, see the upgrade guide https://github.com/vuetifyjs/vuetify/releases/tag/v2.0.0#user-content-upgrade-guide`, i, s);
}
function vt(t, e, i) {
  I(`[REMOVED] '${t}' has been removed. You can safely omit it.`, e, i);
}
const xi = /(?:^|[-_])(\w)/g, $i = (t) => t.replace(xi, (e) => e.toUpperCase()).replace(/[-_]/g, "");
function st(t, e) {
  if (t.$root === t)
    return "<Root>";
  const i = typeof t == "function" && t.cid != null ? t.options : t._isVue ? t.$options || t.constructor.options : t || {};
  let s = i.name || i._componentTag;
  const n = i.__file;
  if (!s && n) {
    const r = n.match(/([^/\\]+)\.vue$/);
    s = r && r[1];
  }
  return (s ? `<${$i(s)}>` : "<Anonymous>") + (n && e !== !1 ? ` at ${n}` : "");
}
function _i(t) {
  if (t._isVue && t.$parent) {
    const e = [];
    let i = 0;
    for (; t; ) {
      if (e.length > 0) {
        const s = e[e.length - 1];
        if (s.constructor === t.constructor) {
          i++, t = t.$parent;
          continue;
        } else i > 0 && (e[e.length - 1] = [s, i], i = 0);
      }
      e.push(t), t = t.$parent;
    }
    return `

found in

` + e.map((s, n) => `${n === 0 ? "---> " : " ".repeat(5 + n * 2)}${Array.isArray(s) ? `${st(s[0])}... (${s[1]} recursive calls)` : st(s)}`).join(`
`);
  } else
    return `

(found in ${st(t)})`;
}
const x = d.extend({
  name: "colorable",
  props: {
    color: String
  },
  methods: {
    setBackgroundColor(t, e = {}) {
      return typeof e.style == "string" ? (C("style must be an object", this), e) : typeof e.class == "string" ? (C("class must be an object", this), e) : (Lt(t) ? e.style = {
        ...e.style,
        "background-color": `${t}`,
        "border-color": `${t}`
      } : t && (e.class = {
        ...e.class,
        [t]: !0
      }), e);
    },
    setTextColor(t, e = {}) {
      if (typeof e.style == "string")
        return C("style must be an object", this), e;
      if (typeof e.class == "string")
        return C("class must be an object", this), e;
      if (Lt(t))
        e.style = {
          ...e.style,
          color: `${t}`,
          "caret-color": `${t}`
        };
      else if (t) {
        const [i, s] = t.toString().trim().split(" ", 2);
        e.class = {
          ...e.class,
          [i + "--text"]: !0
        }, s && (e.class["text--" + s] = !0);
      }
      return e;
    }
  }
}), Ci = d.extend({
  name: "elevatable",
  props: {
    elevation: [Number, String]
  },
  computed: {
    computedElevation() {
      return this.elevation;
    },
    elevationClasses() {
      const t = this.computedElevation;
      return t == null ? {} : isNaN(parseInt(t)) ? {} : {
        [`elevation-${this.elevation}`]: !0
      };
    }
  }
}), Ii = d.extend({
  name: "measurable",
  props: {
    height: [Number, String],
    maxHeight: [Number, String],
    maxWidth: [Number, String],
    minHeight: [Number, String],
    minWidth: [Number, String],
    width: [Number, String]
  },
  computed: {
    measurableStyles() {
      const t = {}, e = f(this.height), i = f(this.minHeight), s = f(this.minWidth), n = f(this.maxHeight), r = f(this.maxWidth), o = f(this.width);
      return e && (t.height = e), i && (t.minHeight = i), s && (t.minWidth = s), n && (t.maxHeight = n), r && (t.maxWidth = r), o && (t.width = o), t;
    }
  }
}), se = d.extend({
  name: "roundable",
  props: {
    rounded: [Boolean, String],
    tile: Boolean
  },
  computed: {
    roundedClasses() {
      const t = [], e = typeof this.rounded == "string" ? String(this.rounded) : this.rounded === !0;
      if (this.tile)
        t.push("rounded-0");
      else if (typeof e == "string") {
        const i = e.split(" ");
        for (const s of i)
          t.push(`rounded-${s}`);
      } else e && t.push("rounded");
      return t.length > 0 ? {
        [t.join(" ")]: !0
      } : {};
    }
  }
}), St = m(gt, x, Ci, Ii, se, v).extend({
  name: "v-sheet",
  props: {
    outlined: Boolean,
    shaped: Boolean,
    tag: {
      type: String,
      default: "div"
    }
  },
  computed: {
    classes() {
      return {
        "v-sheet": !0,
        "v-sheet--outlined": this.outlined,
        "v-sheet--shaped": this.shaped,
        ...this.themeClasses,
        ...this.elevationClasses,
        ...this.roundedClasses
      };
    },
    styles() {
      return this.measurableStyles;
    }
  },
  render(t) {
    const e = {
      class: this.classes,
      style: this.styles,
      on: this.listeners$
    };
    return t(this.tag, this.setBackgroundColor(this.color, e), this.$slots.default);
  }
});
function ki(t, e, i) {
  if (typeof window > "u" || !("IntersectionObserver" in window)) return;
  const s = e.modifiers || {}, n = e.value, {
    handler: r,
    options: o
  } = typeof n == "object" ? n : {
    handler: n,
    options: {}
  }, a = new IntersectionObserver((l = [], c) => {
    var u;
    const p = (u = t._observe) === null || u === void 0 ? void 0 : u[i.context._uid];
    if (!p) return;
    const g = l.some((L) => L.isIntersecting);
    r && (!s.quiet || p.init) && (!s.once || g || p.init) && r(l, c, g), g && s.once ? ne(t, e, i) : p.init = !0;
  }, o);
  t._observe = Object(t._observe), t._observe[i.context._uid] = {
    init: !1,
    observer: a
  }, a.observe(t);
}
function ne(t, e, i) {
  var s;
  const n = (s = t._observe) === null || s === void 0 ? void 0 : s[i.context._uid];
  n && (n.observer.unobserve(t), delete t._observe[i.context._uid]);
}
const ht = {
  inserted: ki,
  unbind: ne
}, Tt = {
  styleList: /;(?![^(]*\))/g,
  styleProp: /:(.*)/
};
function At(t) {
  const e = {};
  for (const i of t.split(Tt.styleList)) {
    let [s, n] = i.split(Tt.styleProp);
    s = s.trim(), s && (typeof n == "string" && (n = n.trim()), e[Oe(s)] = n);
  }
  return e;
}
function S() {
  const t = {};
  let e = arguments.length, i;
  for (; e--; )
    for (i of Object.keys(arguments[e]))
      switch (i) {
        // Array merge strategy (array concatenation)
        case "class":
        case "directives":
          arguments[e][i] && (t[i] = Li(t[i], arguments[e][i]));
          break;
        case "style":
          arguments[e][i] && (t[i] = wi(t[i], arguments[e][i]));
          break;
        // Space delimited string concatenation strategy
        case "staticClass":
          if (!arguments[e][i])
            break;
          t[i] === void 0 && (t[i] = ""), t[i] && (t[i] += " "), t[i] += arguments[e][i].trim();
          break;
        // Object, the properties of which to merge via array merge strategy (array concatenation).
        // Callback merge strategy merges callbacks to the beginning of the array,
        // so that the last defined callback will be invoked first.
        // This is done since to mimic how Object.assign merging
        // uses the last given value to assign.
        case "on":
        case "nativeOn":
          arguments[e][i] && (t[i] = Si(t[i], arguments[e][i]));
          break;
        // Object merge strategy
        case "attrs":
        case "props":
        case "domProps":
        case "scopedSlots":
        case "staticStyle":
        case "hook":
        case "transition":
          if (!arguments[e][i])
            break;
          t[i] || (t[i] = {}), t[i] = {
            ...arguments[e][i],
            ...t[i]
          };
          break;
        // Reassignment strategy (no merge)
        default:
          t[i] || (t[i] = arguments[e][i]);
      }
  return t;
}
function wi(t, e) {
  return t ? e ? (t = mt(typeof t == "string" ? At(t) : t), t.concat(typeof e == "string" ? At(e) : e)) : t : e;
}
function Li(t, e) {
  return e ? t && t ? mt(t).concat(e) : e : t;
}
function Si(...t) {
  if (!t[0]) return t[1];
  if (!t[1]) return t[0];
  const e = {};
  for (let i = 2; i--; ) {
    const s = t[i];
    for (const n in s)
      s[n] && (e[n] ? e[n] = [].concat(s[n], e[n]) : e[n] = s[n]);
  }
  return e;
}
const Mt = {
  absolute: Boolean,
  bottom: Boolean,
  fixed: Boolean,
  left: Boolean,
  right: Boolean,
  top: Boolean
};
function yt(t = []) {
  return d.extend({
    name: "positionable",
    props: t.length ? Me(Mt, t) : Mt
  });
}
yt();
function Z(t) {
  if (typeof t.getRootNode != "function") {
    for (; t.parentNode; ) t = t.parentNode;
    return t !== document ? null : document;
  }
  const e = t.getRootNode();
  return e !== document && e.getRootNode({
    composed: !0
  }) !== document ? null : e;
}
function Ti() {
  return !0;
}
function re(t, e, i) {
  if (!t || oe(t, i) === !1) return !1;
  const s = Z(e);
  if (typeof ShadowRoot < "u" && s instanceof ShadowRoot && s.host === t.target) return !1;
  const n = (typeof i.value == "object" && i.value.include || (() => []))();
  return n.push(e), !n.some((r) => r.contains(t.target));
}
function oe(t, e) {
  return (typeof e.value == "object" && e.value.closeConditional || Ti)(t);
}
function Ai(t, e, i) {
  const s = typeof i.value == "function" ? i.value : i.value.handler;
  e._clickOutside.lastMousedownWasOutside && re(t, e, i) && setTimeout(() => {
    oe(t, i) && s && s(t);
  }, 0);
}
function Dt(t, e) {
  const i = Z(t);
  e(document), typeof ShadowRoot < "u" && i instanceof ShadowRoot && e(i);
}
const ae = {
  // [data-app] may not be found
  // if using bind, inserted makes
  // sure that the root element is
  // available, iOS does not support
  // clicks on body
  inserted(t, e, i) {
    const s = (r) => Ai(r, t, e), n = (r) => {
      t._clickOutside.lastMousedownWasOutside = re(r, t, e);
    };
    Dt(t, (r) => {
      r.addEventListener("click", s, !0), r.addEventListener("mousedown", n, !0);
    }), t._clickOutside || (t._clickOutside = {
      lastMousedownWasOutside: !0
    }), t._clickOutside[i.context._uid] = {
      onClick: s,
      onMousedown: n
    };
  },
  unbind(t, e, i) {
    t._clickOutside && (Dt(t, (s) => {
      var n;
      if (!s || !(!((n = t._clickOutside) === null || n === void 0) && n[i.context._uid])) return;
      const {
        onClick: r,
        onMousedown: o
      } = t._clickOutside[i.context._uid];
      s.removeEventListener("click", r, !0), s.removeEventListener("mousedown", o, !0);
    }), delete t._clickOutside[i.context._uid]);
  }
};
function Mi(t, e, i) {
  const s = e.value, n = e.options || {
    passive: !0
  };
  window.addEventListener("resize", s, n), t._onResize = Object(t._onResize), t._onResize[i.context._uid] = {
    callback: s,
    options: n
  }, (!e.modifiers || !e.modifiers.quiet) && s();
}
function Di(t, e, i) {
  var s;
  if (!(!((s = t._onResize) === null || s === void 0) && s[i.context._uid])) return;
  const {
    callback: n,
    options: r
  } = t._onResize[i.context._uid];
  window.removeEventListener("resize", n, r), delete t._onResize[i.context._uid];
}
const le = {
  inserted: Mi,
  unbind: Di
}, Bi = 80;
function Bt(t, e) {
  t.style.transform = e, t.style.webkitTransform = e;
}
function ct(t) {
  return t.constructor.name === "TouchEvent";
}
function he(t) {
  return t.constructor.name === "KeyboardEvent";
}
const Oi = (t, e, i = {}) => {
  let s = 0, n = 0;
  if (!he(t)) {
    const p = e.getBoundingClientRect(), g = ct(t) ? t.touches[t.touches.length - 1] : t;
    s = g.clientX - p.left, n = g.clientY - p.top;
  }
  let r = 0, o = 0.3;
  e._ripple && e._ripple.circle ? (o = 0.15, r = e.clientWidth / 2, r = i.center ? r : r + Math.sqrt((s - r) ** 2 + (n - r) ** 2) / 4) : r = Math.sqrt(e.clientWidth ** 2 + e.clientHeight ** 2) / 2;
  const a = `${(e.clientWidth - r * 2) / 2}px`, l = `${(e.clientHeight - r * 2) / 2}px`, c = i.center ? a : `${s - r}px`, u = i.center ? l : `${n - r}px`;
  return {
    radius: r,
    scale: o,
    x: c,
    y: u,
    centerX: a,
    centerY: l
  };
}, X = {
  /* eslint-disable max-statements */
  show(t, e, i = {}) {
    if (!e._ripple || !e._ripple.enabled)
      return;
    const s = document.createElement("span"), n = document.createElement("span");
    s.appendChild(n), s.className = "v-ripple__container", i.class && (s.className += ` ${i.class}`);
    const {
      radius: r,
      scale: o,
      x: a,
      y: l,
      centerX: c,
      centerY: u
    } = Oi(t, e, i), p = `${r * 2}px`;
    n.className = "v-ripple__animation", n.style.width = p, n.style.height = p, e.appendChild(s);
    const g = window.getComputedStyle(e);
    g && g.position === "static" && (e.style.position = "relative", e.dataset.previousPosition = "static"), n.classList.add("v-ripple__animation--enter"), n.classList.add("v-ripple__animation--visible"), Bt(n, `translate(${a}, ${l}) scale3d(${o},${o},${o})`), n.dataset.activated = String(performance.now()), setTimeout(() => {
      n.classList.remove("v-ripple__animation--enter"), n.classList.add("v-ripple__animation--in"), Bt(n, `translate(${c}, ${u}) scale3d(1,1,1)`);
    }, 0);
  },
  hide(t) {
    if (!t || !t._ripple || !t._ripple.enabled) return;
    const e = t.getElementsByClassName("v-ripple__animation");
    if (e.length === 0) return;
    const i = e[e.length - 1];
    if (i.dataset.isHiding) return;
    i.dataset.isHiding = "true";
    const s = performance.now() - Number(i.dataset.activated), n = Math.max(250 - s, 0);
    setTimeout(() => {
      i.classList.remove("v-ripple__animation--in"), i.classList.add("v-ripple__animation--out"), setTimeout(() => {
        var r;
        t.getElementsByClassName("v-ripple__animation").length === 1 && t.dataset.previousPosition && (t.style.position = t.dataset.previousPosition, delete t.dataset.previousPosition), ((r = i.parentNode) === null || r === void 0 ? void 0 : r.parentNode) === t && t.removeChild(i.parentNode);
      }, 300);
    }, n);
  }
};
function ce(t) {
  return typeof t > "u" || !!t;
}
function P(t) {
  const e = {}, i = t.currentTarget;
  if (!(!i || !i._ripple || i._ripple.touched || t.rippleStop)) {
    if (t.rippleStop = !0, ct(t))
      i._ripple.touched = !0, i._ripple.isTouch = !0;
    else if (i._ripple.isTouch) return;
    if (e.center = i._ripple.centered || he(t), i._ripple.class && (e.class = i._ripple.class), ct(t)) {
      if (i._ripple.showTimerCommit) return;
      i._ripple.showTimerCommit = () => {
        X.show(t, i, e);
      }, i._ripple.showTimer = window.setTimeout(() => {
        i && i._ripple && i._ripple.showTimerCommit && (i._ripple.showTimerCommit(), i._ripple.showTimerCommit = null);
      }, Bi);
    } else
      X.show(t, i, e);
  }
}
function y(t) {
  const e = t.currentTarget;
  if (!(!e || !e._ripple)) {
    if (window.clearTimeout(e._ripple.showTimer), t.type === "touchend" && e._ripple.showTimerCommit) {
      e._ripple.showTimerCommit(), e._ripple.showTimerCommit = null, e._ripple.showTimer = setTimeout(() => {
        y(t);
      });
      return;
    }
    window.setTimeout(() => {
      e._ripple && (e._ripple.touched = !1);
    }), X.hide(e);
  }
}
function ue(t) {
  const e = t.currentTarget;
  !e || !e._ripple || (e._ripple.showTimerCommit && (e._ripple.showTimerCommit = null), window.clearTimeout(e._ripple.showTimer));
}
let H = !1;
function de(t) {
  !H && (t.keyCode === h.enter || t.keyCode === h.space) && (H = !0, P(t));
}
function fe(t) {
  H = !1, y(t);
}
function pe(t) {
  H === !0 && (H = !1, y(t));
}
function me(t, e, i) {
  const s = ce(e.value);
  s || X.hide(t), t._ripple = t._ripple || {}, t._ripple.enabled = s;
  const n = e.value || {};
  n.center && (t._ripple.centered = !0), n.class && (t._ripple.class = e.value.class), n.circle && (t._ripple.circle = n.circle), s && !i ? (t.addEventListener("touchstart", P, {
    passive: !0
  }), t.addEventListener("touchend", y, {
    passive: !0
  }), t.addEventListener("touchmove", ue, {
    passive: !0
  }), t.addEventListener("touchcancel", y), t.addEventListener("mousedown", P), t.addEventListener("mouseup", y), t.addEventListener("mouseleave", y), t.addEventListener("keydown", de), t.addEventListener("keyup", fe), t.addEventListener("blur", pe), t.addEventListener("dragstart", y, {
    passive: !0
  })) : !s && i && ge(t);
}
function ge(t) {
  t.removeEventListener("mousedown", P), t.removeEventListener("touchstart", P), t.removeEventListener("touchend", y), t.removeEventListener("touchmove", ue), t.removeEventListener("touchcancel", y), t.removeEventListener("mouseup", y), t.removeEventListener("mouseleave", y), t.removeEventListener("keydown", de), t.removeEventListener("keyup", fe), t.removeEventListener("dragstart", y), t.removeEventListener("blur", pe);
}
function Vi(t, e, i) {
  me(t, e, !1), process.env.NODE_ENV === "development" && i.context && i.context.$nextTick(() => {
    const s = window.getComputedStyle(t);
    if (s && s.display === "inline") {
      const n = i.fnOptions ? [i.fnOptions, i.context] : [i.componentInstance];
      I("v-ripple can only be used on block-level elements", ...n);
    }
  });
}
function Ei(t) {
  delete t._ripple, ge(t);
}
function Fi(t, e) {
  if (e.value === e.oldValue)
    return;
  const i = ce(e.oldValue);
  me(t, e, i);
}
const D = {
  bind: Vi,
  unbind: Ei,
  update: Fi
};
function bt(t = "value", e = "input") {
  return d.extend({
    name: "toggleable",
    model: {
      prop: t,
      event: e
    },
    props: {
      [t]: {
        required: !1
      }
    },
    data() {
      return {
        isActive: !!this[t]
      };
    },
    watch: {
      [t](i) {
        this.isActive = !!i;
      },
      isActive(i) {
        !!i !== this[t] && this.$emit(e, i);
      }
    }
  });
}
const Pi = bt(), ve = d.extend({
  name: "sizeable",
  props: {
    large: Boolean,
    small: Boolean,
    xLarge: Boolean,
    xSmall: Boolean
  },
  computed: {
    medium() {
      return !this.xSmall && !this.small && !this.large && !this.xLarge;
    },
    sizeableClasses() {
      return {
        "v-size--x-small": this.xSmall,
        "v-size--small": this.small,
        "v-size--default": this.medium,
        "v-size--large": this.large,
        "v-size--x-large": this.xLarge
      };
    }
  }
});
var ut;
(function(t) {
  t.xSmall = "12px", t.small = "16px", t.default = "24px", t.medium = "28px", t.large = "36px", t.xLarge = "40px";
})(ut || (ut = {}));
function Hi(t) {
  return ["fas", "far", "fal", "fab", "fad", "fak"].some((e) => t.includes(e));
}
function Ni(t) {
  return /^[mzlhvcsqta]\s*[-+.0-9][^mlhvzcsqta]+/i.test(t) && /[\dz]$/i.test(t) && t.length > 4;
}
const Ot = m(
  gt,
  x,
  ve,
  v
  /* @vue/component */
).extend({
  name: "v-icon",
  props: {
    dense: Boolean,
    disabled: Boolean,
    left: Boolean,
    right: Boolean,
    size: [Number, String],
    tag: {
      type: String,
      required: !1,
      default: "i"
    }
  },
  computed: {
    medium() {
      return !1;
    },
    hasClickListener() {
      return !!(this.listeners$.click || this.listeners$["!click"]);
    }
  },
  methods: {
    getIcon() {
      let t = "";
      return this.$slots.default && (t = this.$slots.default[0].text.trim()), De(this, t);
    },
    getSize() {
      const t = {
        xSmall: this.xSmall,
        small: this.small,
        medium: this.medium,
        large: this.large,
        xLarge: this.xLarge
      }, e = zt(t).find((i) => t[i]);
      return e && ut[e] || f(this.size);
    },
    // Component data for both font icon and SVG wrapper span
    getDefaultData() {
      return {
        staticClass: "v-icon notranslate",
        class: {
          "v-icon--disabled": this.disabled,
          "v-icon--left": this.left,
          "v-icon--link": this.hasClickListener,
          "v-icon--right": this.right,
          "v-icon--dense": this.dense
        },
        attrs: {
          "aria-hidden": !this.hasClickListener,
          disabled: this.hasClickListener && this.disabled,
          type: this.hasClickListener ? "button" : void 0,
          ...this.attrs$
        },
        on: this.listeners$
      };
    },
    getSvgWrapperData() {
      const t = this.getSize(), e = {
        ...this.getDefaultData(),
        style: t ? {
          fontSize: t,
          height: t,
          width: t
        } : void 0
      };
      return this.applyColors(e), e;
    },
    applyColors(t) {
      t.class = {
        ...t.class,
        ...this.themeClasses
      }, this.setTextColor(this.color, t);
    },
    renderFontIcon(t, e) {
      const i = [], s = this.getDefaultData();
      let n = "material-icons";
      const r = t.indexOf("-"), o = r <= -1;
      o ? i.push(t) : (n = t.slice(0, r), Hi(n) && (n = "")), s.class[n] = !0, s.class[t] = !o;
      const a = this.getSize();
      return a && (s.style = {
        fontSize: a
      }), this.applyColors(s), e(this.hasClickListener ? "button" : this.tag, s, i);
    },
    renderSvgIcon(t, e) {
      const i = {
        class: "v-icon__svg",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 24 24",
          role: "img",
          "aria-hidden": !0
        }
      }, s = this.getSize();
      return s && (i.style = {
        fontSize: s,
        height: s,
        width: s
      }), e(this.hasClickListener ? "button" : "span", this.getSvgWrapperData(), [e("svg", i, [e("path", {
        attrs: {
          d: t
        }
      })])]);
    },
    renderSvgIconComponent(t, e) {
      const i = {
        class: {
          "v-icon__component": !0
        }
      }, s = this.getSize();
      s && (i.style = {
        fontSize: s,
        height: s,
        width: s
      }), this.applyColors(i);
      const n = t.component;
      return i.props = t.props, i.nativeOn = i.on, e(this.hasClickListener ? "button" : "span", this.getSvgWrapperData(), [e(n, i)]);
    }
  },
  render(t) {
    const e = this.getIcon();
    return typeof e == "string" ? Ni(e) ? this.renderSvgIcon(e, t) : this.renderFontIcon(e, t) : this.renderSvgIconComponent(e, t);
  }
}), V = d.extend({
  name: "v-icon",
  $_wrapperFor: Ot,
  functional: !0,
  render(t, {
    data: e,
    children: i
  }) {
    let s = "";
    return e.domProps && (s = e.domProps.textContent || e.domProps.innerHTML || s, delete e.domProps.textContent, delete e.domProps.innerHTML), t(Ot, e, s ? [s] : i);
  }
});
function ye(t, e, i) {
  return d.extend({
    name: "registrable-inject",
    inject: {
      [t]: {
        default: null
      }
    }
  });
}
function xt(t, e, i) {
  return ye(t).extend({
    name: "groupable",
    props: {
      activeClass: {
        type: String,
        default() {
          if (this[t])
            return this[t].activeClass;
        }
      },
      disabled: Boolean
    },
    data() {
      return {
        isActive: !1
      };
    },
    computed: {
      groupClasses() {
        return this.activeClass ? {
          [this.activeClass]: this.isActive
        } : {};
      }
    },
    created() {
      this[t] && this[t].register(this);
    },
    beforeDestroy() {
      this[t] && this[t].unregister(this);
    },
    methods: {
      toggle(s) {
        if (this.disabled && s) {
          s.preventDefault();
          return;
        }
        this.$emit("change");
      }
    }
  });
}
xt("itemGroup");
const O = d.extend({
  name: "routable",
  directives: {
    Ripple: D
  },
  props: {
    activeClass: String,
    append: Boolean,
    disabled: Boolean,
    exact: {
      type: Boolean,
      default: void 0
    },
    exactPath: Boolean,
    exactActiveClass: String,
    link: Boolean,
    href: [String, Object],
    to: [String, Object],
    nuxt: Boolean,
    replace: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: null
    },
    tag: String,
    target: String
  },
  data: () => ({
    isActive: !1,
    proxyClass: ""
  }),
  computed: {
    classes() {
      const t = {};
      return this.to || (this.activeClass && (t[this.activeClass] = this.isActive), this.proxyClass && (t[this.proxyClass] = this.isActive)), t;
    },
    computedRipple() {
      var t;
      return (t = this.ripple) !== null && t !== void 0 ? t : !this.disabled && this.isClickable;
    },
    isClickable() {
      return this.disabled ? !1 : !!(this.isLink || this.$listeners.click || this.$listeners["!click"] || this.$attrs.tabindex);
    },
    isLink() {
      return this.to || this.href || this.link;
    },
    styles: () => ({})
  },
  watch: {
    $route: "onRouteChange"
  },
  mounted() {
    this.onRouteChange();
  },
  methods: {
    generateRouteLink() {
      let t = this.exact, e;
      const i = {
        attrs: {
          tabindex: "tabindex" in this.$attrs ? this.$attrs.tabindex : void 0
        },
        class: this.classes,
        style: this.styles,
        props: {},
        directives: [{
          name: "ripple",
          value: this.computedRipple
        }],
        [this.to ? "nativeOn" : "on"]: {
          ...this.$listeners,
          ..."click" in this ? {
            click: this.click
          } : void 0
          // #14447
        },
        ref: "link"
      };
      if (typeof this.exact > "u" && (t = this.to === "/" || this.to === Object(this.to) && this.to.path === "/"), this.to) {
        let s = this.activeClass, n = this.exactActiveClass || s;
        this.proxyClass && (s = `${s} ${this.proxyClass}`.trim(), n = `${n} ${this.proxyClass}`.trim()), e = this.nuxt ? "nuxt-link" : "router-link", Object.assign(i.props, {
          to: this.to,
          exact: t,
          exactPath: this.exactPath,
          activeClass: s,
          exactActiveClass: n,
          append: this.append,
          replace: this.replace
        });
      } else
        e = this.href && "a" || this.tag || "div", e === "a" && this.href && (i.attrs.href = this.href);
      return this.target && (i.attrs.target = this.target), {
        tag: e,
        data: i
      };
    },
    onRouteChange() {
      if (!this.to || !this.$refs.link || !this.$route) return;
      const t = `${this.activeClass || ""} ${this.proxyClass || ""}`.trim(), e = `${this.exactActiveClass || ""} ${this.proxyClass || ""}`.trim() || t, i = "_vnode.data.class." + (this.exact ? e : t);
      this.$nextTick(() => {
        !k(this.$refs.link, i) === this.isActive && this.toggle();
      });
    },
    toggle() {
      this.isActive = !this.isActive;
    }
  }
});
function nt(t = [], ...e) {
  return Array().concat(t, ...e);
}
function be(t, e = "top center 0", i) {
  return {
    name: t,
    functional: !0,
    props: {
      group: {
        type: Boolean,
        default: !1
      },
      hideOnLeave: {
        type: Boolean,
        default: !1
      },
      leaveAbsolute: {
        type: Boolean,
        default: !1
      },
      mode: {
        type: String,
        default: i
      },
      origin: {
        type: String,
        default: e
      }
    },
    render(s, n) {
      const r = `transition${n.props.group ? "-group" : ""}`, o = {
        props: {
          name: t,
          mode: n.props.mode
        },
        on: {
          beforeEnter(a) {
            a.style.transformOrigin = n.props.origin, a.style.webkitTransformOrigin = n.props.origin;
          }
        }
      };
      return n.props.leaveAbsolute && (o.on.leave = nt(o.on.leave, (a) => {
        const {
          offsetTop: l,
          offsetLeft: c,
          offsetWidth: u,
          offsetHeight: p
        } = a;
        a._transitionInitialStyles = {
          position: a.style.position,
          top: a.style.top,
          left: a.style.left,
          width: a.style.width,
          height: a.style.height
        }, a.style.position = "absolute", a.style.top = l + "px", a.style.left = c + "px", a.style.width = u + "px", a.style.height = p + "px";
      }), o.on.afterLeave = nt(o.on.afterLeave, (a) => {
        if (a && a._transitionInitialStyles) {
          const {
            position: l,
            top: c,
            left: u,
            width: p,
            height: g
          } = a._transitionInitialStyles;
          delete a._transitionInitialStyles, a.style.position = l || "", a.style.top = c || "", a.style.left = u || "", a.style.width = p || "", a.style.height = g || "";
        }
      })), n.props.hideOnLeave && (o.on.leave = nt(o.on.leave, (a) => {
        a.style.setProperty("display", "none", "important");
      })), s(r, S(n.data, o), n.children);
    }
  };
}
function xe(t, e, i = "in-out") {
  return {
    name: t,
    functional: !0,
    props: {
      mode: {
        type: String,
        default: i
      }
    },
    render(s, n) {
      return s("transition", S(n.data, {
        props: {
          name: t
        },
        on: e
      }), n.children);
    }
  };
}
function $e(t = "", e = !1) {
  const i = e ? "width" : "height", s = `offset${Ve(i)}`;
  return {
    beforeEnter(o) {
      o._parent = o.parentNode, o._initialStyle = {
        transition: o.style.transition,
        overflow: o.style.overflow,
        [i]: o.style[i]
      };
    },
    enter(o) {
      const a = o._initialStyle;
      o.style.setProperty("transition", "none", "important"), o.style.overflow = "hidden";
      const l = `${o[s]}px`;
      o.style[i] = "0", o.offsetHeight, o.style.transition = a.transition, t && o._parent && o._parent.classList.add(t), requestAnimationFrame(() => {
        o.style[i] = l;
      });
    },
    afterEnter: r,
    enterCancelled: r,
    leave(o) {
      o._initialStyle = {
        transition: "",
        overflow: o.style.overflow,
        [i]: o.style[i]
      }, o.style.overflow = "hidden", o.style[i] = `${o[s]}px`, o.offsetHeight, requestAnimationFrame(() => o.style[i] = "0");
    },
    afterLeave: n,
    leaveCancelled: n
  };
  function n(o) {
    t && o._parent && o._parent.classList.remove(t), r(o);
  }
  function r(o) {
    const a = o._initialStyle[i];
    o.style.overflow = o._initialStyle.overflow, a != null && (o.style[i] = a), delete o._initialStyle;
  }
}
const zi = be("fade-transition"), Wi = be("slide-x-transition"), pn = xe("expand-transition", $e()), Ri = xe("expand-x-transition", $e("", !0)), _e = m(x, ve, O, v, xt("chipGroup"), bt("inputValue")).extend({
  name: "v-chip",
  props: {
    active: {
      type: Boolean,
      default: !0
    },
    activeClass: {
      type: String,
      default() {
        return this.chipGroup ? this.chipGroup.activeClass : "";
      }
    },
    close: Boolean,
    closeIcon: {
      type: String,
      default: "$delete"
    },
    closeLabel: {
      type: String,
      default: "$vuetify.close"
    },
    disabled: Boolean,
    draggable: Boolean,
    filter: Boolean,
    filterIcon: {
      type: String,
      default: "$complete"
    },
    label: Boolean,
    link: Boolean,
    outlined: Boolean,
    pill: Boolean,
    tag: {
      type: String,
      default: "span"
    },
    textColor: String,
    value: null
  },
  data: () => ({
    proxyClass: "v-chip--active"
  }),
  computed: {
    classes() {
      return {
        "v-chip": !0,
        ...O.options.computed.classes.call(this),
        "v-chip--clickable": this.isClickable,
        "v-chip--disabled": this.disabled,
        "v-chip--draggable": this.draggable,
        "v-chip--label": this.label,
        "v-chip--link": this.isLink,
        "v-chip--no-color": !this.color,
        "v-chip--outlined": this.outlined,
        "v-chip--pill": this.pill,
        "v-chip--removable": this.hasClose,
        ...this.themeClasses,
        ...this.sizeableClasses,
        ...this.groupClasses
      };
    },
    hasClose() {
      return !!this.close;
    },
    isClickable() {
      return !!(O.options.computed.isClickable.call(this) || this.chipGroup);
    }
  },
  created() {
    [["outline", "outlined"], ["selected", "input-value"], ["value", "active"], ["@input", "@active.sync"]].forEach(([e, i]) => {
      this.$attrs.hasOwnProperty(e) && lt(e, i, this);
    });
  },
  methods: {
    click(t) {
      this.$emit("click", t), this.chipGroup && this.toggle();
    },
    genFilter() {
      const t = [];
      return this.isActive && t.push(this.$createElement(V, {
        staticClass: "v-chip__filter",
        props: {
          left: !0
        }
      }, this.filterIcon)), this.$createElement(Ri, t);
    },
    genClose() {
      return this.$createElement(V, {
        staticClass: "v-chip__close",
        props: {
          right: !0,
          size: 18
        },
        attrs: {
          "aria-label": this.$vuetify.lang.t(this.closeLabel)
        },
        on: {
          click: (t) => {
            t.stopPropagation(), t.preventDefault(), this.$emit("click:close"), this.$emit("update:active", !1);
          }
        }
      }, this.closeIcon);
    },
    genContent() {
      return this.$createElement("span", {
        staticClass: "v-chip__content"
      }, [this.filter && this.genFilter(), b(this), this.hasClose && this.genClose()]);
    }
  },
  render(t) {
    const e = [this.genContent()];
    let {
      tag: i,
      data: s
    } = this.generateRouteLink();
    s.attrs = {
      ...s.attrs,
      draggable: this.draggable ? "true" : void 0,
      tabindex: this.chipGroup && !this.disabled ? 0 : s.attrs.tabindex
    }, s.directives.push({
      name: "show",
      value: this.active
    }), s = this.setBackgroundColor(this.color, s);
    const n = this.textColor || this.outlined && this.color;
    return t(i, this.setTextColor(n, s), e);
  }
}), ji = v.extend({
  name: "v-theme-provider",
  props: {
    root: Boolean
  },
  computed: {
    isDark() {
      return this.root ? this.rootIsDark : v.options.computed.isDark.call(this);
    }
  },
  render() {
    return this.$slots.default && this.$slots.default.find((t) => !t.isComment && t.text !== " ");
  }
}), $t = d.extend().extend({
  name: "delayable",
  props: {
    openDelay: {
      type: [Number, String],
      default: 0
    },
    closeDelay: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => ({
    openTimeout: void 0,
    closeTimeout: void 0
  }),
  methods: {
    /**
     * Clear any pending delay timers from executing
     */
    clearDelay() {
      clearTimeout(this.openTimeout), clearTimeout(this.closeTimeout);
    },
    /**
     * Runs callback after a specified delay
     */
    runDelay(t, e) {
      this.clearDelay();
      const i = parseInt(this[`${t}Delay`], 10);
      this[`${t}Timeout`] = setTimeout(e || (() => {
        this.isActive = {
          open: !0,
          close: !1
        }[t];
      }), i);
    }
  }
}), Yi = m($t, Pi), tt = Yi.extend({
  name: "activatable",
  props: {
    activator: {
      default: null,
      validator: (t) => ["string", "object"].includes(typeof t)
    },
    disabled: Boolean,
    internalActivator: Boolean,
    openOnClick: {
      type: Boolean,
      default: !0
    },
    openOnHover: Boolean,
    openOnFocus: Boolean
  },
  data: () => ({
    // Do not use this directly, call getActivator() instead
    activatorElement: null,
    activatorNode: [],
    events: ["click", "mouseenter", "mouseleave", "focus"],
    listeners: {}
  }),
  watch: {
    activator: "resetActivator",
    openOnFocus: "resetActivator",
    openOnHover: "resetActivator"
  },
  mounted() {
    const t = Wt(this, "activator");
    t && ["v-slot", "normal"].includes(t) && C(`The activator slot must be bound, try '<template v-slot:activator="{ on }"><v-btn v-on="on">'`, this), this.addActivatorEvents();
  },
  beforeDestroy() {
    this.removeActivatorEvents();
  },
  methods: {
    addActivatorEvents() {
      if (!this.activator || this.disabled || !this.getActivator()) return;
      this.listeners = this.genActivatorListeners();
      const t = Object.keys(this.listeners);
      for (const e of t)
        this.getActivator().addEventListener(e, this.listeners[e]);
    },
    genActivator() {
      const t = b(this, "activator", Object.assign(this.getValueProxy(), {
        on: this.genActivatorListeners(),
        attrs: this.genActivatorAttributes()
      })) || [];
      return this.activatorNode = t, t;
    },
    genActivatorAttributes() {
      return {
        role: this.openOnClick && !this.openOnHover ? "button" : void 0,
        "aria-haspopup": !0,
        "aria-expanded": String(this.isActive)
      };
    },
    genActivatorListeners() {
      if (this.disabled) return {};
      const t = {};
      return this.openOnHover ? (t.mouseenter = (e) => {
        this.getActivator(e), this.runDelay("open");
      }, t.mouseleave = (e) => {
        this.getActivator(e), this.runDelay("close");
      }) : this.openOnClick && (t.click = (e) => {
        const i = this.getActivator(e);
        i && i.focus(), e.stopPropagation(), this.isActive = !this.isActive;
      }), this.openOnFocus && (t.focus = (e) => {
        this.getActivator(e), e.stopPropagation(), this.isActive = !this.isActive;
      }), t;
    },
    getActivator(t) {
      if (this.activatorElement) return this.activatorElement;
      let e = null;
      if (this.activator) {
        const i = this.internalActivator ? this.$el : document;
        typeof this.activator == "string" ? e = i.querySelector(this.activator) : this.activator.$el ? e = this.activator.$el : e = this.activator;
      } else if (this.activatorNode.length === 1 || this.activatorNode.length && !t) {
        const i = this.activatorNode[0].componentInstance;
        i && i.$options.mixins && //                         Activatable is indirectly used via Menuable
        i.$options.mixins.some((s) => s.options && ["activatable", "menuable"].includes(s.options.name)) ? e = i.getActivator() : e = this.activatorNode[0].elm;
      } else t && (e = t.currentTarget || t.target);
      return this.activatorElement = (e == null ? void 0 : e.nodeType) === Node.ELEMENT_NODE ? e : null, this.activatorElement;
    },
    getContentSlot() {
      return b(this, "default", this.getValueProxy(), !0);
    },
    getValueProxy() {
      const t = this;
      return {
        get value() {
          return t.isActive;
        },
        set value(e) {
          t.isActive = e;
        }
      };
    },
    removeActivatorEvents() {
      if (!this.activator || !this.activatorElement) return;
      const t = Object.keys(this.listeners);
      for (const e of t)
        this.activatorElement.removeEventListener(e, this.listeners[e]);
      this.listeners = {};
    },
    resetActivator() {
      this.removeActivatorEvents(), this.activatorElement = null, this.getActivator(), this.addActivatorEvents();
    }
  }
});
function Ce(t) {
  const e = [];
  for (let i = 0; i < t.length; i++) {
    const s = t[i];
    s.isActive && s.isDependent ? e.push(s) : e.push(...Ce(s.$children));
  }
  return e;
}
const _t = m().extend({
  name: "dependent",
  data() {
    return {
      closeDependents: !0,
      isActive: !1,
      isDependent: !0
    };
  },
  watch: {
    isActive(t) {
      if (t) return;
      const e = this.getOpenDependents();
      for (let i = 0; i < e.length; i++)
        e[i].isActive = !1;
    }
  },
  methods: {
    getOpenDependents() {
      return this.closeDependents ? Ce(this.$children) : [];
    },
    getOpenDependentElements() {
      const t = [], e = this.getOpenDependents();
      for (let i = 0; i < e.length; i++)
        t.push(...e[i].getClickableDependentElements());
      return t;
    },
    getClickableDependentElements() {
      const t = [this.$el];
      return this.$refs.content && t.push(this.$refs.content), this.overlay && t.push(this.overlay.$el), t.push(...this.getOpenDependentElements()), t;
    }
  }
}), qi = d.extend().extend({
  name: "stackable",
  data() {
    return {
      stackElement: null,
      stackExclude: null,
      stackMinZIndex: 0,
      isActive: !1
    };
  },
  computed: {
    activeZIndex() {
      if (typeof window > "u") return 0;
      const t = this.stackElement || this.$refs.content, e = this.isActive ? this.getMaxZIndex(this.stackExclude || [t]) + 2 : R(t);
      return e == null ? e : parseInt(e);
    }
  },
  methods: {
    getMaxZIndex(t = []) {
      const e = this.$el, i = [this.stackMinZIndex, R(e)], s = [...document.getElementsByClassName("v-menu__content--active"), ...document.getElementsByClassName("v-dialog__content--active")];
      for (let n = 0; n < s.length; n++)
        t.includes(s[n]) || i.push(R(s[n]));
      return Math.max(...i);
    }
  }
}), Zi = d.extend().extend({
  name: "bootable",
  props: {
    eager: Boolean
  },
  data: () => ({
    isBooted: !1
  }),
  computed: {
    hasContent() {
      return this.isBooted || this.eager || this.isActive;
    }
  },
  watch: {
    isActive() {
      this.isBooted = !0;
    }
  },
  created() {
    "lazy" in this.$attrs && vt("lazy", this);
  },
  methods: {
    showLazyContent(t) {
      return this.hasContent && t ? t() : [this.$createElement()];
    }
  }
});
function Xi(t) {
  const e = typeof t;
  return e === "boolean" || e === "string" ? !0 : t.nodeType === Node.ELEMENT_NODE;
}
function Vt(t) {
  t.forEach((e) => {
    e.elm && e.elm.parentNode && e.elm.parentNode.removeChild(e.elm);
  });
}
const Ki = m(Zi).extend({
  name: "detachable",
  props: {
    attach: {
      default: !1,
      validator: Xi
    },
    contentClass: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    activatorNode: null,
    hasDetached: !1
  }),
  watch: {
    attach() {
      this.hasDetached = !1, this.initDetach();
    },
    hasContent() {
      this.$nextTick(this.initDetach);
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.activatorNode && (Array.isArray(this.activatorNode) ? this.activatorNode : [this.activatorNode]).forEach((e) => {
        if (!e.elm || !this.$el.parentNode) return;
        const i = this.$el === this.$el.parentNode.firstChild ? this.$el : this.$el.nextSibling;
        this.$el.parentNode.insertBefore(e.elm, i);
      });
    });
  },
  mounted() {
    this.hasContent && this.initDetach();
  },
  deactivated() {
    this.isActive = !1;
  },
  beforeDestroy() {
    this.$refs.content && this.$refs.content.parentNode && this.$refs.content.parentNode.removeChild(this.$refs.content);
  },
  destroyed() {
    if (this.activatorNode) {
      const t = Array.isArray(this.activatorNode) ? this.activatorNode : [this.activatorNode];
      if (this.$el.isConnected) {
        const e = new MutationObserver((i) => {
          i.some((s) => Array.from(s.removedNodes).includes(this.$el)) && (e.disconnect(), Vt(t));
        });
        e.observe(this.$el.parentNode, {
          subtree: !1,
          childList: !0
        });
      } else
        Vt(t);
    }
  },
  methods: {
    getScopeIdAttrs() {
      const t = k(this.$vnode, "context.$options._scopeId");
      return t && {
        [t]: ""
      };
    },
    initDetach() {
      if (this._isDestroyed || !this.$refs.content || this.hasDetached || // Leave menu in place if attached
      // and dev has not changed target
      this.attach === "" || // If used as a boolean prop (<v-menu attach>)
      this.attach === !0 || // If bound to a boolean (<v-menu :attach="true">)
      this.attach === "attach") return;
      let t;
      if (this.attach === !1 ? t = document.querySelector("[data-app]") : typeof this.attach == "string" ? t = document.querySelector(this.attach) : t = this.attach, !t) {
        I(`Unable to locate target ${this.attach || "[data-app]"}`, this);
        return;
      }
      t.appendChild(this.$refs.content), this.hasDetached = !0;
    }
  }
}), Ui = m(qi, yt(["top", "right", "bottom", "left", "absolute"]), tt, Ki), Ct = Ui.extend().extend({
  name: "menuable",
  props: {
    allowOverflow: Boolean,
    light: Boolean,
    dark: Boolean,
    maxWidth: {
      type: [Number, String],
      default: "auto"
    },
    minWidth: [Number, String],
    nudgeBottom: {
      type: [Number, String],
      default: 0
    },
    nudgeLeft: {
      type: [Number, String],
      default: 0
    },
    nudgeRight: {
      type: [Number, String],
      default: 0
    },
    nudgeTop: {
      type: [Number, String],
      default: 0
    },
    nudgeWidth: {
      type: [Number, String],
      default: 0
    },
    offsetOverflow: Boolean,
    positionX: {
      type: Number,
      default: null
    },
    positionY: {
      type: Number,
      default: null
    },
    zIndex: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({
    activatorNode: [],
    absoluteX: 0,
    absoluteY: 0,
    activatedBy: null,
    activatorFixed: !1,
    dimensions: {
      activator: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        offsetTop: 0,
        scrollHeight: 0,
        offsetLeft: 0
      },
      content: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        offsetTop: 0,
        scrollHeight: 0
      }
    },
    relativeYOffset: 0,
    hasJustFocused: !1,
    hasWindow: !1,
    inputActivator: !1,
    isContentActive: !1,
    pageWidth: 0,
    pageYOffset: 0,
    stackClass: "v-menu__content--active",
    stackMinZIndex: 6
  }),
  computed: {
    computedLeft() {
      const t = this.dimensions.activator, e = this.dimensions.content, i = (this.attach !== !1 ? t.offsetLeft : t.left) || 0, s = Math.max(t.width, e.width);
      let n = 0;
      if (n += i, (this.left || this.$vuetify.rtl && !this.right) && (n -= s - t.width), this.offsetX) {
        const r = isNaN(Number(this.maxWidth)) ? t.width : Math.min(t.width, Number(this.maxWidth));
        n += this.left ? -r : t.width;
      }
      return this.nudgeLeft && (n -= parseInt(this.nudgeLeft)), this.nudgeRight && (n += parseInt(this.nudgeRight)), n;
    },
    computedTop() {
      const t = this.dimensions.activator, e = this.dimensions.content;
      let i = 0;
      return this.top && (i += t.height - e.height), this.attach !== !1 ? i += t.offsetTop : i += t.top + this.pageYOffset, this.offsetY && (i += this.top ? -t.height : t.height), this.nudgeTop && (i -= parseInt(this.nudgeTop)), this.nudgeBottom && (i += parseInt(this.nudgeBottom)), i;
    },
    hasActivator() {
      return !!this.$slots.activator || !!this.$scopedSlots.activator || !!this.activator || !!this.inputActivator;
    },
    absoluteYOffset() {
      return this.pageYOffset - this.relativeYOffset;
    }
  },
  watch: {
    disabled(t) {
      t && this.callDeactivate();
    },
    isActive(t) {
      this.disabled || (t ? this.callActivate() : this.callDeactivate());
    },
    positionX: "updateDimensions",
    positionY: "updateDimensions"
  },
  beforeMount() {
    this.hasWindow = typeof window < "u", this.hasWindow && window.addEventListener("resize", this.updateDimensions, !1);
  },
  beforeDestroy() {
    this.hasWindow && window.removeEventListener("resize", this.updateDimensions, !1);
  },
  methods: {
    absolutePosition() {
      return {
        offsetTop: this.positionY || this.absoluteY,
        offsetLeft: this.positionX || this.absoluteX,
        scrollHeight: 0,
        top: this.positionY || this.absoluteY,
        bottom: this.positionY || this.absoluteY,
        left: this.positionX || this.absoluteX,
        right: this.positionX || this.absoluteX,
        height: 0,
        width: 0
      };
    },
    activate() {
    },
    calcLeft(t) {
      return f(this.attach !== !1 ? this.computedLeft : this.calcXOverflow(this.computedLeft, t));
    },
    calcTop() {
      return f(this.attach !== !1 ? this.computedTop : this.calcYOverflow(this.computedTop));
    },
    calcXOverflow(t, e) {
      const i = t + e - this.pageWidth + 12;
      return (!this.left || this.right) && i > 0 ? t = Math.max(t - i, 0) : t = Math.max(t, 12), t + this.getOffsetLeft();
    },
    calcYOverflow(t) {
      const e = this.getInnerHeight(), i = this.absoluteYOffset + e, s = this.dimensions.activator, n = this.dimensions.content.height, r = t + n, o = i < r;
      return o && this.offsetOverflow && // If we don't have enough room to offset
      // the overflow, don't offset
      s.top > n ? t = this.pageYOffset + (s.top - n) : o && !this.allowOverflow ? t = i - n - 12 : t < this.absoluteYOffset && !this.allowOverflow && (t = this.absoluteYOffset + 12), t < 12 ? 12 : t;
    },
    callActivate() {
      this.hasWindow && this.activate();
    },
    callDeactivate() {
      this.isContentActive = !1, this.deactivate();
    },
    checkForPageYOffset() {
      this.hasWindow && (this.pageYOffset = this.activatorFixed ? 0 : this.getOffsetTop());
    },
    checkActivatorFixed() {
      if (this.attach !== !1) {
        this.activatorFixed = !1;
        return;
      }
      let t = this.getActivator();
      for (; t; ) {
        if (window.getComputedStyle(t).position === "fixed") {
          this.activatorFixed = !0;
          return;
        }
        t = t.offsetParent;
      }
      this.activatorFixed = !1;
    },
    deactivate() {
    },
    genActivatorListeners() {
      const t = tt.options.methods.genActivatorListeners.call(this), e = t.click;
      return e && (t.click = (i) => {
        this.openOnClick && e && e(i), this.absoluteX = i.clientX, this.absoluteY = i.clientY;
      }), t;
    },
    getInnerHeight() {
      return this.hasWindow ? window.innerHeight || document.documentElement.clientHeight : 0;
    },
    getOffsetLeft() {
      return this.hasWindow ? window.pageXOffset || document.documentElement.scrollLeft : 0;
    },
    getOffsetTop() {
      return this.hasWindow ? window.pageYOffset || document.documentElement.scrollTop : 0;
    },
    getRoundedBoundedClientRect(t) {
      const e = t.getBoundingClientRect();
      return {
        top: Math.round(e.top),
        left: Math.round(e.left),
        bottom: Math.round(e.bottom),
        right: Math.round(e.right),
        width: Math.round(e.width),
        height: Math.round(e.height)
      };
    },
    measure(t) {
      if (!t || !this.hasWindow) return null;
      const e = this.getRoundedBoundedClientRect(t);
      if (this.attach !== !1) {
        const i = window.getComputedStyle(t);
        e.left = parseInt(i.marginLeft), e.top = parseInt(i.marginTop);
      }
      return e;
    },
    sneakPeek(t) {
      requestAnimationFrame(() => {
        const e = this.$refs.content;
        if (!e || e.style.display !== "none") {
          t();
          return;
        }
        e.style.display = "inline-block", t(), e.style.display = "none";
      });
    },
    startTransition() {
      return new Promise((t) => requestAnimationFrame(() => {
        this.isContentActive = this.hasJustFocused = this.isActive, t();
      }));
    },
    updateDimensions() {
      this.hasWindow = typeof window < "u", this.checkActivatorFixed(), this.checkForPageYOffset(), this.pageWidth = document.documentElement.clientWidth;
      const t = {
        activator: {
          ...this.dimensions.activator
        },
        content: {
          ...this.dimensions.content
        }
      };
      if (!this.hasActivator || this.absolute)
        t.activator = this.absolutePosition();
      else {
        const e = this.getActivator();
        if (!e) return;
        t.activator = this.measure(e), t.activator.offsetLeft = e.offsetLeft, this.attach !== !1 ? t.activator.offsetTop = e.offsetTop : t.activator.offsetTop = 0;
      }
      this.sneakPeek(() => {
        if (this.$refs.content) {
          if (this.$refs.content.offsetParent) {
            const e = this.getRoundedBoundedClientRect(this.$refs.content.offsetParent);
            this.relativeYOffset = window.pageYOffset + e.top, t.activator.top -= this.relativeYOffset, t.activator.left -= window.pageXOffset + e.left;
          }
          t.content = this.measure(this.$refs.content);
        }
        this.dimensions = t;
      });
    }
  }
}), Gi = d.extend({
  name: "returnable",
  props: {
    returnValue: null
  },
  data: () => ({
    isActive: !1,
    originalValue: null
  }),
  watch: {
    isActive(t) {
      t ? this.originalValue = this.returnValue : this.$emit("update:return-value", this.originalValue);
    }
  },
  methods: {
    save(t) {
      this.originalValue = t, setTimeout(() => {
        this.isActive = !1;
      });
    }
  }
}), Ji = m(_t, $t, Gi, se, v, Ct), Qi = Ji.extend({
  name: "v-menu",
  directives: {
    ClickOutside: ae,
    Resize: le
  },
  provide() {
    return {
      isInMenu: !0,
      // Pass theme through to default slot
      theme: this.theme
    };
  },
  props: {
    auto: Boolean,
    closeOnClick: {
      type: Boolean,
      default: !0
    },
    closeOnContentClick: {
      type: Boolean,
      default: !0
    },
    disabled: Boolean,
    disableKeys: Boolean,
    maxHeight: {
      type: [Number, String],
      default: "auto"
    },
    offsetX: Boolean,
    offsetY: Boolean,
    openOnHover: Boolean,
    origin: {
      type: String,
      default: "top left"
    },
    transition: {
      type: [Boolean, String],
      default: "v-menu-transition"
    },
    contentProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      calculatedTopAuto: 0,
      defaultOffset: 8,
      hasJustFocused: !1,
      listIndex: -1,
      resizeTimeout: 0,
      selectedIndex: null,
      tiles: []
    };
  },
  computed: {
    activeTile() {
      return this.tiles[this.listIndex];
    },
    calculatedLeft() {
      const t = Math.max(this.dimensions.content.width, parseFloat(this.calculatedMinWidth));
      return this.auto ? f(this.calcXOverflow(this.calcLeftAuto(), t)) || "0" : this.calcLeft(t) || "0";
    },
    calculatedMaxHeight() {
      return (this.auto ? "200px" : f(this.maxHeight)) || "0";
    },
    calculatedMaxWidth() {
      return f(this.maxWidth) || "0";
    },
    calculatedMinWidth() {
      if (this.minWidth)
        return f(this.minWidth) || "0";
      const t = Math.min(this.dimensions.activator.width + Number(this.nudgeWidth) + (this.auto ? 16 : 0), Math.max(this.pageWidth - 24, 0)), e = isNaN(parseInt(this.calculatedMaxWidth)) ? t : parseInt(this.calculatedMaxWidth);
      return f(Math.min(e, t)) || "0";
    },
    calculatedTop() {
      return (this.auto ? f(this.calcYOverflow(this.calculatedTopAuto)) : this.calcTop()) || "0";
    },
    hasClickableTiles() {
      return !!this.tiles.find((t) => t.tabIndex > -1);
    },
    styles() {
      return {
        maxHeight: this.calculatedMaxHeight,
        minWidth: this.calculatedMinWidth,
        maxWidth: this.calculatedMaxWidth,
        top: this.calculatedTop,
        left: this.calculatedLeft,
        transformOrigin: this.origin,
        zIndex: this.zIndex || this.activeZIndex
      };
    }
  },
  watch: {
    isActive(t) {
      t || (this.listIndex = -1);
    },
    isContentActive(t) {
      this.hasJustFocused = t;
    },
    listIndex(t, e) {
      if (t in this.tiles) {
        const i = this.tiles[t];
        i.classList.add("v-list-item--highlighted");
        const s = this.$refs.content.scrollTop, n = this.$refs.content.clientHeight;
        s > i.offsetTop - 8 ? M(i.offsetTop - i.clientHeight, {
          appOffset: !1,
          duration: 300,
          container: this.$refs.content
        }) : s + n < i.offsetTop + i.clientHeight + 8 && M(i.offsetTop - n + i.clientHeight * 2, {
          appOffset: !1,
          duration: 300,
          container: this.$refs.content
        });
      }
      e in this.tiles && this.tiles[e].classList.remove("v-list-item--highlighted");
    }
  },
  created() {
    this.$attrs.hasOwnProperty("full-width") && vt("full-width", this);
  },
  mounted() {
    this.isActive && this.callActivate();
  },
  methods: {
    activate() {
      this.updateDimensions(), requestAnimationFrame(() => {
        this.startTransition().then(() => {
          this.$refs.content && (this.calculatedTopAuto = this.calcTopAuto(), this.auto && (this.$refs.content.scrollTop = this.calcScrollPosition()));
        });
      });
    },
    calcScrollPosition() {
      const t = this.$refs.content, e = t.querySelector(".v-list-item--active"), i = t.scrollHeight - t.offsetHeight;
      return e ? Math.min(i, Math.max(0, e.offsetTop - t.offsetHeight / 2 + e.offsetHeight / 2)) : t.scrollTop;
    },
    calcLeftAuto() {
      return parseInt(this.dimensions.activator.left - this.defaultOffset * 2);
    },
    calcTopAuto() {
      const t = this.$refs.content, e = t.querySelector(".v-list-item--active");
      if (e || (this.selectedIndex = null), this.offsetY || !e)
        return this.computedTop;
      this.selectedIndex = Array.from(this.tiles).indexOf(e);
      const i = e.offsetTop - this.calcScrollPosition(), s = t.querySelector(".v-list-item").offsetTop;
      return this.computedTop - i - s - 1;
    },
    changeListIndex(t) {
      if (this.getTiles(), !(!this.isActive || !this.hasClickableTiles)) {
        if (t.keyCode === h.tab) {
          this.isActive = !1;
          return;
        } else if (t.keyCode === h.down)
          this.nextTile();
        else if (t.keyCode === h.up)
          this.prevTile();
        else if (t.keyCode === h.end)
          this.lastTile();
        else if (t.keyCode === h.home)
          this.firstTile();
        else if (t.keyCode === h.enter && this.listIndex !== -1)
          this.tiles[this.listIndex].click();
        else
          return;
        t.preventDefault();
      }
    },
    closeConditional(t) {
      const e = t.target;
      return this.isActive && !this._isDestroyed && this.closeOnClick && !this.$refs.content.contains(e);
    },
    genActivatorAttributes() {
      const t = tt.options.methods.genActivatorAttributes.call(this);
      return this.activeTile && this.activeTile.id ? {
        ...t,
        "aria-activedescendant": this.activeTile.id
      } : t;
    },
    genActivatorListeners() {
      const t = Ct.options.methods.genActivatorListeners.call(this);
      return this.disableKeys || (t.keydown = this.onKeyDown), t;
    },
    genTransition() {
      const t = this.genContent();
      return this.transition ? this.$createElement("transition", {
        props: {
          name: this.transition
        }
      }, [t]) : t;
    },
    genDirectives() {
      const t = [{
        name: "show",
        value: this.isContentActive
      }];
      return !this.openOnHover && this.closeOnClick && t.push({
        name: "click-outside",
        value: {
          handler: () => {
            this.isActive = !1;
          },
          closeConditional: this.closeConditional,
          include: () => [this.$el, ...this.getOpenDependentElements()]
        }
      }), t;
    },
    genContent() {
      const t = {
        attrs: {
          ...this.getScopeIdAttrs(),
          ...this.contentProps,
          role: "role" in this.$attrs ? this.$attrs.role : "menu"
        },
        staticClass: "v-menu__content",
        class: {
          ...this.rootThemeClasses,
          ...this.roundedClasses,
          "v-menu__content--auto": this.auto,
          "v-menu__content--fixed": this.activatorFixed,
          menuable__content__active: this.isActive,
          [this.contentClass.trim()]: !0
        },
        style: this.styles,
        directives: this.genDirectives(),
        ref: "content",
        on: {
          click: (e) => {
            e.target.getAttribute("disabled") || this.closeOnContentClick && (this.isActive = !1);
          },
          keydown: this.onKeyDown
        }
      };
      return this.$listeners.scroll && (t.on = t.on || {}, t.on.scroll = this.$listeners.scroll), !this.disabled && this.openOnHover && (t.on = t.on || {}, t.on.mouseenter = this.mouseEnterHandler), this.openOnHover && (t.on = t.on || {}, t.on.mouseleave = this.mouseLeaveHandler), this.$createElement("div", t, this.getContentSlot());
    },
    getTiles() {
      this.$refs.content && (this.tiles = Array.from(this.$refs.content.querySelectorAll(".v-list-item, .v-divider, .v-subheader")));
    },
    mouseEnterHandler() {
      this.runDelay("open", () => {
        this.hasJustFocused || (this.hasJustFocused = !0);
      });
    },
    mouseLeaveHandler(t) {
      this.runDelay("close", () => {
        var e;
        !((e = this.$refs.content) === null || e === void 0) && e.contains(t.relatedTarget) || requestAnimationFrame(() => {
          this.isActive = !1, this.callDeactivate();
        });
      });
    },
    nextTile() {
      const t = this.tiles[this.listIndex + 1];
      if (!t) {
        if (!this.tiles.length) return;
        this.listIndex = -1, this.nextTile();
        return;
      }
      this.listIndex++, t.tabIndex === -1 && this.nextTile();
    },
    prevTile() {
      const t = this.tiles[this.listIndex - 1];
      if (!t) {
        if (!this.tiles.length) return;
        this.listIndex = this.tiles.length, this.prevTile();
        return;
      }
      this.listIndex--, t.tabIndex === -1 && this.prevTile();
    },
    lastTile() {
      const t = this.tiles[this.tiles.length - 1];
      t && (this.listIndex = this.tiles.length - 1, t.tabIndex === -1 && this.prevTile());
    },
    firstTile() {
      const t = this.tiles[0];
      t && (this.listIndex = 0, t.tabIndex === -1 && this.nextTile());
    },
    onKeyDown(t) {
      if (!this.disableKeys) {
        if (t.keyCode === h.esc) {
          setTimeout(() => {
            this.isActive = !1;
          });
          const e = this.getActivator();
          this.$nextTick(() => e && e.focus());
        } else !this.isActive && [h.up, h.down].includes(t.keyCode) && (this.isActive = !0);
        this.$nextTick(() => this.changeListIndex(t));
      }
    },
    onResize() {
      this.isActive && (this.$refs.content.offsetWidth, this.updateDimensions(), clearTimeout(this.resizeTimeout), this.resizeTimeout = window.setTimeout(this.updateDimensions, 100));
    }
  },
  render(t) {
    const e = {
      staticClass: "v-menu",
      class: {
        "v-menu--attached": this.attach === "" || this.attach === !0 || this.attach === "attach"
      },
      directives: [{
        arg: "500",
        name: "resize",
        value: this.onResize
      }]
    };
    return t("div", e, [!this.activator && this.genActivator(), this.showLazyContent(() => [this.$createElement(ji, {
      props: {
        root: !0,
        light: this.light,
        dark: this.dark
      }
    }, [this.genTransition()])])]);
  }
}), ts = d.extend({
  name: "v-simple-checkbox",
  functional: !0,
  directives: {
    Ripple: D
  },
  props: {
    ...x.options.props,
    ...v.options.props,
    disabled: Boolean,
    ripple: {
      type: Boolean,
      default: !0
    },
    value: Boolean,
    indeterminate: Boolean,
    indeterminateIcon: {
      type: String,
      default: "$checkboxIndeterminate"
    },
    onIcon: {
      type: String,
      default: "$checkboxOn"
    },
    offIcon: {
      type: String,
      default: "$checkboxOff"
    }
  },
  render(t, {
    props: e,
    data: i,
    listeners: s
  }) {
    const n = [];
    let r = e.offIcon;
    if (e.indeterminate ? r = e.indeterminateIcon : e.value && (r = e.onIcon), n.push(t(V, x.options.methods.setTextColor(e.value && e.color, {
      props: {
        disabled: e.disabled,
        dark: e.dark,
        light: e.light
      }
    }), r)), e.ripple && !e.disabled) {
      const o = t("div", x.options.methods.setTextColor(e.color, {
        staticClass: "v-input--selection-controls__ripple",
        directives: [{
          def: D,
          name: "ripple",
          value: {
            center: !0
          }
        }]
      }));
      n.push(o);
    }
    return t("div", S(i, {
      class: {
        "v-simple-checkbox": !0,
        "v-simple-checkbox--disabled": e.disabled
      },
      on: {
        click: (o) => {
          o.stopPropagation(), i.on && i.on.input && !e.disabled && mt(i.on.input).forEach((a) => a(!e.value));
        }
      }
    }), [t("div", {
      staticClass: "v-input--selection-controls__input"
    }, n)]);
  }
}), es = v.extend({
  name: "v-divider",
  props: {
    inset: Boolean,
    vertical: Boolean
  },
  render(t) {
    let e;
    return (!this.$attrs.role || this.$attrs.role === "separator") && (e = this.vertical ? "vertical" : "horizontal"), t("hr", {
      class: {
        "v-divider": !0,
        "v-divider--inset": this.inset,
        "v-divider--vertical": this.vertical,
        ...this.themeClasses
      },
      attrs: {
        role: "separator",
        "aria-orientation": e,
        ...this.$attrs
      },
      on: this.$listeners
    });
  }
}), is = m(
  v
  /* @vue/component */
).extend({
  name: "v-subheader",
  props: {
    inset: Boolean
  },
  render(t) {
    return t("div", {
      staticClass: "v-subheader",
      class: {
        "v-subheader--inset": this.inset,
        ...this.themeClasses
      },
      attrs: this.$attrs,
      on: this.$listeners
    }, b(this));
  }
}), ss = St.extend().extend({
  name: "v-list",
  provide() {
    return {
      isInList: !0,
      list: this
    };
  },
  inject: {
    isInMenu: {
      default: !1
    },
    isInNav: {
      default: !1
    }
  },
  props: {
    dense: Boolean,
    disabled: Boolean,
    expand: Boolean,
    flat: Boolean,
    nav: Boolean,
    rounded: Boolean,
    subheader: Boolean,
    threeLine: Boolean,
    twoLine: Boolean
  },
  data: () => ({
    groups: []
  }),
  computed: {
    classes() {
      return {
        ...St.options.computed.classes.call(this),
        "v-list--dense": this.dense,
        "v-list--disabled": this.disabled,
        "v-list--flat": this.flat,
        "v-list--nav": this.nav,
        "v-list--rounded": this.rounded,
        "v-list--subheader": this.subheader,
        "v-list--two-line": this.twoLine,
        "v-list--three-line": this.threeLine
      };
    }
  },
  methods: {
    register(t) {
      this.groups.push(t);
    },
    unregister(t) {
      const e = this.groups.findIndex((i) => i._uid === t._uid);
      e > -1 && this.groups.splice(e, 1);
    },
    listClick(t) {
      if (!this.expand)
        for (const e of this.groups)
          e.toggle(t);
    }
  },
  render(t) {
    const e = {
      staticClass: "v-list",
      class: this.classes,
      style: this.styles,
      attrs: {
        role: this.isInNav || this.isInMenu ? void 0 : "list",
        ...this.attrs$
      },
      on: this.listeners$
    };
    return t(this.tag, this.setBackgroundColor(this.color, e), b(this));
  }
}), ns = m(x, O, v, xt("listItemGroup"), bt("inputValue")), Y = ns.extend().extend({
  name: "v-list-item",
  directives: {
    Ripple: D
  },
  inject: {
    isInGroup: {
      default: !1
    },
    isInList: {
      default: !1
    },
    isInMenu: {
      default: !1
    },
    isInNav: {
      default: !1
    }
  },
  inheritAttrs: !1,
  props: {
    activeClass: {
      type: String,
      default() {
        return this.listItemGroup ? this.listItemGroup.activeClass : "";
      }
    },
    dense: Boolean,
    inactive: Boolean,
    link: Boolean,
    selectable: {
      type: Boolean
    },
    tag: {
      type: String,
      default: "div"
    },
    threeLine: Boolean,
    twoLine: Boolean,
    value: null
  },
  data: () => ({
    proxyClass: "v-list-item--active"
  }),
  computed: {
    classes() {
      return {
        "v-list-item": !0,
        ...O.options.computed.classes.call(this),
        "v-list-item--dense": this.dense,
        "v-list-item--disabled": this.disabled,
        "v-list-item--link": this.isClickable && !this.inactive,
        "v-list-item--selectable": this.selectable,
        "v-list-item--three-line": this.threeLine,
        "v-list-item--two-line": this.twoLine,
        ...this.themeClasses
      };
    },
    isClickable() {
      return !!(O.options.computed.isClickable.call(this) || this.listItemGroup);
    }
  },
  created() {
    this.$attrs.hasOwnProperty("avatar") && vt("avatar", this);
  },
  methods: {
    click(t) {
      t.detail && this.$el.blur(), this.$emit("click", t), this.to || this.toggle();
    },
    genAttrs() {
      const t = {
        "aria-disabled": this.disabled ? !0 : void 0,
        tabindex: this.isClickable && !this.disabled ? 0 : -1,
        ...this.$attrs
      };
      return this.$attrs.hasOwnProperty("role") || this.isInNav || (this.isInGroup ? (t.role = "option", t["aria-selected"] = String(this.isActive)) : this.isInMenu ? (t.role = this.isClickable ? "menuitem" : void 0, t.id = t.id || `list-item-${this._uid}`) : this.isInList && (t.role = "listitem")), t;
    },
    toggle() {
      this.to && this.inputValue === void 0 && (this.isActive = !this.isActive), this.$emit("change");
    }
  },
  render(t) {
    let {
      tag: e,
      data: i
    } = this.generateRouteLink();
    i.attrs = {
      ...i.attrs,
      ...this.genAttrs()
    }, i[this.to ? "nativeOn" : "on"] = {
      ...i[this.to ? "nativeOn" : "on"],
      keydown: (n) => {
        this.disabled || (n.keyCode === h.enter && this.click(n), this.$emit("keydown", n));
      }
    }, this.inactive && (e = "div"), this.inactive && this.to && (i.on = i.nativeOn, delete i.nativeOn);
    const s = b(this, "default", {
      active: this.isActive,
      toggle: this.toggle
    });
    return t(e, this.isActive ? this.setTextColor(this.color, i) : i, s);
  }
}), Ie = d.extend({
  name: "comparable",
  props: {
    valueComparator: {
      type: Function,
      default: pt
    }
  }
});
function rs(t = "value", e = "change") {
  return d.extend({
    name: "proxyable",
    model: {
      prop: t,
      event: e
    },
    props: {
      [t]: {
        required: !1
      }
    },
    data() {
      return {
        internalLazyValue: this[t]
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.internalLazyValue;
        },
        set(i) {
          i !== this.internalLazyValue && (this.internalLazyValue = i, this.$emit(e, i));
        }
      }
    },
    watch: {
      [t](i) {
        this.internalLazyValue = i;
      }
    }
  });
}
const os = rs(), ke = d.extend({
  name: "v-list-item-action",
  functional: !0,
  render(t, {
    data: e,
    children: i = []
  }) {
    return e.staticClass = e.staticClass ? `v-list-item__action ${e.staticClass}` : "v-list-item__action", i.filter((n) => n.isComment === !1 && n.text !== " ").length > 1 && (e.staticClass += " v-list-item__action--stack"), t("div", e, i);
  }
});
K("v-list-item__action-text", "span");
const we = K("v-list-item__content", "div"), Le = K("v-list-item__title", "div");
K("v-list-item__subtitle", "div");
const Et = m(x, v).extend({
  name: "v-select-list",
  // https://github.com/vuejs/vue/issues/6872
  directives: {
    ripple: D
  },
  props: {
    action: Boolean,
    dense: Boolean,
    hideSelected: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: "disabled"
    },
    itemText: {
      type: [String, Array, Function],
      default: "text"
    },
    itemValue: {
      type: [String, Array, Function],
      default: "value"
    },
    noDataText: String,
    noFilter: Boolean,
    searchInput: null,
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    parsedItems() {
      return this.selectedItems.map((t) => this.getValue(t));
    },
    tileActiveClass() {
      return Object.keys(this.setTextColor(this.color).class || {}).join(" ");
    },
    staticNoDataTile() {
      const t = {
        attrs: {
          role: void 0
        },
        on: {
          mousedown: (e) => e.preventDefault()
          // Prevent onBlur from being called
        }
      };
      return this.$createElement(Y, t, [this.genTileContent(this.noDataText)]);
    }
  },
  methods: {
    genAction(t, e) {
      return this.$createElement(ke, [this.$createElement(ts, {
        props: {
          color: this.color,
          value: e,
          ripple: !1
        },
        on: {
          input: () => this.$emit("select", t)
        }
      })]);
    },
    genDivider(t) {
      return this.$createElement(es, {
        props: t
      });
    },
    genFilteredText(t) {
      if (t = t || "", !this.searchInput || this.noFilter) return t;
      const {
        start: e,
        middle: i,
        end: s
      } = this.getMaskedCharacters(t);
      return [e, this.genHighlight(i), s];
    },
    genHeader(t) {
      return this.$createElement(is, {
        props: t
      }, t.header);
    },
    genHighlight(t) {
      return this.$createElement("span", {
        staticClass: "v-list-item__mask"
      }, t);
    },
    getMaskedCharacters(t) {
      const e = (this.searchInput || "").toString().toLocaleLowerCase(), i = t.toLocaleLowerCase().indexOf(e);
      if (i < 0) return {
        start: t,
        middle: "",
        end: ""
      };
      const s = t.slice(0, i), n = t.slice(i, i + e.length), r = t.slice(i + e.length);
      return {
        start: s,
        middle: n,
        end: r
      };
    },
    genTile({
      item: t,
      index: e,
      disabled: i = null,
      value: s = !1
    }) {
      s || (s = this.hasItem(t)), t === Object(t) && (i = i !== null ? i : this.getDisabled(t));
      const n = {
        attrs: {
          // Default behavior in list does not
          // contain aria-selected by default
          "aria-selected": String(s),
          id: `list-item-${this._uid}-${e}`,
          role: "option"
        },
        on: {
          mousedown: (a) => {
            a.preventDefault();
          },
          click: () => i || this.$emit("select", t)
        },
        props: {
          activeClass: this.tileActiveClass,
          disabled: i,
          ripple: !0,
          inputValue: s
        }
      };
      if (!this.$scopedSlots.item)
        return this.$createElement(Y, n, [this.action && !this.hideSelected && this.items.length > 0 ? this.genAction(t, s) : null, this.genTileContent(t, e)]);
      const r = this, o = this.$scopedSlots.item({
        parent: r,
        item: t,
        attrs: {
          ...n.attrs,
          ...n.props
        },
        on: n.on
      });
      return this.needsTile(o) ? this.$createElement(Y, n, o) : o;
    },
    genTileContent(t, e = 0) {
      return this.$createElement(we, [this.$createElement(Le, [this.genFilteredText(this.getText(t))])]);
    },
    hasItem(t) {
      return this.parsedItems.indexOf(this.getValue(t)) > -1;
    },
    needsTile(t) {
      return t.length !== 1 || t[0].componentOptions == null || t[0].componentOptions.Ctor.options.name !== "v-list-item";
    },
    getDisabled(t) {
      return !!A(t, this.itemDisabled, !1);
    },
    getText(t) {
      return String(A(t, this.itemText, t));
    },
    getValue(t) {
      return A(t, this.itemValue, this.getText(t));
    }
  },
  render() {
    const t = [], e = this.items.length;
    for (let i = 0; i < e; i++) {
      const s = this.items[i];
      this.hideSelected && this.hasItem(s) || (s == null ? t.push(this.genTile({
        item: s,
        index: i
      })) : s.header ? t.push(this.genHeader(s)) : s.divider ? t.push(this.genDivider(s)) : t.push(this.genTile({
        item: s,
        index: i
      })));
    }
    return t.length || t.push(this.$slots["no-data"] || this.staticNoDataTile), this.$slots["prepend-item"] && t.unshift(this.$slots["prepend-item"]), this.$slots["append-item"] && t.push(this.$slots["append-item"]), this.$createElement(ss, {
      staticClass: "v-select-list",
      class: this.themeClasses,
      attrs: {
        role: "listbox",
        tabindex: -1
      },
      on: {
        mousedown: (i) => {
          i.preventDefault();
        }
      },
      props: {
        dense: this.dense
      }
    }, t);
  }
}), Se = m(v).extend({
  name: "v-label",
  functional: !0,
  props: {
    absolute: Boolean,
    color: {
      type: String,
      default: "primary"
    },
    disabled: Boolean,
    focused: Boolean,
    for: String,
    left: {
      type: [Number, String],
      default: 0
    },
    right: {
      type: [Number, String],
      default: "auto"
    },
    value: Boolean
  },
  render(t, e) {
    const {
      children: i,
      listeners: s,
      props: n,
      data: r
    } = e, o = S({
      staticClass: "v-label",
      class: {
        "v-label--active": n.value,
        "v-label--is-disabled": n.disabled,
        ...Nt(e)
      },
      attrs: {
        for: n.for,
        "aria-hidden": !n.for
      },
      on: s,
      style: {
        left: f(n.left),
        right: f(n.right),
        position: n.absolute ? "absolute" : "relative"
      },
      ref: "label"
    }, r);
    return t("label", x.options.methods.setTextColor(n.focused && n.color, o), i);
  }
}), as = m(x, v).extend({
  name: "v-messages",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    genChildren() {
      return this.$createElement("transition-group", {
        staticClass: "v-messages__wrapper",
        attrs: {
          name: "message-transition",
          tag: "div"
        }
      }, this.value.map(this.genMessage));
    },
    genMessage(t, e) {
      return this.$createElement("div", {
        staticClass: "v-messages__message",
        key: e
      }, b(this, "default", {
        message: t,
        key: e
      }) || [t]);
    }
  },
  render(t) {
    return t("div", this.setTextColor(this.color, {
      staticClass: "v-messages",
      class: this.themeClasses
    }), [this.genChildren()]);
  }
}), ls = m(x, ye("form"), v), Te = ls.extend({
  name: "validatable",
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    error: Boolean,
    errorCount: {
      type: [Number, String],
      default: 1
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    messages: {
      type: [String, Array],
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    success: Boolean,
    successMessages: {
      type: [String, Array],
      default: () => []
    },
    validateOnBlur: Boolean,
    value: {
      required: !1
    }
  },
  data() {
    return {
      errorBucket: [],
      hasColor: !1,
      hasFocused: !1,
      hasInput: !1,
      isFocused: !1,
      isResetting: !1,
      lazyValue: this.value,
      valid: !1
    };
  },
  computed: {
    computedColor() {
      if (!this.isDisabled)
        return this.color ? this.color : this.isDark && !this.appIsDark ? "white" : "primary";
    },
    hasError() {
      return this.internalErrorMessages.length > 0 || this.errorBucket.length > 0 || this.error;
    },
    // TODO: Add logic that allows the user to enable based
    // upon a good validation
    hasSuccess() {
      return this.internalSuccessMessages.length > 0 || this.success;
    },
    externalError() {
      return this.internalErrorMessages.length > 0 || this.error;
    },
    hasMessages() {
      return this.validationTarget.length > 0;
    },
    hasState() {
      return this.isDisabled ? !1 : this.hasSuccess || this.shouldValidate && this.hasError;
    },
    internalErrorMessages() {
      return this.genInternalMessages(this.errorMessages);
    },
    internalMessages() {
      return this.genInternalMessages(this.messages);
    },
    internalSuccessMessages() {
      return this.genInternalMessages(this.successMessages);
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(t) {
        this.lazyValue = t, this.$emit("input", t);
      }
    },
    isDisabled() {
      var t;
      return (t = this.disabled) !== null && t !== void 0 ? t : !!this.form && this.form.disabled;
    },
    isInteractive() {
      return !this.isDisabled && !this.isReadonly;
    },
    isReadonly() {
      var t;
      return (t = this.readonly) !== null && t !== void 0 ? t : !!this.form && this.form.readonly;
    },
    shouldValidate() {
      return this.externalError ? !0 : this.isResetting ? !1 : this.validateOnBlur ? this.hasFocused && !this.isFocused : this.hasInput || this.hasFocused;
    },
    validations() {
      return this.validationTarget.slice(0, Number(this.errorCount));
    },
    validationState() {
      if (!this.isDisabled) {
        if (this.hasError && this.shouldValidate) return "error";
        if (this.hasSuccess) return "success";
        if (this.hasColor) return this.computedColor;
      }
    },
    validationTarget() {
      return this.internalErrorMessages.length > 0 ? this.internalErrorMessages : this.successMessages && this.successMessages.length > 0 ? this.internalSuccessMessages : this.messages && this.messages.length > 0 ? this.internalMessages : this.shouldValidate ? this.errorBucket : [];
    }
  },
  watch: {
    rules: {
      handler(t, e) {
        pt(t, e) || this.validate();
      },
      deep: !0
    },
    internalValue() {
      this.hasInput = !0, this.validateOnBlur || this.$nextTick(this.validate);
    },
    isFocused(t) {
      !t && !this.isDisabled && (this.hasFocused = !0, this.validateOnBlur && this.$nextTick(this.validate));
    },
    isResetting() {
      setTimeout(() => {
        this.hasInput = !1, this.hasFocused = !1, this.isResetting = !1, this.validate();
      }, 0);
    },
    hasError(t) {
      this.shouldValidate && this.$emit("update:error", t);
    },
    value(t) {
      this.lazyValue = t;
    }
  },
  beforeMount() {
    this.validate();
  },
  created() {
    this.form && this.form.register(this);
  },
  beforeDestroy() {
    this.form && this.form.unregister(this);
  },
  methods: {
    genInternalMessages(t) {
      return t ? Array.isArray(t) ? t : [t] : [];
    },
    /** @public */
    reset() {
      this.isResetting = !0, this.internalValue = Array.isArray(this.internalValue) ? [] : null;
    },
    /** @public */
    resetValidation() {
      this.isResetting = !0;
    },
    /** @public */
    validate(t = !1, e) {
      const i = [];
      e = e || this.internalValue, t && (this.hasInput = this.hasFocused = !0);
      for (let s = 0; s < this.rules.length; s++) {
        const n = this.rules[s], r = typeof n == "function" ? n(e) : n;
        r === !1 || typeof r == "string" ? i.push(r || "") : typeof r != "boolean" && C(`Rules should return a string or boolean, received '${typeof r}' instead`, this);
      }
      return this.errorBucket = i, this.valid = i.length === 0, this.valid;
    }
  }
}), hs = m(gt, Te), _ = hs.extend().extend({
  name: "v-input",
  inheritAttrs: !1,
  props: {
    appendIcon: String,
    backgroundColor: {
      type: String,
      default: ""
    },
    dense: Boolean,
    height: [Number, String],
    hideDetails: [Boolean, String],
    hideSpinButtons: Boolean,
    hint: String,
    id: String,
    label: String,
    loading: Boolean,
    persistentHint: Boolean,
    prependIcon: String,
    value: null
  },
  data() {
    return {
      lazyValue: this.value,
      hasMouseDown: !1
    };
  },
  computed: {
    classes() {
      return {
        "v-input--has-state": this.hasState,
        "v-input--hide-details": !this.showDetails,
        "v-input--is-label-active": this.isLabelActive,
        "v-input--is-dirty": this.isDirty,
        "v-input--is-disabled": this.isDisabled,
        "v-input--is-focused": this.isFocused,
        // <v-switch loading>.loading === '' so we can't just cast to boolean
        "v-input--is-loading": this.loading !== !1 && this.loading != null,
        "v-input--is-readonly": this.isReadonly,
        "v-input--dense": this.dense,
        "v-input--hide-spin-buttons": this.hideSpinButtons,
        ...this.themeClasses
      };
    },
    computedId() {
      return this.id || `input-${this._uid}`;
    },
    hasDetails() {
      return this.messagesToDisplay.length > 0;
    },
    hasHint() {
      return !this.hasMessages && !!this.hint && (this.persistentHint || this.isFocused);
    },
    hasLabel() {
      return !!(this.$slots.label || this.label);
    },
    // Proxy for `lazyValue`
    // This allows an input
    // to function without
    // a provided model
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(t) {
        this.lazyValue = t, this.$emit(this.$_modelEvent, t);
      }
    },
    isDirty() {
      return !!this.lazyValue;
    },
    isLabelActive() {
      return this.isDirty;
    },
    messagesToDisplay() {
      return this.hasHint ? [this.hint] : this.hasMessages ? this.validations.map((t) => {
        if (typeof t == "string") return t;
        const e = t(this.internalValue);
        return typeof e == "string" ? e : "";
      }).filter((t) => t !== "") : [];
    },
    showDetails() {
      return this.hideDetails === !1 || this.hideDetails === "auto" && this.hasDetails;
    }
  },
  watch: {
    value(t) {
      this.lazyValue = t;
    }
  },
  beforeCreate() {
    this.$_modelEvent = this.$options.model && this.$options.model.event || "input";
  },
  methods: {
    genContent() {
      return [this.genPrependSlot(), this.genControl(), this.genAppendSlot()];
    },
    genControl() {
      return this.$createElement("div", {
        staticClass: "v-input__control",
        attrs: {
          title: this.attrs$.title
        }
      }, [this.genInputSlot(), this.genMessages()]);
    },
    genDefaultSlot() {
      return [this.genLabel(), b(this)];
    },
    genIcon(t, e, i = {}) {
      var s;
      const n = this[`${t}Icon`], r = `click:${rt(t)}`, o = !!(this.listeners$[r] || e), a = {
        prepend: "prependAction",
        prependInner: "prependAction",
        append: "appendAction",
        appendOuter: "appendAction",
        clear: "clear"
      }[t], l = o && a ? this.$vuetify.lang.t(`$vuetify.input.${a}`, (s = this.label) !== null && s !== void 0 ? s : "") : void 0, c = S({
        attrs: {
          "aria-label": l,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          light: this.light,
          tabindex: t === "clear" ? -1 : void 0
        },
        on: o ? {
          click: (u) => {
            u.preventDefault(), u.stopPropagation(), this.$emit(r, u), e && e(u);
          },
          // Container has g event that will
          // trigger menu open if enclosed
          mouseup: (u) => {
            u.preventDefault(), u.stopPropagation();
          }
        } : void 0
      }, i);
      return this.$createElement("div", {
        staticClass: "v-input__icon",
        class: t ? `v-input__icon--${rt(t)}` : void 0
      }, [this.$createElement(V, c, n)]);
    },
    genInputSlot() {
      return this.$createElement("div", this.setBackgroundColor(this.backgroundColor, {
        staticClass: "v-input__slot",
        style: {
          height: f(this.height)
        },
        on: {
          click: this.onClick,
          mousedown: this.onMouseDown,
          mouseup: this.onMouseUp
        },
        ref: "input-slot"
      }), [this.genDefaultSlot()]);
    },
    genLabel() {
      return this.hasLabel ? this.$createElement(Se, {
        props: {
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: this.hasState,
          for: this.computedId,
          light: this.light
        }
      }, b(this, "label") || this.label) : null;
    },
    genMessages() {
      return this.showDetails ? this.$createElement(as, {
        props: {
          color: this.hasHint ? "" : this.validationState,
          dark: this.dark,
          light: this.light,
          value: this.messagesToDisplay
        },
        attrs: {
          role: this.hasMessages ? "alert" : null
        },
        scopedSlots: {
          default: (t) => b(this, "message", t)
        }
      }) : null;
    },
    genSlot(t, e, i) {
      if (!i.length) return null;
      const s = `${t}-${e}`;
      return this.$createElement("div", {
        staticClass: `v-input__${s}`,
        ref: s
      }, i);
    },
    genPrependSlot() {
      const t = [];
      return this.$slots.prepend ? t.push(this.$slots.prepend) : this.prependIcon && t.push(this.genIcon("prepend")), this.genSlot("prepend", "outer", t);
    },
    genAppendSlot() {
      const t = [];
      return this.$slots.append ? t.push(this.$slots.append) : this.appendIcon && t.push(this.genIcon("append")), this.genSlot("append", "outer", t);
    },
    onClick(t) {
      this.$emit("click", t);
    },
    onMouseDown(t) {
      this.hasMouseDown = !0, this.$emit("mousedown", t);
    },
    onMouseUp(t) {
      this.hasMouseDown = !1, this.$emit("mouseup", t);
    }
  },
  render(t) {
    return t("div", this.setTextColor(this.validationState, {
      staticClass: "v-input",
      class: this.classes
    }), this.genContent());
  }
}), cs = m(v).extend({
  name: "v-counter",
  functional: !0,
  props: {
    value: {
      type: [Number, String],
      default: ""
    },
    max: [Number, String]
  },
  render(t, e) {
    const {
      props: i
    } = e, s = parseInt(i.max, 10), n = parseInt(i.value, 10), r = s ? `${n} / ${s}` : String(i.value), o = s && n > s;
    return t("div", {
      staticClass: "v-counter",
      class: {
        "error--text": o,
        ...Nt(e)
      }
    }, r);
  }
});
function us(t) {
  return d.extend({
    name: "intersectable",
    data: () => ({
      isIntersecting: !1
    }),
    mounted() {
      ht.inserted(this.$el, {
        name: "intersect",
        value: this.onObserve
      }, this.$vnode);
    },
    destroyed() {
      ht.unbind(this.$el, {
        name: "intersect",
        value: this.onObserve
      }, this.$vnode);
    },
    methods: {
      onObserve(e, i, s) {
        if (this.isIntersecting = s, !!s)
          for (let n = 0, r = t.onVisible.length; n < r; n++) {
            const o = this[t.onVisible[n]];
            if (typeof o == "function") {
              o();
              continue;
            }
            I(t.onVisible[n] + " method is not available on the instance but referenced in intersectable mixin options");
          }
      }
    }
  });
}
const ds = m(x, yt(["absolute", "fixed", "top", "bottom"]), os, v), fs = ds.extend({
  name: "v-progress-linear",
  directives: {
    intersect: ht
  },
  props: {
    active: {
      type: Boolean,
      default: !0
    },
    backgroundColor: {
      type: String,
      default: null
    },
    backgroundOpacity: {
      type: [Number, String],
      default: null
    },
    bufferValue: {
      type: [Number, String],
      default: 100
    },
    color: {
      type: String,
      default: "primary"
    },
    height: {
      type: [Number, String],
      default: 4
    },
    indeterminate: Boolean,
    query: Boolean,
    reverse: Boolean,
    rounded: Boolean,
    stream: Boolean,
    striped: Boolean,
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      internalLazyValue: this.value || 0,
      isVisible: !0
    };
  },
  computed: {
    __cachedBackground() {
      return this.$createElement("div", this.setBackgroundColor(this.backgroundColor || this.color, {
        staticClass: "v-progress-linear__background",
        style: this.backgroundStyle
      }));
    },
    __cachedBar() {
      return this.$createElement(this.computedTransition, [this.__cachedBarType]);
    },
    __cachedBarType() {
      return this.indeterminate ? this.__cachedIndeterminate : this.__cachedDeterminate;
    },
    __cachedBuffer() {
      return this.$createElement("div", {
        staticClass: "v-progress-linear__buffer",
        style: this.styles
      });
    },
    __cachedDeterminate() {
      return this.$createElement("div", this.setBackgroundColor(this.color, {
        staticClass: "v-progress-linear__determinate",
        style: {
          width: f(this.normalizedValue, "%")
        }
      }));
    },
    __cachedIndeterminate() {
      return this.$createElement("div", {
        staticClass: "v-progress-linear__indeterminate",
        class: {
          "v-progress-linear__indeterminate--active": this.active
        }
      }, [this.genProgressBar("long"), this.genProgressBar("short")]);
    },
    __cachedStream() {
      return this.stream ? this.$createElement("div", this.setTextColor(this.color, {
        staticClass: "v-progress-linear__stream",
        style: {
          width: f(100 - this.normalizedBuffer, "%")
        }
      })) : null;
    },
    backgroundStyle() {
      return {
        opacity: this.backgroundOpacity == null ? this.backgroundColor ? 1 : 0.3 : parseFloat(this.backgroundOpacity),
        [this.isReversed ? "right" : "left"]: f(this.normalizedValue, "%"),
        width: f(Math.max(0, this.normalizedBuffer - this.normalizedValue), "%")
      };
    },
    classes() {
      return {
        "v-progress-linear--absolute": this.absolute,
        "v-progress-linear--fixed": this.fixed,
        "v-progress-linear--query": this.query,
        "v-progress-linear--reactive": this.reactive,
        "v-progress-linear--reverse": this.isReversed,
        "v-progress-linear--rounded": this.rounded,
        "v-progress-linear--striped": this.striped,
        "v-progress-linear--visible": this.isVisible,
        ...this.themeClasses
      };
    },
    computedTransition() {
      return this.indeterminate ? zi : Wi;
    },
    isReversed() {
      return this.$vuetify.rtl !== this.reverse;
    },
    normalizedBuffer() {
      return this.normalize(this.bufferValue);
    },
    normalizedValue() {
      return this.normalize(this.internalLazyValue);
    },
    reactive() {
      return !!this.$listeners.change;
    },
    styles() {
      const t = {};
      return this.active || (t.height = 0), !this.indeterminate && parseFloat(this.normalizedBuffer) !== 100 && (t.width = f(this.normalizedBuffer, "%")), t;
    }
  },
  methods: {
    genContent() {
      const t = b(this, "default", {
        value: this.internalLazyValue
      });
      return t ? this.$createElement("div", {
        staticClass: "v-progress-linear__content"
      }, t) : null;
    },
    genListeners() {
      const t = this.$listeners;
      return this.reactive && (t.click = this.onClick), t;
    },
    genProgressBar(t) {
      return this.$createElement("div", this.setBackgroundColor(this.color, {
        staticClass: "v-progress-linear__indeterminate",
        class: {
          [t]: !0
        }
      }));
    },
    onClick(t) {
      if (!this.reactive) return;
      const {
        width: e
      } = this.$el.getBoundingClientRect();
      this.internalValue = t.offsetX / e * 100;
    },
    onObserve(t, e, i) {
      this.isVisible = i;
    },
    normalize(t) {
      return t < 0 ? 0 : t > 100 ? 100 : parseFloat(t);
    }
  },
  render(t) {
    const e = {
      staticClass: "v-progress-linear",
      attrs: {
        role: "progressbar",
        "aria-valuemin": 0,
        "aria-valuemax": this.normalizedBuffer,
        "aria-valuenow": this.indeterminate ? void 0 : this.normalizedValue
      },
      class: this.classes,
      directives: [{
        name: "intersect",
        value: this.onObserve
      }],
      style: {
        bottom: this.bottom ? 0 : void 0,
        height: this.active ? f(this.height) : 0,
        top: this.top ? 0 : void 0
      },
      on: this.genListeners()
    };
    return t("div", e, [this.__cachedStream, this.__cachedBackground, this.__cachedBuffer, this.__cachedBar, this.genContent()]);
  }
}), ps = d.extend().extend({
  name: "loadable",
  props: {
    loading: {
      type: [Boolean, String],
      default: !1
    },
    loaderHeight: {
      type: [Number, String],
      default: 2
    }
  },
  methods: {
    genProgress() {
      return this.loading === !1 ? null : b(this, "progress") || this.$createElement(fs, {
        props: {
          absolute: !0,
          color: this.loading === !0 || this.loading === "" ? this.color || "primary" : this.loading,
          height: this.loaderHeight,
          indeterminate: !0
        }
      });
    }
  }
}), ms = m(_, us({
  onVisible: ["onResize", "tryAutofocus"]
}), ps), gs = ["color", "file", "time", "date", "datetime-local", "week", "month"], T = ms.extend().extend({
  name: "v-text-field",
  directives: {
    resize: le,
    ripple: D
  },
  inheritAttrs: !1,
  props: {
    appendOuterIcon: String,
    autofocus: Boolean,
    clearable: Boolean,
    clearIcon: {
      type: String,
      default: "$clear"
    },
    counter: [Boolean, Number, String],
    counterValue: Function,
    filled: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    label: String,
    outlined: Boolean,
    placeholder: String,
    prefix: String,
    prependInnerIcon: String,
    persistentPlaceholder: Boolean,
    reverse: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    singleLine: Boolean,
    solo: Boolean,
    soloInverted: Boolean,
    suffix: String,
    type: {
      type: String,
      default: "text"
    }
  },
  data: () => ({
    badInput: !1,
    labelWidth: 0,
    prefixWidth: 0,
    prependWidth: 0,
    initialValue: null,
    isBooted: !1,
    isClearing: !1
  }),
  computed: {
    classes() {
      return {
        ..._.options.computed.classes.call(this),
        "v-text-field": !0,
        "v-text-field--full-width": this.fullWidth,
        "v-text-field--prefix": this.prefix,
        "v-text-field--single-line": this.isSingle,
        "v-text-field--solo": this.isSolo,
        "v-text-field--solo-inverted": this.soloInverted,
        "v-text-field--solo-flat": this.flat,
        "v-text-field--filled": this.filled,
        "v-text-field--is-booted": this.isBooted,
        "v-text-field--enclosed": this.isEnclosed,
        "v-text-field--reverse": this.reverse,
        "v-text-field--outlined": this.outlined,
        "v-text-field--placeholder": this.placeholder,
        "v-text-field--rounded": this.rounded,
        "v-text-field--shaped": this.shaped
      };
    },
    computedColor() {
      const t = Te.options.computed.computedColor.call(this);
      return !this.soloInverted || !this.isFocused ? t : this.color || "primary";
    },
    computedCounterValue() {
      return typeof this.counterValue == "function" ? this.counterValue(this.internalValue) : [...(this.internalValue || "").toString()].length;
    },
    hasCounter() {
      return this.counter !== !1 && this.counter != null;
    },
    hasDetails() {
      return _.options.computed.hasDetails.call(this) || this.hasCounter;
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(t) {
        this.lazyValue = t, this.$emit("input", this.lazyValue);
      }
    },
    isDirty() {
      var t;
      return ((t = this.lazyValue) === null || t === void 0 ? void 0 : t.toString().length) > 0 || this.badInput;
    },
    isEnclosed() {
      return this.filled || this.isSolo || this.outlined;
    },
    isLabelActive() {
      return this.isDirty || gs.includes(this.type);
    },
    isSingle() {
      return this.isSolo || this.singleLine || this.fullWidth || // https://material.io/components/text-fields/#filled-text-field
      this.filled && !this.hasLabel;
    },
    isSolo() {
      return this.solo || this.soloInverted;
    },
    labelPosition() {
      let t = this.prefix && !this.labelValue ? this.prefixWidth : 0;
      return this.labelValue && this.prependWidth && (t -= this.prependWidth), this.$vuetify.rtl === this.reverse ? {
        left: t,
        right: "auto"
      } : {
        left: "auto",
        right: t
      };
    },
    showLabel() {
      return this.hasLabel && !(this.isSingle && this.labelValue);
    },
    labelValue() {
      return this.isFocused || this.isLabelActive || this.persistentPlaceholder;
    }
  },
  watch: {
    // labelValue: 'setLabelWidth', // moved to mounted, see #11533
    outlined: "setLabelWidth",
    label() {
      this.$nextTick(this.setLabelWidth);
    },
    prefix() {
      this.$nextTick(this.setPrefixWidth);
    },
    isFocused: "updateValue",
    value(t) {
      this.lazyValue = t;
    }
  },
  created() {
    this.$attrs.hasOwnProperty("box") && lt("box", "filled", this), this.$attrs.hasOwnProperty("browser-autocomplete") && lt("browser-autocomplete", "autocomplete", this), this.shaped && !(this.filled || this.outlined || this.isSolo) && I("shaped should be used with either filled or outlined", this);
  },
  mounted() {
    this.$watch(() => this.labelValue, this.setLabelWidth), this.autofocus && this.tryAutofocus(), requestAnimationFrame(() => {
      this.isBooted = !0, requestAnimationFrame(() => {
        this.isIntersecting || this.onResize();
      });
    });
  },
  methods: {
    /** @public */
    focus() {
      this.onFocus();
    },
    /** @public */
    blur(t) {
      window.requestAnimationFrame(() => {
        this.$refs.input && this.$refs.input.blur();
      });
    },
    clearableCallback() {
      this.$refs.input && this.$refs.input.focus(), this.$nextTick(() => this.internalValue = null);
    },
    genAppendSlot() {
      const t = [];
      return this.$slots["append-outer"] ? t.push(this.$slots["append-outer"]) : this.appendOuterIcon && t.push(this.genIcon("appendOuter")), this.genSlot("append", "outer", t);
    },
    genPrependInnerSlot() {
      const t = [];
      return this.$slots["prepend-inner"] ? t.push(this.$slots["prepend-inner"]) : this.prependInnerIcon && t.push(this.genIcon("prependInner")), this.genSlot("prepend", "inner", t);
    },
    genIconSlot() {
      const t = [];
      return this.$slots.append ? t.push(this.$slots.append) : this.appendIcon && t.push(this.genIcon("append")), this.genSlot("append", "inner", t);
    },
    genInputSlot() {
      const t = _.options.methods.genInputSlot.call(this), e = this.genPrependInnerSlot();
      return e && (t.children = t.children || [], t.children.unshift(e)), t;
    },
    genClearIcon() {
      return this.clearable ? this.isDirty ? this.genSlot("append", "inner", [this.genIcon("clear", this.clearableCallback)]) : this.genSlot("append", "inner", [this.$createElement("div")]) : null;
    },
    genCounter() {
      var t, e, i;
      if (!this.hasCounter) return null;
      const s = this.counter === !0 ? this.attrs$.maxlength : this.counter, n = {
        dark: this.dark,
        light: this.light,
        max: s,
        value: this.computedCounterValue
      };
      return (i = (e = (t = this.$scopedSlots).counter) === null || e === void 0 ? void 0 : e.call(t, {
        props: n
      })) !== null && i !== void 0 ? i : this.$createElement(cs, {
        props: n
      });
    },
    genControl() {
      return _.options.methods.genControl.call(this);
    },
    genDefaultSlot() {
      return [this.genFieldset(), this.genTextFieldSlot(), this.genClearIcon(), this.genIconSlot(), this.genProgress()];
    },
    genFieldset() {
      return this.outlined ? this.$createElement("fieldset", {
        attrs: {
          "aria-hidden": !0
        }
      }, [this.genLegend()]) : null;
    },
    genLabel() {
      if (!this.showLabel) return null;
      const t = {
        props: {
          absolute: !0,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: !this.isSingle && (this.isFocused || !!this.validationState),
          for: this.computedId,
          left: this.labelPosition.left,
          light: this.light,
          right: this.labelPosition.right,
          value: this.labelValue
        }
      };
      return this.$createElement(Se, t, b(this, "label") || this.label);
    },
    genLegend() {
      const t = !this.singleLine && (this.labelValue || this.isDirty) ? this.labelWidth : 0, e = this.$createElement("span", {
        domProps: {
          innerHTML: "&#8203;"
        },
        staticClass: "notranslate"
      });
      return this.$createElement("legend", {
        style: {
          width: this.isSingle ? void 0 : f(t)
        }
      }, [e]);
    },
    genInput() {
      const t = Object.assign({}, this.listeners$);
      delete t.change;
      const {
        title: e,
        ...i
      } = this.attrs$;
      return this.$createElement("input", {
        style: {},
        domProps: {
          value: this.type === "number" && Object.is(this.lazyValue, -0) ? "-0" : this.lazyValue
        },
        attrs: {
          ...i,
          autofocus: this.autofocus,
          disabled: this.isDisabled,
          id: this.computedId,
          placeholder: this.persistentPlaceholder || this.isFocused || !this.hasLabel ? this.placeholder : void 0,
          readonly: this.isReadonly,
          type: this.type
        },
        on: Object.assign(t, {
          blur: this.onBlur,
          input: this.onInput,
          focus: this.onFocus,
          keydown: this.onKeyDown
        }),
        ref: "input",
        directives: [{
          name: "resize",
          modifiers: {
            quiet: !0
          },
          value: this.onResize
        }]
      });
    },
    genMessages() {
      if (!this.showDetails) return null;
      const t = _.options.methods.genMessages.call(this), e = this.genCounter();
      return this.$createElement("div", {
        staticClass: "v-text-field__details"
      }, [t, e]);
    },
    genTextFieldSlot() {
      return this.$createElement("div", {
        staticClass: "v-text-field__slot"
      }, [this.genLabel(), this.prefix ? this.genAffix("prefix") : null, this.genInput(), this.suffix ? this.genAffix("suffix") : null]);
    },
    genAffix(t) {
      return this.$createElement("div", {
        class: `v-text-field__${t}`,
        ref: t
      }, this[t]);
    },
    onBlur(t) {
      this.isFocused = !1, t && this.$nextTick(() => this.$emit("blur", t));
    },
    onClick() {
      this.isFocused || this.isDisabled || !this.$refs.input || this.$refs.input.focus();
    },
    onFocus(t) {
      if (!this.$refs.input) return;
      const e = Z(this.$el);
      if (e) {
        if (e.activeElement !== this.$refs.input)
          return this.$refs.input.focus();
        this.isFocused || (this.isFocused = !0, t && this.$emit("focus", t));
      }
    },
    onInput(t) {
      const e = t.target;
      this.internalValue = e.value, this.badInput = e.validity && e.validity.badInput;
    },
    onKeyDown(t) {
      t.keyCode === h.enter && this.lazyValue !== this.initialValue && (this.initialValue = this.lazyValue, this.$emit("change", this.initialValue)), this.$emit("keydown", t);
    },
    onMouseDown(t) {
      t.target !== this.$refs.input && (t.preventDefault(), t.stopPropagation()), _.options.methods.onMouseDown.call(this, t);
    },
    onMouseUp(t) {
      this.hasMouseDown && this.focus(), _.options.methods.onMouseUp.call(this, t);
    },
    setLabelWidth() {
      this.outlined && (this.labelWidth = this.$refs.label ? Math.min(this.$refs.label.scrollWidth * 0.75 + 6, this.$el.offsetWidth - 24) : 0);
    },
    setPrefixWidth() {
      this.$refs.prefix && (this.prefixWidth = this.$refs.prefix.offsetWidth);
    },
    setPrependWidth() {
      !this.outlined || !this.$refs["prepend-inner"] || (this.prependWidth = this.$refs["prepend-inner"].offsetWidth);
    },
    tryAutofocus() {
      if (!this.autofocus || typeof document > "u" || !this.$refs.input) return !1;
      const t = Z(this.$el);
      return !t || t.activeElement === this.$refs.input ? !1 : (this.$refs.input.focus(), !0);
    },
    updateValue(t) {
      this.hasColor = t, t ? this.initialValue = this.lazyValue : this.initialValue !== this.lazyValue && this.$emit("change", this.lazyValue);
    },
    onResize() {
      this.setLabelWidth(), this.setPrefixWidth(), this.setPrependWidth();
    }
  }
}), vs = d.extend({
  name: "filterable",
  props: {
    noDataText: {
      type: String,
      default: "$vuetify.noDataText"
    }
  }
}), dt = {
  closeOnClick: !1,
  closeOnContentClick: !1,
  disableKeys: !0,
  openOnClick: !1,
  maxHeight: 304
}, ys = m(T, Ie, _t, vs), $ = ys.extend().extend({
  name: "v-select",
  directives: {
    ClickOutside: ae
  },
  props: {
    appendIcon: {
      type: String,
      default: "$dropdown"
    },
    attach: {
      type: null,
      default: !1
    },
    cacheItems: Boolean,
    chips: Boolean,
    clearable: Boolean,
    deletableChips: Boolean,
    disableLookup: Boolean,
    eager: Boolean,
    hideSelected: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    itemColor: {
      type: String,
      default: "primary"
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: "disabled"
    },
    itemText: {
      type: [String, Array, Function],
      default: "text"
    },
    itemValue: {
      type: [String, Array, Function],
      default: "value"
    },
    menuProps: {
      type: [String, Array, Object],
      default: () => dt
    },
    multiple: Boolean,
    openOnClear: Boolean,
    returnObject: Boolean,
    smallChips: Boolean
  },
  data() {
    return {
      cachedItems: this.cacheItems ? this.items : [],
      menuIsBooted: !1,
      isMenuActive: !1,
      lastItem: 20,
      // As long as a value is defined, show it
      // Otherwise, check if multiple
      // to determine which default to provide
      lazyValue: this.value !== void 0 ? this.value : this.multiple ? [] : void 0,
      selectedIndex: -1,
      selectedItems: [],
      keyboardLookupPrefix: "",
      keyboardLookupLastTime: 0
    };
  },
  computed: {
    /* All items that the select has */
    allItems() {
      return this.filterDuplicates(this.cachedItems.concat(this.items));
    },
    classes() {
      return {
        ...T.options.computed.classes.call(this),
        "v-select": !0,
        "v-select--chips": this.hasChips,
        "v-select--chips--small": this.smallChips,
        "v-select--is-menu-active": this.isMenuActive,
        "v-select--is-multi": this.multiple
      };
    },
    /* Used by other components to overwrite */
    computedItems() {
      return this.allItems;
    },
    computedOwns() {
      return `list-${this._uid}`;
    },
    computedCounterValue() {
      var t;
      const e = this.multiple ? this.selectedItems : ((t = this.getText(this.selectedItems[0])) !== null && t !== void 0 ? t : "").toString();
      return typeof this.counterValue == "function" ? this.counterValue(e) : e.length;
    },
    directives() {
      return this.isFocused ? [{
        name: "click-outside",
        value: {
          handler: this.blur,
          closeConditional: this.closeConditional,
          include: () => this.getOpenDependentElements()
        }
      }] : void 0;
    },
    dynamicHeight() {
      return "auto";
    },
    hasChips() {
      return this.chips || this.smallChips;
    },
    hasSlot() {
      return !!(this.hasChips || this.$scopedSlots.selection);
    },
    isDirty() {
      return this.selectedItems.length > 0;
    },
    listData() {
      const t = this.$vnode && this.$vnode.context.$options._scopeId;
      return {
        attrs: {
          ...t ? {
            [t]: !0
          } : {},
          id: this.computedOwns
        },
        props: {
          action: this.multiple,
          color: this.itemColor,
          dense: this.dense,
          hideSelected: this.hideSelected,
          items: this.virtualizedItems,
          itemDisabled: this.itemDisabled,
          itemText: this.itemText,
          itemValue: this.itemValue,
          noDataText: this.$vuetify.lang.t(this.noDataText),
          selectedItems: this.selectedItems
        },
        on: {
          select: this.selectItem
        },
        scopedSlots: {
          item: this.$scopedSlots.item
        }
      };
    },
    staticList() {
      return (this.$slots["no-data"] || this.$slots["prepend-item"] || this.$slots["append-item"]) && C("assert: staticList should not be called if slots are used"), this.$createElement(Et, this.listData);
    },
    virtualizedItems() {
      return this.$_menuProps.auto ? this.computedItems : this.computedItems.slice(0, this.lastItem);
    },
    menuCanShow: () => !0,
    $_menuProps() {
      let t = typeof this.menuProps == "string" ? this.menuProps.split(",") : this.menuProps;
      return Array.isArray(t) && (t = t.reduce((e, i) => (e[i.trim()] = !0, e), {})), {
        ...dt,
        eager: this.eager,
        value: this.menuCanShow && this.isMenuActive,
        nudgeBottom: t.offsetY ? 1 : 0,
        ...t
      };
    }
  },
  watch: {
    internalValue(t) {
      this.initialValue = t, this.setSelectedItems(), this.multiple && this.$nextTick(() => {
        var e;
        (e = this.$refs.menu) === null || e === void 0 || e.updateDimensions();
      }), this.hideSelected && this.$nextTick(() => {
        this.onScroll();
      });
    },
    isMenuActive(t) {
      window.setTimeout(() => this.onMenuActiveChange(t));
    },
    items: {
      immediate: !0,
      handler(t) {
        this.cacheItems && this.$nextTick(() => {
          this.cachedItems = this.filterDuplicates(this.cachedItems.concat(t));
        }), this.setSelectedItems();
      }
    }
  },
  methods: {
    /** @public */
    blur(t) {
      T.options.methods.blur.call(this, t), this.isMenuActive = !1, this.isFocused = !1, this.selectedIndex = -1, this.setMenuIndex(-1);
    },
    /** @public */
    activateMenu() {
      !this.isInteractive || this.isMenuActive || (this.isMenuActive = !0);
    },
    clearableCallback() {
      this.setValue(this.multiple ? [] : null), this.setMenuIndex(-1), this.$nextTick(() => this.$refs.input && this.$refs.input.focus()), this.openOnClear && (this.isMenuActive = !0);
    },
    closeConditional(t) {
      return this.isMenuActive ? !this._isDestroyed && // Click originates from outside the menu content
      // Multiple selects don't close when an item is clicked
      (!this.getContent() || !this.getContent().contains(t.target)) && // Click originates from outside the element
      this.$el && !this.$el.contains(t.target) && t.target !== this.$el : !0;
    },
    filterDuplicates(t) {
      const e = /* @__PURE__ */ new Map();
      for (let i = 0; i < t.length; ++i) {
        const s = t[i];
        if (s == null)
          continue;
        if (s.header || s.divider) {
          e.set(s, s);
          continue;
        }
        const n = this.getValue(s);
        !e.has(n) && e.set(n, s);
      }
      return Array.from(e.values());
    },
    findExistingIndex(t) {
      const e = this.getValue(t);
      return (this.internalValue || []).findIndex((i) => this.valueComparator(this.getValue(i), e));
    },
    getContent() {
      return this.$refs.menu && this.$refs.menu.$refs.content;
    },
    genChipSelection(t, e) {
      const i = this.isDisabled || this.getDisabled(t), s = !i && this.isInteractive;
      return this.$createElement(_e, {
        staticClass: "v-chip--select",
        attrs: {
          tabindex: -1
        },
        props: {
          close: this.deletableChips && s,
          disabled: i,
          inputValue: e === this.selectedIndex,
          small: this.smallChips
        },
        on: {
          click: (n) => {
            s && (n.stopPropagation(), this.selectedIndex = e);
          },
          "click:close": () => this.onChipInput(t)
        },
        key: JSON.stringify(this.getValue(t))
      }, this.getText(t));
    },
    genCommaSelection(t, e, i) {
      const s = e === this.selectedIndex && this.computedColor, n = this.isDisabled || this.getDisabled(t);
      return this.$createElement("div", this.setTextColor(s, {
        staticClass: "v-select__selection v-select__selection--comma",
        class: {
          "v-select__selection--disabled": n
        },
        key: JSON.stringify(this.getValue(t))
      }), `${this.getText(t)}${i ? "" : ", "}`);
    },
    genDefaultSlot() {
      const t = this.genSelections(), e = this.genInput();
      return Array.isArray(t) ? t.push(e) : (t.children = t.children || [], t.children.push(e)), [this.genFieldset(), this.$createElement("div", {
        staticClass: "v-select__slot",
        directives: this.directives
      }, [this.genLabel(), this.prefix ? this.genAffix("prefix") : null, t, this.suffix ? this.genAffix("suffix") : null, this.genClearIcon(), this.genIconSlot(), this.genHiddenInput()]), this.genMenu(), this.genProgress()];
    },
    genIcon(t, e, i) {
      const s = _.options.methods.genIcon.call(this, t, e, i);
      return t === "append" && (s.children[0].data = S(s.children[0].data, {
        attrs: {
          tabindex: s.children[0].componentOptions.listeners && "-1",
          "aria-hidden": "true",
          "aria-label": void 0
        }
      })), s;
    },
    genInput() {
      const t = T.options.methods.genInput.call(this);
      return delete t.data.attrs.name, t.data = S(t.data, {
        domProps: {
          value: null
        },
        attrs: {
          readonly: !0,
          type: "text",
          "aria-readonly": String(this.isReadonly),
          "aria-activedescendant": k(this.$refs.menu, "activeTile.id"),
          autocomplete: k(t.data, "attrs.autocomplete", "off"),
          placeholder: !this.isDirty && (this.persistentPlaceholder || this.isFocused || !this.hasLabel) ? this.placeholder : void 0
        },
        on: {
          keypress: this.onKeyPress
        }
      }), t;
    },
    genHiddenInput() {
      return this.$createElement("input", {
        domProps: {
          value: this.lazyValue
        },
        attrs: {
          type: "hidden",
          name: this.attrs$.name
        }
      });
    },
    genInputSlot() {
      const t = T.options.methods.genInputSlot.call(this);
      return t.data.attrs = {
        ...t.data.attrs,
        role: "button",
        "aria-haspopup": "listbox",
        "aria-expanded": String(this.isMenuActive),
        "aria-owns": this.computedOwns
      }, t;
    },
    genList() {
      return this.$slots["no-data"] || this.$slots["prepend-item"] || this.$slots["append-item"] ? this.genListWithSlot() : this.staticList;
    },
    genListWithSlot() {
      const t = ["prepend-item", "no-data", "append-item"].filter((e) => this.$slots[e]).map((e) => this.$createElement("template", {
        slot: e
      }, this.$slots[e]));
      return this.$createElement(Et, {
        ...this.listData
      }, t);
    },
    genMenu() {
      const t = this.$_menuProps;
      return t.activator = this.$refs["input-slot"], "attach" in t || (// TODO: make this a computed property or helper or something
      this.attach === "" || // If used as a boolean prop (<v-menu attach>)
      this.attach === !0 || // If bound to a boolean (<v-menu :attach="true">)
      this.attach === "attach" ? t.attach = this.$el : t.attach = this.attach), this.$createElement(Qi, {
        attrs: {
          role: void 0
        },
        props: t,
        on: {
          input: (e) => {
            this.isMenuActive = e, this.isFocused = e;
          },
          scroll: this.onScroll
        },
        ref: "menu"
      }, [this.genList()]);
    },
    genSelections() {
      let t = this.selectedItems.length;
      const e = new Array(t);
      let i;
      for (this.$scopedSlots.selection ? i = this.genSlotSelection : this.hasChips ? i = this.genChipSelection : i = this.genCommaSelection; t--; )
        e[t] = i(this.selectedItems[t], t, t === e.length - 1);
      return this.$createElement("div", {
        staticClass: "v-select__selections"
      }, e);
    },
    genSlotSelection(t, e) {
      return this.$scopedSlots.selection({
        attrs: {
          class: "v-chip--select"
        },
        parent: this,
        item: t,
        index: e,
        select: (i) => {
          i.stopPropagation(), this.selectedIndex = e;
        },
        selected: e === this.selectedIndex,
        disabled: !this.isInteractive
      });
    },
    getMenuIndex() {
      return this.$refs.menu ? this.$refs.menu.listIndex : -1;
    },
    getDisabled(t) {
      return A(t, this.itemDisabled, !1);
    },
    getText(t) {
      return A(t, this.itemText, t);
    },
    getValue(t) {
      return A(t, this.itemValue, this.getText(t));
    },
    onBlur(t) {
      t && this.$emit("blur", t);
    },
    onChipInput(t) {
      this.multiple ? this.selectItem(t) : this.setValue(null), this.selectedItems.length === 0 ? this.isMenuActive = !0 : this.isMenuActive = !1, this.selectedIndex = -1;
    },
    onClick(t) {
      this.isInteractive && (this.isAppendInner(t.target) || (this.isMenuActive = !0), this.isFocused || (this.isFocused = !0, this.$emit("focus")), this.$emit("click", t));
    },
    onEscDown(t) {
      t.preventDefault(), this.isMenuActive && (t.stopPropagation(), this.isMenuActive = !1);
    },
    onKeyPress(t) {
      if (this.multiple || !this.isInteractive || this.disableLookup || t.key.length > 1 || t.ctrlKey || t.metaKey || t.altKey) return;
      const e = 1e3, i = performance.now();
      i - this.keyboardLookupLastTime > e && (this.keyboardLookupPrefix = ""), this.keyboardLookupPrefix += t.key.toLowerCase(), this.keyboardLookupLastTime = i;
      const s = this.allItems.findIndex((r) => {
        var o;
        return ((o = this.getText(r)) !== null && o !== void 0 ? o : "").toString().toLowerCase().startsWith(this.keyboardLookupPrefix);
      }), n = this.allItems[s];
      s !== -1 && (this.lastItem = Math.max(this.lastItem, s + 5), this.setValue(this.returnObject ? n : this.getValue(n)), this.$nextTick(() => this.$refs.menu.getTiles()), setTimeout(() => this.setMenuIndex(s)));
    },
    onKeyDown(t) {
      if (this.isReadonly && t.keyCode !== h.tab) return;
      const e = t.keyCode, i = this.$refs.menu;
      if (this.$emit("keydown", t), !!i) {
        if (this.isMenuActive && [h.up, h.down, h.home, h.end, h.enter].includes(e) && this.$nextTick(() => {
          i.changeListIndex(t), this.$emit("update:list-index", i.listIndex);
        }), [h.enter, h.space].includes(e) && this.activateMenu(), !this.isMenuActive && [h.up, h.down, h.home, h.end].includes(e)) return this.onUpDown(t);
        if (e === h.esc) return this.onEscDown(t);
        if (e === h.tab) return this.onTabDown(t);
        if (e === h.space) return this.onSpaceDown(t);
      }
    },
    onMenuActiveChange(t) {
      if (this.multiple && !t || this.getMenuIndex() > -1) return;
      const e = this.$refs.menu;
      if (!(!e || !this.isDirty)) {
        this.$refs.menu.getTiles();
        for (let i = 0; i < e.tiles.length; i++)
          if (e.tiles[i].getAttribute("aria-selected") === "true") {
            this.setMenuIndex(i);
            break;
          }
      }
    },
    onMouseUp(t) {
      this.hasMouseDown && t.which !== 3 && this.isInteractive && this.isAppendInner(t.target) && this.$nextTick(() => this.isMenuActive = !this.isMenuActive), T.options.methods.onMouseUp.call(this, t);
    },
    onScroll() {
      if (!this.isMenuActive)
        requestAnimationFrame(() => {
          const t = this.getContent();
          t && (t.scrollTop = 0);
        });
      else {
        if (this.lastItem > this.computedItems.length) return;
        this.getContent().scrollHeight - (this.getContent().scrollTop + this.getContent().clientHeight) < 200 && (this.lastItem += 20);
      }
    },
    onSpaceDown(t) {
      t.preventDefault();
    },
    onTabDown(t) {
      const e = this.$refs.menu;
      if (!e) return;
      const i = e.activeTile;
      !this.multiple && i && this.isMenuActive ? (t.preventDefault(), t.stopPropagation(), i.click()) : this.blur(t);
    },
    onUpDown(t) {
      const e = this.$refs.menu;
      if (!e) return;
      if (t.preventDefault(), this.multiple) return this.activateMenu();
      const i = t.keyCode;
      e.isBooted = !0, window.requestAnimationFrame(() => {
        if (e.getTiles(), !e.hasClickableTiles) return this.activateMenu();
        switch (i) {
          case h.up:
            e.prevTile();
            break;
          case h.down:
            e.nextTile();
            break;
          case h.home:
            e.firstTile();
            break;
          case h.end:
            e.lastTile();
            break;
        }
        this.selectItem(this.allItems[this.getMenuIndex()]);
      });
    },
    selectItem(t) {
      if (!this.multiple)
        this.setValue(this.returnObject ? t : this.getValue(t)), this.isMenuActive = !1;
      else {
        const e = (this.internalValue || []).slice(), i = this.findExistingIndex(t);
        if (i !== -1 ? e.splice(i, 1) : e.push(t), this.setValue(e.map((s) => this.returnObject ? s : this.getValue(s))), this.hideSelected)
          this.setMenuIndex(-1);
        else {
          const s = this.computedItems.indexOf(t);
          ~s && (this.$nextTick(() => this.$refs.menu.getTiles()), setTimeout(() => this.setMenuIndex(s)));
        }
      }
    },
    setMenuIndex(t) {
      this.$refs.menu && (this.$refs.menu.listIndex = t);
    },
    setSelectedItems() {
      const t = [], e = !this.multiple || !Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue;
      for (const i of e) {
        const s = this.allItems.findIndex((n) => this.valueComparator(this.getValue(n), this.getValue(i)));
        s > -1 && t.push(this.allItems[s]);
      }
      this.selectedItems = t;
    },
    setValue(t) {
      this.valueComparator(t, this.internalValue) || (this.internalValue = t, this.$emit("change", t));
    },
    isAppendInner(t) {
      const e = this.$refs["append-inner"];
      return e && (e === t || e.contains(t));
    }
  }
}), Ft = {
  ...dt,
  offsetY: !0,
  offsetOverflow: !0,
  transition: !1
}, bs = $.extend({
  name: "v-autocomplete",
  props: {
    autoSelectFirst: {
      type: Boolean,
      default: !1
    },
    filter: {
      type: Function,
      default: (t, e, i) => i.toLocaleLowerCase().indexOf(e.toLocaleLowerCase()) > -1
    },
    hideNoData: Boolean,
    menuProps: {
      type: $.options.props.menuProps.type,
      default: () => Ft
    },
    noFilter: Boolean,
    searchInput: {
      type: String
    }
  },
  data() {
    return {
      lazySearch: this.searchInput
    };
  },
  computed: {
    classes() {
      return {
        ...$.options.computed.classes.call(this),
        "v-autocomplete": !0,
        "v-autocomplete--is-selecting-index": this.selectedIndex > -1
      };
    },
    computedItems() {
      return this.filteredItems;
    },
    selectedValues() {
      return this.selectedItems.map((t) => this.getValue(t));
    },
    hasDisplayedItems() {
      return this.hideSelected ? this.filteredItems.some((t) => !this.hasItem(t)) : this.filteredItems.length > 0;
    },
    currentRange() {
      return this.selectedItem == null ? 0 : String(this.getText(this.selectedItem)).length;
    },
    filteredItems() {
      return !this.isSearching || this.noFilter || this.internalSearch == null ? this.allItems : this.allItems.filter((t) => {
        const e = A(t, this.itemText), i = e != null ? String(e) : "";
        return this.filter(t, String(this.internalSearch), i);
      });
    },
    internalSearch: {
      get() {
        return this.lazySearch;
      },
      set(t) {
        this.lazySearch !== t && (this.lazySearch = t, this.$emit("update:search-input", t));
      }
    },
    isAnyValueAllowed() {
      return !1;
    },
    isDirty() {
      return this.searchIsDirty || this.selectedItems.length > 0;
    },
    isSearching() {
      return this.multiple && this.searchIsDirty || this.searchIsDirty && this.internalSearch !== this.getText(this.selectedItem);
    },
    menuCanShow() {
      return this.isFocused ? this.hasDisplayedItems || !this.hideNoData : !1;
    },
    $_menuProps() {
      const t = $.options.computed.$_menuProps.call(this);
      return t.contentClass = `v-autocomplete__content ${t.contentClass || ""}`.trim(), {
        ...Ft,
        ...t
      };
    },
    searchIsDirty() {
      return this.internalSearch != null && this.internalSearch !== "";
    },
    selectedItem() {
      return this.multiple ? null : this.selectedItems.find((t) => this.valueComparator(this.getValue(t), this.getValue(this.internalValue)));
    },
    listData() {
      const t = $.options.computed.listData.call(this);
      return t.props = {
        ...t.props,
        items: this.virtualizedItems,
        noFilter: this.noFilter || !this.isSearching || !this.filteredItems.length,
        searchInput: this.internalSearch
      }, t;
    }
  },
  watch: {
    filteredItems: "onFilteredItemsChanged",
    internalValue: "setSearch",
    isFocused(t) {
      t ? (document.addEventListener("copy", this.onCopy), this.$refs.input && this.$refs.input.select()) : (document.removeEventListener("copy", this.onCopy), this.blur(), this.updateSelf());
    },
    isMenuActive(t) {
      t || !this.hasSlot || (this.lazySearch = null);
    },
    items(t, e) {
      !(e && e.length) && this.hideNoData && this.isFocused && !this.isMenuActive && t.length && this.activateMenu();
    },
    searchInput(t) {
      this.lazySearch = t;
    },
    internalSearch: "onInternalSearchChanged",
    itemText: "updateSelf"
  },
  created() {
    this.setSearch();
  },
  destroyed() {
    document.removeEventListener("copy", this.onCopy);
  },
  methods: {
    onFilteredItemsChanged(t, e) {
      if (t !== e) {
        if (!this.autoSelectFirst) {
          const i = e[this.$refs.menu.listIndex];
          i ? this.setMenuIndex(t.findIndex((s) => s === i)) : this.setMenuIndex(-1), this.$emit("update:list-index", this.$refs.menu.listIndex);
        }
        this.$nextTick(() => {
          !this.internalSearch || t.length !== 1 && !this.autoSelectFirst || (this.$refs.menu.getTiles(), this.autoSelectFirst && t.length && (this.setMenuIndex(0), this.$emit("update:list-index", this.$refs.menu.listIndex)));
        });
      }
    },
    onInternalSearchChanged() {
      this.updateMenuDimensions();
    },
    updateMenuDimensions() {
      this.isMenuActive && this.$refs.menu && this.$refs.menu.updateDimensions();
    },
    changeSelectedIndex(t) {
      this.searchIsDirty || (this.multiple && t === h.left ? this.selectedIndex === -1 ? this.selectedIndex = this.selectedItems.length - 1 : this.selectedIndex-- : this.multiple && t === h.right ? this.selectedIndex >= this.selectedItems.length - 1 ? this.selectedIndex = -1 : this.selectedIndex++ : (t === h.backspace || t === h.delete) && this.deleteCurrentItem());
    },
    deleteCurrentItem() {
      const t = this.selectedIndex, e = this.selectedItems[t];
      if (!this.isInteractive || this.getDisabled(e)) return;
      const i = this.selectedItems.length - 1;
      if (this.selectedIndex === -1 && i !== 0) {
        this.selectedIndex = i;
        return;
      }
      const s = this.selectedItems.length, n = t !== s - 1 ? t : t - 1;
      this.selectedItems[n] ? this.selectItem(e) : this.setValue(this.multiple ? [] : null), this.selectedIndex = n;
    },
    clearableCallback() {
      this.internalSearch = null, $.options.methods.clearableCallback.call(this);
    },
    genInput() {
      const t = T.options.methods.genInput.call(this);
      return t.data = S(t.data, {
        attrs: {
          "aria-activedescendant": k(this.$refs.menu, "activeTile.id"),
          autocomplete: k(t.data, "attrs.autocomplete", "off")
        },
        domProps: {
          value: this.internalSearch
        }
      }), t;
    },
    genInputSlot() {
      const t = $.options.methods.genInputSlot.call(this);
      return t.data.attrs.role = "combobox", t;
    },
    genSelections() {
      return this.hasSlot || this.multiple ? $.options.methods.genSelections.call(this) : [];
    },
    onClick(t) {
      this.isInteractive && (this.selectedIndex > -1 ? this.selectedIndex = -1 : this.onFocus(), this.isAppendInner(t.target) || this.activateMenu());
    },
    onInput(t) {
      if (this.selectedIndex > -1 || !t.target) return;
      const e = t.target, i = e.value;
      e.value && this.activateMenu(), !this.multiple && i === "" && this.deleteCurrentItem(), this.internalSearch = i, this.badInput = e.validity && e.validity.badInput;
    },
    onKeyDown(t) {
      const e = t.keyCode;
      (t.ctrlKey || ![h.home, h.end].includes(e)) && $.options.methods.onKeyDown.call(this, t), this.changeSelectedIndex(e);
    },
    onSpaceDown(t) {
    },
    onTabDown(t) {
      $.options.methods.onTabDown.call(this, t), this.updateSelf();
    },
    onUpDown(t) {
      t.preventDefault(), this.activateMenu();
    },
    selectItem(t) {
      $.options.methods.selectItem.call(this, t), this.setSearch();
    },
    setSelectedItems() {
      $.options.methods.setSelectedItems.call(this), this.isFocused || this.setSearch();
    },
    setSearch() {
      this.$nextTick(() => {
        (!this.multiple || !this.internalSearch || !this.isMenuActive) && (this.internalSearch = !this.selectedItems.length || this.multiple || this.hasSlot ? null : this.getText(this.selectedItem));
      });
    },
    updateSelf() {
      !this.searchIsDirty && !this.internalValue || !this.multiple && !this.valueComparator(this.internalSearch, this.getValue(this.internalValue)) && this.setSearch();
    },
    hasItem(t) {
      return this.selectedValues.indexOf(this.getValue(t)) > -1;
    },
    onCopy(t) {
      var e, i;
      if (this.selectedIndex === -1) return;
      const s = this.selectedItems[this.selectedIndex], n = this.getText(s);
      (e = t.clipboardData) === null || e === void 0 || e.setData("text/plain", n), (i = t.clipboardData) === null || i === void 0 || i.setData("text/vnd.vuetify.autocomplete.item+plain", n), t.preventDefault();
    }
  }
}), xs = d.extend({
  name: "rippleable",
  directives: {
    ripple: D
  },
  props: {
    ripple: {
      type: [Boolean, Object],
      default: !0
    }
  },
  methods: {
    genRipple(t = {}) {
      return this.ripple ? (t.staticClass = "v-input--selection-controls__ripple", t.directives = t.directives || [], t.directives.push({
        name: "ripple",
        value: {
          center: !0
        }
      }), this.$createElement("div", t)) : null;
    }
  }
});
function Pt(t) {
  t.preventDefault();
}
const $s = m(_, xs, Ie).extend({
  name: "selectable",
  model: {
    prop: "inputValue",
    event: "change"
  },
  props: {
    id: String,
    inputValue: null,
    falseValue: null,
    trueValue: null,
    multiple: {
      type: Boolean,
      default: null
    },
    label: String
  },
  data() {
    return {
      hasColor: this.inputValue,
      lazyValue: this.inputValue
    };
  },
  computed: {
    computedColor() {
      if (this.isActive)
        return this.color ? this.color : this.isDark && !this.appIsDark ? "white" : "primary";
    },
    isMultiple() {
      return this.multiple === !0 || this.multiple === null && Array.isArray(this.internalValue);
    },
    isActive() {
      const t = this.value, e = this.internalValue;
      return this.isMultiple ? Array.isArray(e) ? e.some((i) => this.valueComparator(i, t)) : !1 : this.trueValue === void 0 || this.falseValue === void 0 ? t ? this.valueComparator(t, e) : !!e : this.valueComparator(e, this.trueValue);
    },
    isDirty() {
      return this.isActive;
    },
    rippleState() {
      return !this.isDisabled && !this.validationState ? void 0 : this.validationState;
    }
  },
  watch: {
    inputValue(t) {
      this.lazyValue = t, this.hasColor = t;
    }
  },
  methods: {
    genLabel() {
      const t = _.options.methods.genLabel.call(this);
      return t && (t.data.on = {
        // Label shouldn't cause the input to focus
        click: Pt
      }, t);
    },
    genInput(t, e) {
      return this.$createElement("input", {
        attrs: Object.assign({
          "aria-checked": this.isActive.toString(),
          disabled: this.isDisabled,
          id: this.computedId,
          role: t,
          type: t
        }, e),
        domProps: {
          value: this.value,
          checked: this.isActive
        },
        on: {
          blur: this.onBlur,
          change: this.onChange,
          focus: this.onFocus,
          keydown: this.onKeydown,
          click: Pt
        },
        ref: "input"
      });
    },
    onClick(t) {
      this.onChange(), this.$emit("click", t);
    },
    onChange() {
      if (!this.isInteractive) return;
      const t = this.value;
      let e = this.internalValue;
      if (this.isMultiple) {
        Array.isArray(e) || (e = []);
        const i = e.length;
        e = e.filter((s) => !this.valueComparator(s, t)), e.length === i && e.push(t);
      } else this.trueValue !== void 0 && this.falseValue !== void 0 ? e = this.valueComparator(e, this.trueValue) ? this.falseValue : this.trueValue : t ? e = this.valueComparator(e, t) ? null : t : e = !e;
      this.validate(!0, e), this.internalValue = e, this.hasColor = e;
    },
    onFocus(t) {
      this.isFocused = !0, this.$emit("focus", t);
    },
    onBlur(t) {
      this.isFocused = !1, this.$emit("blur", t);
    },
    /** @abstract */
    onKeydown(t) {
    }
  }
}), _s = $s.extend({
  name: "v-checkbox",
  props: {
    indeterminate: Boolean,
    indeterminateIcon: {
      type: String,
      default: "$checkboxIndeterminate"
    },
    offIcon: {
      type: String,
      default: "$checkboxOff"
    },
    onIcon: {
      type: String,
      default: "$checkboxOn"
    }
  },
  data() {
    return {
      inputIndeterminate: this.indeterminate
    };
  },
  computed: {
    classes() {
      return {
        ..._.options.computed.classes.call(this),
        "v-input--selection-controls": !0,
        "v-input--checkbox": !0,
        "v-input--indeterminate": this.inputIndeterminate
      };
    },
    computedIcon() {
      return this.inputIndeterminate ? this.indeterminateIcon : this.isActive ? this.onIcon : this.offIcon;
    },
    // Do not return undefined if disabled,
    // according to spec, should still show
    // a color when disabled and active
    validationState() {
      if (!(this.isDisabled && !this.inputIndeterminate)) {
        if (this.hasError && this.shouldValidate) return "error";
        if (this.hasSuccess) return "success";
        if (this.hasColor !== null) return this.computedColor;
      }
    }
  },
  watch: {
    indeterminate(t) {
      this.$nextTick(() => this.inputIndeterminate = t);
    },
    inputIndeterminate(t) {
      this.$emit("update:indeterminate", t);
    },
    isActive() {
      this.indeterminate && (this.inputIndeterminate = !1);
    }
  },
  methods: {
    genCheckbox() {
      const {
        title: t,
        ...e
      } = this.attrs$;
      return this.$createElement("div", {
        staticClass: "v-input--selection-controls__input"
      }, [this.$createElement(V, this.setTextColor(this.validationState, {
        props: {
          dense: this.dense,
          dark: this.dark,
          light: this.light
        }
      }), this.computedIcon), this.genInput("checkbox", {
        ...e,
        "aria-checked": this.inputIndeterminate ? "mixed" : this.isActive.toString()
      }), this.genRipple(this.setTextColor(this.rippleState))]);
    },
    genDefaultSlot() {
      return [this.genCheckbox(), this.genLabel()];
    }
  }
}), Cs = m(x, $t, _t, Ct).extend({
  name: "v-tooltip",
  props: {
    closeDelay: {
      type: [Number, String],
      default: 0
    },
    disabled: Boolean,
    openDelay: {
      type: [Number, String],
      default: 0
    },
    openOnHover: {
      type: Boolean,
      default: !0
    },
    openOnFocus: {
      type: Boolean,
      default: !0
    },
    tag: {
      type: String,
      default: "span"
    },
    transition: String
  },
  data: () => ({
    calculatedMinWidth: 0,
    closeDependents: !1
  }),
  computed: {
    calculatedLeft() {
      const {
        activator: t,
        content: e
      } = this.dimensions, i = !this.bottom && !this.left && !this.top && !this.right, s = this.attach !== !1 ? t.offsetLeft : t.left;
      let n = 0;
      return this.top || this.bottom || i ? n = s + t.width / 2 - e.width / 2 : (this.left || this.right) && (n = s + (this.right ? t.width : -e.width) + (this.right ? 10 : -10)), this.nudgeLeft && (n -= parseInt(this.nudgeLeft)), this.nudgeRight && (n += parseInt(this.nudgeRight)), `${this.calcXOverflow(n, this.dimensions.content.width)}px`;
    },
    calculatedTop() {
      const {
        activator: t,
        content: e
      } = this.dimensions, i = this.attach !== !1 ? t.offsetTop : t.top;
      let s = 0;
      return this.top || this.bottom ? s = i + (this.bottom ? t.height : -e.height) + (this.bottom ? 10 : -10) : (this.left || this.right) && (s = i + t.height / 2 - e.height / 2), this.nudgeTop && (s -= parseInt(this.nudgeTop)), this.nudgeBottom && (s += parseInt(this.nudgeBottom)), this.attach === !1 && (s += this.pageYOffset), `${this.calcYOverflow(s)}px`;
    },
    classes() {
      return {
        "v-tooltip--top": this.top,
        "v-tooltip--right": this.right,
        "v-tooltip--bottom": this.bottom,
        "v-tooltip--left": this.left,
        "v-tooltip--attached": this.attach === "" || this.attach === !0 || this.attach === "attach"
      };
    },
    computedTransition() {
      return this.transition ? this.transition : this.isActive ? "scale-transition" : "fade-transition";
    },
    offsetY() {
      return this.top || this.bottom;
    },
    offsetX() {
      return this.left || this.right;
    },
    styles() {
      return {
        left: this.calculatedLeft,
        maxWidth: f(this.maxWidth),
        minWidth: f(this.minWidth),
        top: this.calculatedTop,
        zIndex: this.zIndex || this.activeZIndex
      };
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.value && this.callActivate();
    });
  },
  mounted() {
    Wt(this, "activator") === "v-slot" && C(`v-tooltip's activator slot must be bound, try '<template #activator="data"><v-btn v-on="data.on>'`, this);
  },
  methods: {
    activate() {
      this.updateDimensions(), requestAnimationFrame(this.startTransition);
    },
    deactivate() {
      this.runDelay("close");
    },
    genActivatorListeners() {
      const t = tt.options.methods.genActivatorListeners.call(this);
      return this.openOnFocus && (t.focus = (e) => {
        this.getActivator(e), this.runDelay("open");
      }, t.blur = (e) => {
        this.getActivator(e), this.runDelay("close");
      }), t.keydown = (e) => {
        e.keyCode === h.esc && (this.getActivator(e), this.runDelay("close"));
      }, t;
    },
    genActivatorAttributes() {
      return {
        "aria-haspopup": !0,
        "aria-expanded": String(this.isActive)
      };
    },
    genTransition() {
      const t = this.genContent();
      return this.computedTransition ? this.$createElement("transition", {
        props: {
          name: this.computedTransition
        }
      }, [t]) : t;
    },
    genContent() {
      return this.$createElement("div", this.setBackgroundColor(this.color, {
        staticClass: "v-tooltip__content",
        class: {
          [this.contentClass]: !0,
          menuable__content__active: this.isActive,
          "v-tooltip__content--fixed": this.activatorFixed
        },
        style: this.styles,
        attrs: this.getScopeIdAttrs(),
        directives: [{
          name: "show",
          value: this.isContentActive
        }],
        ref: "content"
      }), this.getContentSlot());
    }
  },
  render(t) {
    return t(this.tag, {
      staticClass: "v-tooltip",
      class: this.classes
    }, [this.showLazyContent(() => [this.genTransition()]), this.genActivator()]);
  }
}), Is = {
  name: "MAutocomplete"
}, ks = /* @__PURE__ */ N({
  ...Is,
  props: {
    dense: { type: Boolean, default: !1 },
    filter: { type: Function, default: (t, e, i) => !0 },
    hideDetails: { type: Boolean, default: void 0 },
    itemDisabled: { default: "disabled" },
    items: { default: () => [] },
    itemText: { default: "text" },
    itemValue: { default: "value" },
    label: { default: void 0 },
    multiple: { type: Boolean, default: !1 },
    outlined: { type: Boolean, default: !1 },
    value: null
  },
  emits: ["input"],
  setup(t, { emit: e }) {
    return { __sfc: !0, props: t, emit: e };
  }
});
function w(t, e, i, s, n, r, o, a) {
  var l = typeof t == "function" ? t.options : t;
  return e && (l.render = e, l.staticRenderFns = i, l._compiled = !0), {
    exports: t,
    options: l
  };
}
var ws = function() {
  var e = this, i = e._self._c, s = e._self._setupProxy;
  return i(bs, { attrs: { dense: s.props.dense, filter: s.props.filter, "hide-details": s.props.hideDetails, items: s.props.items, "item-text": s.props.itemText, "item-value": s.props.itemValue, label: s.props.label, multiple: s.props.multiple, outlined: s.props.outlined, value: s.props.value }, on: { input: function(n) {
    return s.emit("input", n);
  } }, scopedSlots: e._u([{ key: "selection", fn: function(n) {
    return [e._t("selection", null, null, n)];
  } }, { key: "item", fn: function(n) {
    return [e._t("item", null, null, n)];
  } }], null, !0) });
}, Ls = [], Ss = /* @__PURE__ */ w(
  ks,
  ws,
  Ls
);
const mn = Ss.exports, Ts = {
  name: "MCheckbox"
}, As = /* @__PURE__ */ N({
  ...Ts,
  props: {
    disabled: { type: Boolean, default: !1 },
    inputValue: { default: void 0 }
  },
  setup(t) {
    return { __sfc: !0, props: t };
  }
});
var Ms = function() {
  var e = this, i = e._self._c, s = e._self._setupProxy;
  return i(_s, { attrs: { "input-value": s.props.inputValue, disabled: s.props.disabled } });
}, Ds = [], Bs = /* @__PURE__ */ w(
  As,
  Ms,
  Ds
);
const gn = Bs.exports, Os = {
  name: "MChip"
}, Vs = /* @__PURE__ */ N({
  ...Os,
  props: {
    color: { default: void 0 },
    textColor: { default: void 0 },
    xSmall: { type: Boolean, default: !1 }
  },
  setup(t) {
    return { __sfc: !0, props: t };
  }
});
var Es = function() {
  var e = this, i = e._self._c, s = e._self._setupProxy;
  return i(_e, { attrs: { color: s.props.color, "text-color": s.props.textColor, "x-small": s.props.xSmall } }, [e._t("default")], 2);
}, Fs = [], Ps = /* @__PURE__ */ w(
  Vs,
  Es,
  Fs
);
const vn = Ps.exports, Hs = {
  name: "MIcon"
}, Ns = /* @__PURE__ */ N({
  ...Hs,
  props: {
    color: { default: void 0 },
    left: { type: Boolean, default: !1 },
    small: { type: Boolean, default: !1 }
  },
  setup(t) {
    return { __sfc: !0, props: t };
  }
});
var zs = function() {
  var e = this, i = e._self._c, s = e._self._setupProxy;
  return i(V, e._g({ attrs: { color: s.props.color, left: s.props.left, small: s.props.small } }, e.$listeners), [e._t("default")], 2);
}, Ws = [], Rs = /* @__PURE__ */ w(
  Ns,
  zs,
  Ws
);
const yn = Rs.exports, js = {
  name: "MListItem"
};
var Ys = function() {
  var e = this, i = e._self._c;
  return i(Y, e._b({ scopedSlots: e._u([{ key: "default", fn: function(s) {
    return [e._t("default", null, null, s)];
  } }], null, !0) }, "v-list-item", e.$attrs, !1));
}, qs = [], Zs = /* @__PURE__ */ w(
  js,
  Ys,
  qs
);
const bn = Zs.exports, Xs = {
  name: "MListItemAction"
};
var Ks = function() {
  var e = this, i = e._self._c;
  return i(ke, [e._t("default")], 2);
}, Us = [], Gs = /* @__PURE__ */ w(
  Xs,
  Ks,
  Us
);
const xn = Gs.exports, Js = {
  name: "MListItemContent"
};
var Qs = function() {
  var e = this, i = e._self._c;
  return i(we, [e._t("default")], 2);
}, tn = [], en = /* @__PURE__ */ w(
  Js,
  Qs,
  tn
);
const $n = en.exports, sn = {
  name: "MListItemTitle"
};
var nn = function() {
  var e = this, i = e._self._c;
  return i(Le, [e._t("default")], 2);
}, rn = [], on = /* @__PURE__ */ w(
  sn,
  nn,
  rn
);
const _n = on.exports, an = {
  name: "MTooltip"
}, ln = /* @__PURE__ */ N({
  ...an,
  props: {
    top: { type: Boolean, default: !1 }
  },
  setup(t) {
    return { __sfc: !0, props: t };
  }
});
var hn = function() {
  var e = this, i = e._self._c, s = e._self._setupProxy;
  return i(Cs, { attrs: { top: s.props.top }, scopedSlots: e._u([{ key: "activator", fn: function({ on: n, attrs: r }) {
    return [e._t("activator", null, { on: n, attrs: r })];
  } }], null, !0) }, [e._t("default")], 2);
}, cn = [], un = /* @__PURE__ */ w(
  ln,
  hn,
  cn
);
const Cn = un.exports;
d.use(Ht);
const In = new Ht({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: {
      customProperties: !0
    },
    themes: {
      light: {
        accent: "82B1FF",
        blue_dark: "083D77",
        blue_light: "EFFEFF",
        chip_blue: "cfdeff",
        chip_red: "ffb0af",
        chip_tale: "c4f6e9",
        chip_yellow: "ffeab6",
        critical: "a44345",
        doc_type_chip_blue: "D2DEFC",
        doc_type_chip_electric_blue: "D6EFFB",
        doc_type_chip_green: "D8F5C8",
        doc_type_chip_orange: "F9E3D7",
        doc_type_chip_red: "F9DDE5",
        doc_type_chip_rose: "F9DBF4",
        doc_type_chip_turquoise: "CCF4E9",
        doc_type_chip_yellow: "FBEABC",
        error: "FA9594",
        green: "338E73",
        green_soft: "89EE79",
        green_success: "00A78A",
        grey_color_background: "f5f5f5",
        grey_color_icon: "818386",
        grey_dark: "A0A4A8",
        grey_light: "efefef",
        grey_middle: "D5D5D5",
        h1: "2B2B2B",
        high: "c69634",
        hover: "B297FF",
        info: "2196F3",
        low: "89909d",
        normal: "4b65f6",
        ongoing: "2868F0",
        ongoing_light: "DBEEFA",
        primary: "6733FD",
        primary_extra_light: "E1D6FF",
        primary_light: "F0E8FF",
        red: "FE4242",
        red_error: "E90101",
        red_light: "FFE4E4",
        secondary: "BBA0FE",
        selected: "6733FD",
        soft: "D7C6FE",
        success: "1DBB8C",
        success_light: "CCEDE8",
        text: "2B2B2B",
        warning: "FFA800",
        yellow: "FFA800",
        yellow_light: "FFF2D8"
      }
    }
  }
});
export {
  mn as MAutocomplete,
  gn as MCheckbox,
  vn as MChip,
  yn as MIcon,
  bn as MListItem,
  xn as MListItemAction,
  $n as MListItemContent,
  _n as MListItemTitle,
  Cn as MTooltip,
  In as vuetifyPlugin
};
