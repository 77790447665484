import type { TranslationMessages } from "@mercateam/apps-module-shared";

import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";
import pl from "./pl.json";
import pt from "./pt.json";
import sk from "./sk.json";
import tr from "./tr.json";

const translations: TranslationMessages = {
  en,
  es,
  fr,
  it,
  pl,
  pt,
  sk,
  tr,
} as const;

export default translations;
