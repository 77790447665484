import { filter, forEach, map, orderBy, sortBy } from "lodash";
import { defineStore } from "pinia";
import type { Dictionary } from "vue-router/types/router";

import * as baseCollection from "@/pinia/collections";
import {
  BaseStatus,
  type IEmployee,
  type IEmployeeLoan,
  type IPosition,
  type IPositionLevel,
  type ITeam,
  type PositionWithTemplateInfos,
  type SubpositionWithTemplateInfos,
} from "@/tscript/interfaces";
import type { TeamID } from "@/tscript/mercateam";
import { createDictionary } from "@/tscript/utils/dictionary";

import { useTeamDataFastStore } from "./features/team-data";
import { useAuthentificationStore } from "./user";

interface PositionManagement {
  fixed: boolean;
  id: string;
  name: string;
  order: number;
}

export const useTeamDataStore = defineStore("useTeamData", {
  actions: {
    saveOrderPosition: async (list: PositionManagement[], teamId: TeamID) => {
      const PositionOrderStore = baseCollection.usePositionOrderStore();
      // const positionsOrder = PositionOrderStore.list;
      const teamDataStore = useTeamDataStore();
      const positionsOrder = teamDataStore.positionOrders;
      const AuthentificationStore = useAuthentificationStore();
      const order =
        positionsOrder && positionsOrder.length > 0
          ? positionsOrder[0].positions_order
          : null;
      const strings = map(list, (l) => l.id);
      if (order) {
        const id = teamDataStore.positionOrders?.[0].id;
        await PositionOrderStore.update(
          {
            positions_order: strings,
          },
          id,
        );
      } else {
        await PositionOrderStore.add({
          client_id: AuthentificationStore.data?.client_id,
          positions_order: strings,
          site_id: AuthentificationStore.data?.site_id,
          status: BaseStatus.active,
          team_id: teamId,
          update_user_id: AuthentificationStore.data?.id,
        });
      }
    },
  },
  getters: {
    employeeIds(): string[] {
      return map(this.employees, (e) => e.id);
    },
    employees: (state): (IEmployee | IEmployeeLoan)[] | null => {
      const EmployeeGlobal = baseCollection.useEmployeeGlobalStore();
      return filter(EmployeeGlobal.actives, (employee) => {
        return employee.team_id === state.team.id;
      });
    },
    employeesActivesArchived: (state): (IEmployee | IEmployeeLoan)[] | null => {
      const EmployeeGlobal = baseCollection.useEmployeeGlobalStore();
      return filter(
        { ...EmployeeGlobal.actives, ...EmployeeGlobal.archived },
        (employee) => {
          return employee.team_id === state.team.id;
        },
      );
    },
    employeesdictionary(): Dictionary<IEmployee | IEmployeeLoan | undefined> {
      if (this.employees) {
        return createDictionary(this.employees);
      }
      return {};
    },
    order_positions(): any[] {
      let positionOrders = this.positionOrders;

      const positions = useTeamDataFastStore().positions;
      if (!positionOrders || positionOrders.length <= 0) {
        return sortBy(positions, ["name"]);
      }

      positionOrders = positionOrders.map((positionOrder) =>
        positionOrder.position_id ? positionOrder.position_id : positionOrder,
      );

      return sortBy(positions, (p) => {
        return positionOrders?.indexOf(p.id);
      });
    },
    order_positions_and_subpositions(): (
      | PositionWithTemplateInfos
      | SubpositionWithTemplateInfos
    )[] {
      // const SubpositionStore = baseCollection.useSubpositionStore();
      const positionsSorted: IPosition[] = this.order_positions;

      if (useTeamDataFastStore().subpositions.length === 0)
        return positionsSorted;

      const positionsAndSubpositions: (
        | PositionWithTemplateInfos
        | SubpositionWithTemplateInfos
      )[] = [];

      forEach(positionsSorted, (position) => {
        const subPositions = useTeamDataFastStore().subpositions.filter(
          (subposition: any) => subposition.position.id === position.id,
        );
        if (subPositions && subPositions.length > 0) {
          const subpositionOrderByName = orderBy(subPositions, "name");
          for (const [i, sub] of subpositionOrderByName.entries()) {
            const subposition = {
              ...sub,
              parent_name: position.name,
              sub_row_span: i === 0 ? subPositions.length : 0,
            };
            positionsAndSubpositions.push(subposition);
          }
        } else {
          positionsAndSubpositions.push(position);
        }
      });
      return positionsAndSubpositions;
    },

    order_positions_and_subpositions_to_search(): (
      | PositionWithTemplateInfos
      | SubpositionWithTemplateInfos
    )[] {
      // const SubpositionStore = baseCollection.useSubpositionStore();
      const positionsSorted: IPosition[] = this.order_positions;

      if (useTeamDataFastStore().subpositions.length <= 0)
        return positionsSorted;

      const positionsAndSubpositions: (
        | PositionWithTemplateInfos
        | SubpositionWithTemplateInfos
      )[] = [];

      forEach(positionsSorted, (position) => {
        const subPositions = useTeamDataFastStore().subpositions.filter(
          (subposition: any) => subposition.position.id === position.id,
        );
        if (subPositions && subPositions.length > 0) {
          for (const [i, sub] of subPositions.entries()) {
            const subposition = {
              ...sub,
              parent_name: position.name,
              sub_row_span: i === 0 ? subPositions.length : 0,
            };
            positionsAndSubpositions.push(subposition);
            positionsAndSubpositions.push(position);
          }
        } else {
          positionsAndSubpositions.push(position);
        }
      });
      return positionsAndSubpositions;
    },
    order_positions_and_subpositionsdictionary(): Dictionary<
      IPosition | IPositionLevel | undefined
    > {
      return createDictionary(this.order_positions_and_subpositions);
    },
  },
  state: () => ({
    clearances: [] as any[] | null, // From employee_clearances
    clearancesFromTeam: [] as any[] | null, // From team.clearances
    employeeCards: [] as any[] | null,
    positionOrders: [] as any[] | null,
    refetch: Date.now() as number,
    refetchPositionVersatility: false as boolean,
    skills: [] as any[] | null, // From employee_skills
    skillsFromTeam: [] as any[] | null, // From team.skills
    team: {
      document: null as ITeam | null,
      id: null as null | TeamID,
      last_update: null as Date | null,
    },
  }),
});
