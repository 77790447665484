import {
  CampaignCreateData,
  type CampaignEdition,
} from "../types/campaignEdition";
import type { Block } from "../types/entities/block.entity";
import { Step } from "../types/entities/step.entity";
import type { ReviewDetail } from "../types/reviewDetail";
import type { SignatureReview, SignReview } from "../types/signReview";
import {
  BlockCreate,
  StepCreate,
  TemplateCreateData,
  TemplateEdition,
} from "../types/templateEdition";

const formatBlockToCreate = (block: Block): "ERROR" | BlockCreate => {
  switch (block.resource.type) {
    case "grid":
      return BlockCreate.parse({
        cols: block.resource.cols,
        description: block.description,
        min_score: block.resource.minScore,
        optional: block.optional,
        rows: block.resource.rows,
        title: block.resource.title,
        type: "grid",
      });
    case "information":
      return BlockCreate.parse({
        description: block.description,
        optional: block.optional,
        text: block.resource.text,
        type: "information",
      });
    case "openQuestion":
      return BlockCreate.parse({
        description: block.description,
        expected_response: block.resource.expectedResponse ?? undefined,
        optional: block.optional,
        question: block.resource.question,
        type: "open-question",
      });
    case "rating":
      return BlockCreate.parse({
        ...block,
        description: block.description,
        optional: block.optional,
        question: block.resource.question,
        type: "rating",
      } satisfies BlockCreate);
    case "table":
      return BlockCreate.parse({
        ...block,
        description: block.description,
        optional: block.optional,
        rows: block.resource.rows,
        title: block.resource.title,
        type: "table",
      } satisfies BlockCreate);
    default:
      block.resource satisfies never;
  }
  return "ERROR";
};

const formatBlockToEdit = (block: BlockCreate): "ERROR" | Block => {
  switch (block.type) {
    case "grid":
      return {
        description: block.description ?? undefined,
        optional: block.optional ?? undefined,
        resource: {
          cols: block.cols.map((col) => ({
            ...col,
            score: col.score ?? undefined,
          })),
          minScore: block.min_score,
          rows: block.rows,
          title: block.title,
          type: "grid",
        },
      };
    case "information":
      return {
        description: block.description ?? undefined,
        optional: block.optional ?? undefined,
        resource: {
          text: block.text,
          type: "information",
        },
      };
    case "open-question":
      return {
        description: block.description ?? undefined,
        optional: block.optional ?? undefined,
        resource: {
          expectedResponse: block.expected_response ?? null,
          question: block.question,
          type: "openQuestion",
        },
      };
    case "rating":
      return {
        description: block.description ?? undefined,
        optional: block.optional ?? false,
        resource: {
          question: block.question,
          type: "rating",
        },
      };
    case "table":
      return {
        description: block.description ?? undefined,
        optional: block.optional ?? false,
        resource: {
          rows: block.rows,
          title: block.title,
          type: "table",
        },
      };
    default:
      block satisfies never;
  }
  return "ERROR";
};

export const templateCreateToApiDomain = (
  template: TemplateEdition,
  siteId: string,
): "ERROR" | TemplateCreateData => {
  try {
    const formattedSteps = template.steps.map((step) => {
      if (!step.mustFillEmployee && !step.mustFillManager)
        throw new Error("Must have at least one filler");
      const both = step.mustFillEmployee && step.mustFillManager;
      const blocks = step.blocks.map((block) => {
        const result = formatBlockToCreate(block);
        if (result === "ERROR")
          throw new Error("Error in format block raw data");
        return result;
      });
      const parsedStepCreate = StepCreate.safeParse({
        blocks,
        filler: both ? "both" : step.mustFillEmployee ? "employee" : "manager",
        title: step.title,
      });
      if (!parsedStepCreate.success)
        throw new Error("Error in format template raw data");
      return parsedStepCreate.data;
    });
    const parsedTemplateCreate = TemplateCreateData.safeParse({
      define_new_goals: template.defineNewGoals,
      description: template.description,
      display_attached_files: template.displayAttachedFiles,
      display_global_assessment: template.displayGlobalAssessment,
      display_previous_goals: template.displayPreviousGoals,
      display_skills_clearances_trainings:
        template.displaySkillsClearancesTrainings,
      global_assessment: template.displayGlobalAssessment
        ? template.globalAssessment
        : [],
      id: template.id ?? undefined,
      name: template.name,
      signatories: {
        employee_required: template.signatories.employeeRequired,
        manager_required: template.signatories.managerRequired,
        others: template.signatories.others,
      },
      site_id: siteId,
      steps: formattedSteps,
      update_skills_clearances_trainings:
        template.updateSkillsClearancesTrainings,
    } satisfies TemplateCreateData);
    if (!parsedTemplateCreate.success)
      throw new Error("Error in format template raw data");
    return parsedTemplateCreate.data;
  } catch (error) {
    console.error("Parse ERROR in templateCreateToApiDomain", error);
    return "ERROR";
  }
};

export const templateApiToEditDomain = (
  template: TemplateCreateData,
): "ERROR" | TemplateEdition => {
  try {
    const formattedSteps = template.steps.map((step) => {
      const blocks = step.blocks.map((block) => {
        const result = formatBlockToEdit(block);
        if (result === "ERROR")
          throw new Error("Error in format block raw data");
        return result;
      });
      const parsedStepCreate = Step.safeParse({
        blocks,
        mustFillEmployee: step.filler === "both" || step.filler === "employee",
        mustFillManager: step.filler === "both" || step.filler === "manager",
        title: step.title,
      } satisfies Step);
      if (!parsedStepCreate.success)
        throw new Error("Error in format step raw data");
      return parsedStepCreate.data;
    });

    const parsedTemplateCreate = TemplateEdition.safeParse({
      defineNewGoals: template.define_new_goals,
      description: template.description,
      displayAttachedFiles: template.display_attached_files,
      displayGlobalAssessment: template.display_global_assessment,
      displayPreviousGoals: template.display_previous_goals,
      displaySkillsClearancesTrainings:
        template.display_skills_clearances_trainings,
      globalAssessment: template.display_global_assessment
        ? template.global_assessment
        : [],
      id: template.template_id,
      name: template.name,
      signatories: {
        employeeRequired: template.signatories.employee_required,
        managerRequired: template.signatories.manager_required,
        others: template.signatories.others ?? undefined,
      },
      steps: formattedSteps,
      updateSkillsClearancesTrainings:
        template.update_skills_clearances_trainings,
    } satisfies TemplateEdition);
    if (!parsedTemplateCreate.success)
      throw new Error("Error in format template raw data");
    return parsedTemplateCreate.data;
  } catch (error) {
    console.error("Parse ERROR in templateApiToEditDomain", error);
    return "ERROR";
  }
};

export const campaignCreateToApiDomain = (
  campaign: CampaignEdition,
  site_id: string,
): "ERROR" | CampaignCreateData => {
  try {
    if (!campaign.selectedTemplateId) return "ERROR";
    const formattedCampaign: CampaignCreateData = {
      applicants: campaign.selectedEmployees.map((employee) => {
        return { employee_id: employee.id };
      }),
      due_date: campaign.dueDate ? campaign.dueDate : undefined,
      name: campaign.name,
      site_id,
      start_date: campaign.startDate,
      template_id: campaign.selectedTemplateId,
    };
    const result = CampaignCreateData.parse(formattedCampaign);
    return result;
  } catch (error) {
    console.error("Parse ERROR in  campaignCreateToApiDomain", error);
    return "ERROR";
  }
};

export const signReviewtoApiDomain = (
  data: SignatureReview,
): "ERROR" | SignReview => {
  try {
    const signReview: SignReview = {
      campaign_id: data.campaignId,
      employee_id: data.employeeId,
      signatures: {},
    };

    const others = data.signatures.others
      ? Object.entries(data.signatures.others)
      : undefined;
    if (others) {
      if (!signReview.signatures.others) {
        signReview.signatures.others = {};
      }
      for (const [key, other] of others) {
        if (other.type === "refused") {
          signReview.signatures.others[key] = {
            ...other,
            user_id: other.userId,
          };
        }
        if (other.type === "accepted") {
          signReview.signatures.others[key] = {
            ...other,
            user_id: other.userId,
          };
        }
      }
    }
    signReview.signatures.employee = data.signatures?.employee;
    signReview.signatures.manager = data.signatures?.manager;
    return signReview;
  } catch {
    return "ERROR";
  }
};

export const formatReviewDetail = (data) => {
  try {
    const formatResponseIntoReviewDetail: ReviewDetail = {
      defineNewGoals: data.define_new_goals,
      description: data.description,
      displayAttachedFiles: data.display_attached_files,
      displayGlobalAssessment: data.display_global_assessment,
      displayJobDescription: data.display_job_description,
      displayPreviousGoals: data.display_previous_goals,
      displaySkillsClearancesTrainings:
        data.display_skills_clearances_trainings,
      globalAssessment: data.global_assessment,
      goals: data.goals,
      goalsHistory: data.previous_goals?.length
        ? data.previous_goals.map((item) => ({
            campaignId: item.campaign_id,
            campaignName: item.campaign_name,
            goals: item.goals,
            reviewValidatedAt: item.review_validated_at,
            templateName: item.template_name,
          }))
        : [],
      managerId: data.manager_id,
      name: data.name,
      scores: data.scores
        ? data.scores.map((score) => ({
            expectedScore: score.expected_score,
            obtainedScore: score.obtained_score,
            title: score.title,
          }))
        : [],
      signatures: {
        employee: {
          type: "notSigned",
        },
        manager: {
          type: "notSigned",
        },
        others: null,
      },
      status: data.status,
      steps: data.steps.map((step) => ({
        ...step,
        blocks: step.blocks.map((block) => {
          const resourceType:
            | "grid"
            | "information"
            | "open-question"
            | "rating"
            | "table" = block.type;
          switch (resourceType) {
            case "grid":
              return {
                description: block.description ?? undefined,
                optional: block.optional ?? undefined,
                resource: {
                  cols: block.cols.map((col) => ({
                    ...col,
                    score: col.score ?? undefined,
                  })),
                  minScore: block.min_score,
                  rows: block.rows.map((row) => ({
                    ...row,
                    selectedIndex: row.selected_index,
                  })),
                  title: block.title,
                  type: "grid",
                },
              };
            case "information":
              return {
                description: block.description ?? undefined,
                resource: { text: block.text, type: "information" },
              };
            case "open-question":
              return {
                description: block.description ?? undefined,
                optional: block.optional ?? undefined,
                resource: {
                  question: block.question,
                  response: block.response,
                  type: "openQuestion",
                },
              };
            case "rating":
              return {
                description: block.description ?? undefined,
                optional: block.optional ?? undefined,
                resource: {
                  comment: block.comment,
                  question: block.question,
                  score: block.score,
                  title: block.title,
                  type: "rating",
                },
              };
            case "table":
              return {
                description: block.description ?? undefined,
                optional: block.optional ?? undefined,
                resource: {
                  rows: block.rows.map((row) => ({
                    ...row,
                    cols: row.cols.map((col) => ({
                      ...col,
                      text: col.text ?? "",
                    })),
                  })),
                  title: block.title,
                  type: "table",
                },
              };
            default:
              resourceType satisfies never;
          }
        }),
      })),
      updateSkillsClearancesTrainings: data.update_skills_clearances_trainings,
    };

    if (data.signatories?.manager_required) {
      if (data.signatures?.manager) {
        formatResponseIntoReviewDetail.signatures.manager = {
          date: data.signatures.manager.date,
          signature: data.signatures.manager.signature,
          type: data.signatures?.manager.type,
          userId: data.signatures.manager.user_id,
        };
      }
    } else {
      formatResponseIntoReviewDetail.signatures.manager = null;
    }

    if (data.signatories?.employee_required) {
      if (data.signatures?.employee) {
        formatResponseIntoReviewDetail.signatures.employee = {
          date: data.signatures.employee.date,
          signature: data.signatures.employee.signature,
          type: data.signatures?.employee.type,
        };
      }
    } else {
      formatResponseIntoReviewDetail.signatures.employee = null;
    }

    if (data.signatories?.others) {
      formatResponseIntoReviewDetail.signatures.others = {};

      for (const [key, other] of Object.entries(
        data.signatories.others as Record<string, { user_id: string }[]>,
      )) {
        if (data.signatures?.others?.[key]) {
          formatResponseIntoReviewDetail.signatures.others[key] = {
            ...data.signatures?.others[key],
            userId: data.signatures?.others[key].user_id,
          };
        } else {
          formatResponseIntoReviewDetail.signatures.others[key] = {
            signatories: other.map((signer) => ({ userId: signer.user_id })),
            type: "notSigned",
          };
        }
      }
    }

    return formatResponseIntoReviewDetail;
  } catch (error) {
    console.error(error);
  }
};
