import { defineStandard } from "@/tscript/database/PiniaHelper";
import type { IQuestionnaire, IQuestionnaireModel } from "@/tscript/evaluation";
import type {
  IAbsenceType,
  IAssignationTag,
  IClearance,
  IClient,
  IContent,
  IContentApprobation,
  IContentDocumentType,
  IContract,
  IEmployee,
  IEmployeeCategory,
  IEvent,
  IExternalTrainer,
  IFactory,
  IFileAttach,
  IFolder,
  IGeneralClosure,
  IKeywordLink,
  INote,
  INotification,
  IPlanningTemplate,
  IPosition,
  IPositionLevel,
  IRestriction,
  ISearchFilter,
  ISite,
  ISkill,
  ITeam,
  IUser,
  PositionShift,
  SkillsLegends,
} from "@/tscript/interfaces";
import type {
  LAbsence,
  LClearance,
  LContent,
  LPlanning,
  LPosition,
  LRestriction,
  LShift,
  LSkill,
  LTraining,
} from "@/tscript/links_employee";
import type { LPositionClosed, LSkills } from "@/tscript/links_team";
import type { IGoal, IGoalHistory } from "@/tscript/piloting";
import type { PositionOrder } from "@/tscript/team_settings";
import type { IVersatilityClosedMonth } from "@/tscript/versatility";
import type { Template } from "@/tscript/view_shift";

export const useParameterPlanningStore = defineStandard<
  any,
  "useParameterPlanning"
>("useParameterPlanning", "parameters_planning", {
  dictionary: true,
});

export const useEventGlobalStore = defineStandard<IEvent, "useEventGlobal">(
  "useEventGlobal",
  "events",
);

export const useSiteGlobalStore = defineStandard<ISite, "useSiteGlobal">(
  "useSiteGlobal",
  "sites",
  {
    dictionary: true,
  },
);

export const usePlanningTemplateGlobalStore = defineStandard<
  IPlanningTemplate,
  "usePlanningTemplateGlobal"
>("usePlanningTemplateGlobal", "planning_template");

export const useFactoryGlobalStore = defineStandard<
  IFactory,
  "useFactoryGlobal"
>("useFactoryGlobal", "factories", {
  dictionary: true,
});

export const useClientGlobalStore = defineStandard<IClient, "useClientGlobal">(
  "useClientGlobal",
  "clients",
  {
    dictionary: true,
  },
);

export const useContentGlobalStore = defineStandard<
  IContent,
  "useContentGlobal"
>("useContentGlobal", "content", {
  dictionary: true,
  dictionaryActives: true,
});

export const useContentApprobationGlobalStore = defineStandard<
  IContentApprobation,
  "useContentApprobationGlobal"
>("useContentApprobationGlobal", "content_approbation", {
  dictionary: true,
});

export const useContentDocumentTypeGlobalStore = defineStandard<
  IContentDocumentType,
  "useContentDocumentTypeGlobal"
>("useContentDocumentTypeGlobal", "content_document_type", {
  dictionary: true,
});

export const useEmployeeGlobalStore = defineStandard<
  IEmployee,
  "useEmployeeGlobal"
>("useEmployeeGlobal", "employees", {
  dictionary: true,
  dictionaryActives: true,
});

export const useSearchFilterStore = defineStandard<
  ISearchFilter,
  "useSearchFilter"
>("useSearchFilter", "search_filters", {
  dictionary: true,
  dictionaryActives: true,
});

// Used for settings
export const useParameterExternalTrainerStore = defineStandard<
  IExternalTrainer,
  "useParameterExternalTrainer"
>("useParameterExternalTrainer", "external_trainers", {
  dictionary: true,
});

export const useTeamGlobalStore = defineStandard<ITeam, "useTeamGlobal">(
  "useTeamGlobal",
  "teams",
  {
    dictionary: true,
    orderBy: "name",
  },
);

export const useUserGlobalStore = defineStandard<IUser, "useUserGlobal">(
  "useUserGlobal",
  "users",
  {
    dictionary: true,
  },
);

export const useSkillGlobalStore = defineStandard<ISkill, "useSkillGlobal">(
  "useSkillGlobal",
  "skills",
  {
    dictionary: true,
    dictionaryActives: true,
  },
);

export const useClearanceGlobalStore = defineStandard<
  IClearance,
  "useClearanceGlobal"
>("useClearanceGlobal", "clearances", {
  dictionary: true,
  dictionaryActives: true,
});

// This one is used for globally (now: widget, soon: admin)
export const usePositionGlobalStore = defineStandard<
  IPosition,
  "PositionGlobal"
>("PositionGlobal", "positions", {
  dictionary: true,
});

export const usePositionLevelGlobalStore = defineStandard<
  IPositionLevel,
  "PositionLevelGlobal"
>("PositionLevelGlobal", "positions_level", {
  dictionary: true,
});

// This one is used for planning.
export const usePositionStore = defineStandard<IPosition, "usePosition">(
  "usePosition",
  "positions",
);

export const useSubpositionStore = defineStandard<
  IPositionLevel,
  "useSubposition"
>("useSubposition", "positions_level", { dictionary: true });

export const usePositionOrderStore = defineStandard<
  PositionOrder,
  "usePositionOrder"
>("usePositionOrder", "position_order");

export const useContractGlobalStore = defineStandard<
  IContract,
  "useContractGlobal"
>("useContractGlobal", "parameters_contract", {
  dictionary: true,
});

export const useParameterAbsenceStore = defineStandard<
  IAbsenceType,
  "useParameterAbsence"
>("useParameterAbsence", "parameters_absences", {
  dictionary: true,
});

// Used for settings
export const useParameterRestrictionStore = defineStandard<
  IRestriction,
  "ParameterRestriction"
>("ParameterRestriction", "parameters_restrictions", {
  dictionary: true,
});

export const useParameterFolderStore = defineStandard<
  IFolder,
  "useParameterFolder"
>("useParameterFolder", "parameters_folders");

export const useFilesAttachmentStore = defineStandard<
  IFileAttach,
  "useFilesAttachment"
>("useFilesAttachment", "files_attachment");

export const useParameterEmployeeCategoryStore = defineStandard<
  IEmployeeCategory,
  "useParameterEmployeeCategory"
>("useParameterEmployeeCategory", "parameters_employee_categories", {
  dictionary: true,
});

export const useParameterAssignationTagsStore = defineStandard<
  IAssignationTag,
  "useParameterAssignationTags"
>("useParameterAssignationTags", "parameters_assignation_tags", {
  dictionary: true,
});

export const useEmployeeContentStore = defineStandard<
  LContent,
  "EmployeeContent"
>("EmployeeContent", "link_employee_content", {
  dictionary: true,
  linkEmployee: true,
});

export const useEmployeePositionStore = defineStandard<
  LPosition,
  "EmployeePosition"
>("EmployeePosition", "link_employee_position", { linkEmployee: true });

export const useEmployeePlanningStore = defineStandard<
  LPlanning,
  "useEmployeePlanning"
>("useEmployeePlanning", "link_employee_planning", {
  dictionary: true,
  linkEmployee: true,
  querySlug: ["client_id", "site_id", "week", "weekBeginsOnYear"],
  querySlugMonth: ["client_id", "site_id", "month", "year"],
});

export const useEmployeeShiftStore = defineStandard<LShift, "useEmployeeShift">(
  "useEmployeeShift",
  "link_employee_shifts",
  {
    linkEmployee: true,
    querySlug: ["client_id", "site_id", "week", "weekBeginsOnYear"],
    querySlugMonth: ["client_id", "site_id", "month", "year"],
  },
);

export const useEmployeeAbsencesStore = defineStandard<
  LAbsence,
  "useEmployeeAbsence"
>("useEmployeeAbsence", "link_employee_time_absences", {
  linkEmployee: true,
  // querySlug: ["client_id", "site_id", "week", "weekBeginsOnYear"],
  querySlugMonth: ["client_id", "site_id", "month", "year"],
});

export const usePositionsClosedStore = defineStandard<
  LPositionClosed,
  "usePositionsClosed"
>("usePositionsClosed", "link_teams_positions_closed", {
  querySlug: ["client_id", "site_id", "week", "year", "team_id"],
  querySlugMonth: ["client_id", "site_id", "month", "year", "team_id"],
});

export const useSkillLegendsStore = defineStandard<
  SkillsLegends,
  "useSkillLegends"
>("useSkillLegends", "skills_legends");

export const useTeamSkillStore = defineStandard<LSkills, "useTeamSkill">(
  "useTeamSkill",
  "link_teams_skills",
);

export const useEmployeeSkillStore = defineStandard<LSkill, "useEmployeeSkill">(
  "useEmployeeSkill",
  "link_employee_skills",
  {
    dictionary: true,
    linkEmployee: true,
  },
);

export const useEmployeeClearanceStore = defineStandard<
  LClearance,
  "EmployeeClearanceStore"
>("EmployeeClearanceStore", "link_employee_clearances", { linkEmployee: true });

export const useEmployeeRestrictionStore = defineStandard<
  LRestriction,
  "EmployeeRestriction"
>("EmployeeRestriction", "link_employee_restrictions", {
  linkEmployee: true,
});

export const useEmployeeTrainingStore = defineStandard<
  LTraining,
  "EmployeeTraining"
>("EmployeeTraining", "link_trainings_employee", {
  dictionary: true,
  linkEmployee: true,
});

export const useLinkKeywordsStore = defineStandard<
  IKeywordLink,
  "useLinkKeywords"
>("useLinkKeywords", "link_keywords");

export const useQuestionnaireStore = defineStandard<
  IQuestionnaire,
  "useQuestionnaire"
>("useQuestionnaire", "questionnaire");

export const useQuestionnaireModelStore = defineStandard<
  IQuestionnaireModel,
  "useQuestionnaireModel"
>("useQuestionnaireModel", "questionnaire_model", {
  dictionary: true,
});

export const useShiftTemplateStore = defineStandard<
  Template,
  "useShiftTemplate"
>("useShiftTemplate", "shift_templates");

export const useParameterClosureStore = defineStandard<
  IGeneralClosure,
  "useParameterClosure"
>("useParameterClosure", "parameters_closure");

export const useGoalsStore = defineStandard<IGoal, "useGoals">(
  "useGoals",
  "goals",
);

export const useGoalsHistoryStore = defineStandard<
  IGoalHistory,
  "useGoalsHistory"
>("useGoalsHistory", "goals_history");

export const useNotesStore = defineStandard<INote, "useNotes">(
  "useNotes",
  "notes",
);

export const useNotificationsStore = defineStandard<
  INotification,
  "useNotifications"
>("useNotifications", "notifications");

export const useVersatilityClosedMonthStore = defineStandard<
  IVersatilityClosedMonth,
  "useVersatilityClosedMonth"
>("useVersatilityClosedMonth", "versatility_closed_month");

export const usePositionShiftStore = defineStandard<
  PositionShift,
  "usePositionShift"
>("usePositionShift", "position_shifts");
