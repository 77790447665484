import { getCurrentInstance as f, computed as u } from "vue";
const a = (e, t, n, s = {}) => {
  const { overwrite: c = !1, warnOnExisting: i = !0 } = s;
  for (const o of Object.keys(n)) {
    const r = e.getLocaleMessage(o)[t];
    if (r && i && console.warn(
      `[i18n] Messages already exist for namespace "${t}" in locale "${o}". ${c ? "Overwriting" : "Skipping"} registration.`
    ), !r || c) {
      const l = {
        [t]: n[o]
      };
      e.mergeLocaleMessage(o, l);
    }
  }
}, d = (e = "") => {
  const t = f();
  if (!(t != null && t.proxy))
    throw new Error(
      "[i18n] useI18n must be called within a component setup function"
    );
  const n = t.proxy.$i18n;
  if (!n)
    throw new Error("[i18n] vue-i18n instance not found");
  const s = u(() => n.locale), c = (i) => e ? `${e}.${i}` : i;
  return {
    locale: s,
    t: (i, o) => n.t(c(i), o),
    tc: (i, o, r) => n.tc(c(i), o, r)
  };
}, w = (e, t = "asc") => (n, s) => {
  if (n[e] === void 0 || s[e] === void 0)
    throw new Error(
      `Property ${String(e)} does not exist on provided object`
    );
  return t === "asc" ? n[e] === "" ? 1 : s[e] === "" ? -1 : typeof n[e] == "string" && typeof s[e] == "string" ? n[e].localeCompare(s[e]) : n[e] - s[e] : n[e] === "" ? -1 : s[e] === "" ? 1 : typeof n[e] == "string" && typeof s[e] == "string" ? s[e].localeCompare(n[e]) : s[e] - n[e];
}, x = (e, t) => e.length > t ? `${e.slice(0, t)}…` : e, $ = (e, t) => e.length > t ? e.slice(0, t) : e, m = (e) => e.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
export {
  w as getSortByProperty,
  a as registerPackageTranslations,
  m as removeAccents,
  $ as textCut,
  x as textEllipsis,
  d as useI18n
};
